import { useState, useEffect} from 'react'

import { getTypeUser } from '../helpers/registerFunc';

//Return LIST CHATS
const useGetTypeUserList = (state) => {
   const [typeUserState,setTypeUserState] = useState({
      listTypeUser:[],
      loading:true,
   });

   useEffect(() => {
      getTypeUser().then( (typeUser) => {
         setTypeUserState({
            listTypeUser:typeUser,
            loading:false,
          })
       })
  },[state])

   return typeUserState;
}

//GET CONVERSATION OR CREATE NEW

export {useGetTypeUserList}



import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/authContext';
import { sendEmail } from '../../helpers/evidenceFunc';
import { useCurrentInci } from '../../hooks/hooks_incidence';
import { CircleGif } from '../CircleGif';

export const IncidenciaInfo = () => {


    //Global info user dispatch
    const { currentUser } = useContext(AuthContext);
    const { incidenciaid } = useParams();
    console.log("ServiceDetailID => ", incidenciaid);

    const incidentState = useCurrentInci(incidenciaid, currentUser.idCompany);
    console.log(incidentState);
    //console.log(loadingImg);
    const { incident, loadingImg } = incidentState;

    //validar campos y lista de imagenes selecionadas
    const handlerValidation = async (e) => {
        e.preventDefault();
        console.log('Click enviar');
        let msgError = '';
        const emial = document.getElementById('email');
        const subject = document.getElementById('subject');
        const message = document.getElementById('txtmessgage');
        //selecPictures
        let listImg = handlerSelectPic();
        if (emial.value === "" && subject.value === "" && listImg.length === 0) {
            msgError += 'Los campos e imágenes son obligatorios.\n'
            //sweatAlert(msgError);
        }

        if (emial.value !== "" && subject.value !== "" && listImg.length === 0) {
            msgError += 'Al menos debes seleccionar una imagen.\n'
            //sweatAlert(msgError);
        }

        if (emial.value === "" && subject.value === "" && listImg.length > 0) {
            msgError += 'El correo, asunto son campos requeridos, por favor introduce valores.\n'
            //sweatAlert(msgError);
        }

        if (emial.value !== "" && subject.value === "") {
            msgError += 'El Asunto es requerido.\n'
            //sweatAlert(msgError);
        }
        //validar correo valido
        //if(){}
        if (emial.value === "" && subject.value !== "") {
            msgError += 'El correo correo es requerido.\n'

        }

        if (msgError !== "") {
            sweatAlert(msgError);
        } else {
            //funcion para enviar correo
            await sendEmailPic(emial.value, subject.value, message.value, listImg);
        }

    }

    //funcion para enviar correo con imagenes.
    const sendEmailPic = async (correo, subj, msg, listPictures) => {

        const objData = {
            senderEmail: currentUser.email,
            whoRecibe: correo,
            subject: subj,
            msg: msg,
            idCompany: currentUser.idCompany
        };
        await sendEmail(listPictures, objData, 2);
        document.getElementById('email').value = '';
        document.getElementById('subject').value = '';
        document.getElementById('txtmessgage').value = '';
        listPictures = [];
        const getAllchecboxSelected = document.querySelectorAll('.ck1');
        for (let x = 0; x < getAllchecboxSelected.length; x++) {

            if (getAllchecboxSelected[x].checked === true) {
                getAllchecboxSelected[x].checked = false;

            }

        }

    }



    //funcion para seleccionar dichas imagenes.
    const handlerSelectPic = () => {
        //e.preventDefault();
        let listPictures = [];
        //setListImgSelect([]);
        const getAllchecboxSelected = document.querySelectorAll('.ck1');
        for (let x = 0; x < getAllchecboxSelected.length; x++) {

            if (getAllchecboxSelected[x].checked === true) {
                const urlPic = getAllchecboxSelected[x].value;
                console.log(urlPic);
                listPictures.push(urlPic);
            }

        }
        if (listPictures.length > 0) {
            // setListImgSelect(listPictures);

        } else {
            //setListImgSelect([]);
            listPictures = [];
        }
        return listPictures;
    }



    const renderingImg = () => {
        let viewImgList = [];
        if (incident.length == 1) {
            incident.forEach((e, index) => {
                const img = e.imageURL;
                viewImgList.push(
                    /* <tr key={index}>
                         <td>*/
                    <div key={index} className='center'>
                        <input className='ck1 
                             filled-in' type="checkbox" value={img} style={{ left: '20%' }} />
                        <img src={img} width={250} height={300} className='sizetallImg responsive-img' />

                    </div>
                    //</td>

                    //</tr>
                );
            })
        } else {
            viewImgList = [];
        }
        return viewImgList;
    }


    return (
        <div className='fondInfo'>


            <div className=' row containerIfo'>
                <div className='contentInfo'>
                    <div className='colorone'>
                        <form>

                            <div className='input-field col s11 globalFormEviInc'>
                                <input placeholder="Correo" id="email" type="email" className="" name='email' />
                            </div>
                            <div className='input-field col s11 globalFormEviInc'>
                                <input placeholder="Asunto" id="subject" type="text" className="" name='subject' />
                            </div>
                            <div className='input-field col s11 inputMessage-evidence'>

                                <textarea className='txtAreaBorder-None-eviden noResize' type='text' name='txtmessgage' id='txtmessgage' placeholder='Mensaje' style={{ color: '#75747D' }}></textarea>

                            </div>

                            <div className='input-field col s11'>



                                <button id='sendEmail' className='btn btnFromLogin' type='button' onClick={handlerValidation}>Enviar</button>
                                <Link to='/incidencias' className='btn btnFromLogin' id='backP' style={{ background: '#5E5E68' }}>Regresar</Link>

                            </div>


                        </form>
                    </div>
                    <div className='colortwo'>


                        <div className='contenGrid'>
                            {loadingImg == true ? <div className='row center'><CircleGif /></div> : renderingImg()}


                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}


const sweatAlert = (messageErr) => {

    Swal.fire({
        title: 'Aviso',
        text: messageErr !== '' || messageErr !== null ? messageErr : 'NA',
        icon: 'warning'
    });
}
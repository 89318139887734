import { where, query, collection, getDocs, orderBy } from "firebase/firestore";
import Swal from "sweetalert2";
import { myFirestore } from "../config/ConfigFirebase";
import firebase from "../config/global-variables";


//**GET all the solicitudes  */
const getAccessList = async (idCompany, searchTex) => {

    let listAccessPDF = [];
    try {
        if (searchTex === "") {
            listAccessPDF = [];
        }
        if (searchTex !== '' && searchTex !== null) {
            listAccessPDF = [];
            const myquerySearc = query(collection(myFirestore, 'ServiceRequest'), where('idCompany', "==", idCompany), orderBy('order', 'desc'));
            const subCollectionConversSerac = await getDocs(myquerySearc);

            subCollectionConversSerac.forEach((newdoc) => {
                const nameOperator = newdoc.data()['nameOperator'];
                const fecha = newdoc.data()['date'];
                const observation = newdoc.data()['observation'];
                const serviceGuide = newdoc.data()['serviceGuide'];
                if (nameOperator.toUpperCase().includes(searchTex.toUpperCase()) || fecha.toUpperCase().includes(searchTex.toUpperCase())
                    || observation.toUpperCase().includes(searchTex.toUpperCase()) || serviceGuide.toUpperCase().includes(searchTex.toUpperCase())) {
                    let converSerc = {
                        id: newdoc.id,
                        nameOper: newdoc.data()['nameOperator'],
                        date: newdoc.data()['date'],
                        observa: newdoc.data()['observation'],
                        idCompany: newdoc.data()['idCompany'],
                        serviceGuide: newdoc.data()['serviceGuide'],
                        status: newdoc.data()['status'],
                        order: newdoc.data()['order']
                    }
                    listAccessPDF.push(converSerc);
                }
            })

        }

        return listAccessPDF;

    } catch (error) {
        console.error(error);
        Swal.fire({
            title: 'Error al servidor',
            text: 'No se pudo conectar o obtener las conversaciónes, por favor intente mas tarde.',
            icon: 'error',
        })
        return listAccessPDF;
    }

}//END


const acceptOrCancelAccess = async (dataID, idCompany) => {
    try {

        const getParams = dataID.split(',');
        //serviceGuide,type,idCompany
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/ExecuteAction`;
        console.log('GET PARASMS => ', getParams);
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                serviceGuide: getParams[0],
                type: getParams[1],
                // idCompany:idCompany,
                action: parseInt(getParams[2]),
                order: parseInt(getParams[3]),
                email: getParams[4],
            }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();

        console.log('NOTIFICACION RESULT => ', data);
        if (data === 1) {
            Swal.close();
            if (parseInt(getParams[2]) == 1) {
                Swal.fire({
                    title: 'Exitoso',
                    text: 'Tu operador ahora tiene acceso al documento.',
                    icon: 'success',
                })
            } else {
                Swal.fire({
                    title: 'Denegado',
                    text: 'Tu operador no tiene acceso al documento.',
                    icon: 'success',
                })
            }


        } else {
            Swal.close();
            Swal.fire({
                title: 'Alerta',
                text: 'No se pudo realizar la petición, desconexión al momento de conectar al servicor intentalo mas tarde.',
                icon: 'warning',
            })
        }

    } catch (error) {
        Swal.close();
        Swal.fire({
            title: 'Error al generar permiso',
            text: 'No se pudo conectar a la base de datos, por favor intente mas tarde.',
            icon: 'error',
        })
        console.error(error)
    }
}

export {
    getAccessList,
    acceptOrCancelAccess
}

import { myFirestore} from '../config/ConfigFirebase';
import { collection, query, where, getDocs,orderBy, doc,getDoc, onSnapshot, addDoc, updateDoc,arrayUnion } from "firebase/firestore";
import firebase from '../config/global-variables';
import Swal from 'sweetalert2';
const collectionCompanies = 'Companies';

const getCompanies = async() =>{
    let listCompanies=[];
    try {

        const response = query(collection(myFirestore,collectionCompanies));
        const querySnapshot =  await getDocs(response);

        querySnapshot.forEach((doc)=>{
            let company={
                id:doc.id,
                name:doc.data()['name'],
                idCompany:doc.data()['idCompany']
             }
               
             listCompanies.push(company);
              
            
            });
            
            console.log("Companies = ",listCompanies)
            return listCompanies;
        
    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Problema de conexión.',
            text: 'No se puedo obtener la lista de Compañias por algun problema de conexión al servidor.',
            icon:'error',
        })
          return listCompanies;
    }
}




const saveRegisterStatusByCompany = async(idCompany, data,type) =>{
    let res= 0;
    try {
        if(type === 1){
            const docREf =  await addDoc(collection(myFirestore,'CatStatusVehicles'),{
                "idCompany":idCompany,
                "statusCaption":data.statusCaption,
                "statusCaptionEs":data.statusCaptionEs,
                "statusCode":data.statusCode,
                "statusName":data.statusName,
                "statusNameEs":data.statusNameEs,
                "statusOrder":data.statusOrder,
                "statusType":data.statusType
            });
    
            console.log("Document written with ID: ", docREf.id);
            res =1;
            return res;
        }
        if(type === 2){
            const docREfe =  await addDoc(collection(myFirestore,'CatServiceTrailer'),{
                "idCompany":idCompany,
                "statusCaption":data.statusCaption,
                "statusCaptionEs":data.statusCaptionEs,
                "statusCode":data.statusCode,
                "statusName":data.statusName,
                "statusNameEs":data.statusNameEs,
                "statusType":data.statusType
            });
    
            console.log("Document written with ID: ", docREfe.id);
            res =1;
            return res;
        }else{
            
            res = 0;
            return res;
        }
  

    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Problema de conexión.',
            text: 'No se puedo acceder al servidor, no se pudo crear el nuevo status.',
            icon:'error',
        })
        return res;
    }
}


export{getCompanies,saveRegisterStatusByCompany}

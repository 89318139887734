
import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/authContext';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import { editDevolution, getOneDevolution } from '../../helpers/conceptosFunc';


export const EditDelovution = () => {

    const param = useParams();
    console.log(param);
    const idConpecto = param.concepto;
    const { currentUser } = useContext(AuthContext);

    // const [logingCompanies, setLoadingCompanies] = useState(true);
    // const [listCompanies, setListCompanies] = useState([]);
    const initialValues = { name: "", nameES: "", description: "" };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        let isMounted = true;
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
        getOneDevolution(idConpecto).then((result) => {
            console.info("result=>", result);
            // console.log("result[0].typeUser=>" + result[0].typeUser);
            // console.log(result)
            setFormValues({
                name: result[0].name, nameES: result[0].nameES, description: result[0].description
            })
        });

        return () => {
            isMounted = false;
        };

    }, []);//logingCompanies

    const handleChange = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }


    const validate = (values) => {
        // e.preventDefault();
        //  console.log('Click enviar');
        let msgError = '';
        let flag;
        const typeStatus = document.getElementById("typeStatus");
        // if (values.company === "" || values.company === "DEFAULT") {
        //     msgError += "La compañia es requerida.\n";
        //     flag = false;
        // }
        if (values.name === "") {
            msgError += "El nombre de la devolución en ingles es requerido.\n";
            flag = false;
        }
        if (values.nameES === "") {
            msgError += "El nombre de la devolución en español es requerido.\n";
            flag = false;
        }
        if (values.description === "") {
            msgError += "La descripción es requerida.\n";
            flag = false;
        }


        if (msgError !== "") {
            msgError = "Todos los campos son requeridos."
            Swal.fire({
                title: 'Aviso',
                text: msgError,
                icon: 'warning',
            });

            flag = false;
        } else {
            flag = true;
        }

        return flag;

    }

    /* const handlerChangeSelector = (e) =>{
         e.preventDefault();
         console.log(e.target.value); // get the value = id firebase user
         selectCompanyState(e.target.value);
     }*/

    const handlerSaveEdit = async (e) => {
        e.preventDefault();
        const flag = validate(formValues);

        if (flag === true) {
            const data = {
                name: formValues.name.toUpperCase(),
                nameES: formValues.nameES.toUpperCase(),
                description: formValues.description.toUpperCase(),

            }

            const result = await editDevolution(data, currentUser.idCompany, idConpecto);
            if (result === 1) {
                Swal.fire({
                    title: 'Exitoso',
                    text: 'Devolución Editada.',
                    confirmButtonText: 'Ok',
                    icon: 'success',
                });

                setFormValues(initialValues);

            } else {
                //
                Swal.fire({
                    title: 'Aviso',
                    text: 'No se pudo editar la devolución.',
                    confirmButtonText: 'Ok',
                    icon: 'warning',
                });

                setFormValues(initialValues);

            }
        }
        //saveRegisterStatusByCompany
    }







    return (

        // <>

        //     {currentUser.typeUser === 1 ? 
        <> <div className='containerUser'>
            <div className='contentForm' style={{ paddingTop: '300px' }}>
                <div className='col s12 m12 l12 center'>
                    <div className=' center'><h4>Editar devolución</h4></div>
                    <div className='grettingsDiv'>

                    </div>
                    <form className='col s12 m12 l12'>
                        <div className='row'>

                            {/* <div className="input-field white lighten-5 inputRegisterUser spaceBottom20">
                                <i className="tiny material-icons prefix iconPositionInput "></i>
                                <select name="company"

                                    onChange={handleChange} required
                                    defaultValue={'DEFAULT'}
                                >
                                    <option key='0' value='DEFAULT' disabled>Select company</option>

                                    {

                                        listCompanies.map((company) => {
                                            //console.log(user);
                                            return <option key={company.id} value={company.idCompany}>{company.name}</option>
                                        })

                                    }
                                </select>

                            </div> */}

                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="name" placeholder="nombre en ingles" type="text" required id='name'
                                    value={formValues.name} onChange={handleChange} maxlength="50" />
                            </div>

                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="nameES" placeholder="nombre en español" type="text" required id='nameES'
                                    value={formValues.nameES} onChange={handleChange} maxlength="50" />
                            </div>


                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="description" placeholder="codigo: ej -> TAL04" type="text" className="" required id='description'
                                    value={formValues.description} onChange={handleChange} maxlength="10" />
                            </div>

                        </div>
                        <div className='registerDiv'>
                            <button
                                className='btn waves-effect waves-light btnFromRegisterCreate'
                                onClick={handlerSaveEdit}
                                type='button' name='Crear'>
                                Actualizar
                            </button>
                            <Link to='/conceptosDevolucion' className='btn btnFromLogin' id='btnFromLoginRU' style={{ background: '#5E5E68', width: '100px !important' }}>Salir</Link>
                        </div>
                    </form>

                </div>

            </div>
        </div></>
        // : <>
        //     <div><div className='containerUser'>
        //         <h3 className='center'>No tiene acceso a este apartado.</h3>
        //     </div>
        //     </div></>}

        // </>


    )
}

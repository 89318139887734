import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/authContext';
import { CircleGif } from '../CircleGif';
import M from 'materialize-css';
import { where, query, collection, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import { myFirestore } from '../../config/ConfigFirebase';
import { acceptOrCancelAccess, getAccessList } from '../../helpers/solicitudAccesFunc';
import { list } from 'firebase/storage';

export const Solicitudes = () => {
    const itemsPerPAge = 8;
    let count = 0;

    //Global info user dispatch
    const { currentUser } = useContext(AuthContext);
    const [listSolicitudes, setlistSolicitudes] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");

    //const getlistSolicitudes = objEvidences; // ciclica onsanpshot

    useEffect(() => {
        let isMounted = true;
        let modalM = document.querySelectorAll('.modal');
        M.Modal.init(modalM);
        //reset the paginations of evi and inci
        localStorage.setItem('savePage', JSON.stringify({
            pagination: 1
        }));
        localStorage.setItem('savePageInc', JSON.stringify({
            pagination: 1
        }));
        return () => {
            isMounted = false;
        };
    }, []);

    //list of Incidences
    const [listSolicitudesState, setlistSolicitudess] = useState({
        listSolicitudesData: [],
        loading: true,
    });

    useEffect(() => {
        let isMounted = true;
        /*getIncidence(serachText,currentUser.idCompany,currentUser.id,currentUser.typeUser).then((result)=>{
            //console.log(result);
            setlistSolicitudess({
                listSolicitudesData:result,
                loading:false,
            })
        })*/
        getAccessList(currentUser.idCompany, serachText).then((result) => {
            let listOfAccess = [];
            if (result.length === 0) {
                const myquery = query(collection(myFirestore, 'ServiceRequest'), where('idCompany', '==', currentUser.idCompany), orderBy("order", "desc"));
                console.info(myquery);
                // const subCollectionConvers = await getDocs(myquery);
                onSnapshot(myquery, (querySnapshot) => {
                    //console.logg('dasfd');
                    console.log("QuerySnapshot =>", querySnapshot);
                    if (querySnapshot === undefined) {
                        listOfAccess = [];

                    } else {
                        listOfAccess = [];
                        querySnapshot.forEach((doc) => {
                            /*querySnapshot.docChanges().forEach((doc)=>{
                                if(doc.type==="added"){
    
                                }
                                if(doc.type === "modified"){
    
                                }*/
                            let solicitud = {
                                id: doc.id,
                                nameOper: doc.data()['nameOperator'],
                                date: doc.data()['date'],
                                observa: doc.data()['observation'],
                                idCompany: doc.data()['idCompany'],
                                serviceGuide: doc.data()['serviceGuide'],
                                status: doc.data()['status'],
                                order: doc.data()['order'],
                                email: doc.data()['email']

                            }

                            listOfAccess.push(solicitud);
                        });
                    }

                    console.log("LISTCHAT==ONSnapshot==>", listOfAccess);
                    setlistSolicitudess({
                        listSolicitudesData: listOfAccess,
                        loading: false,
                    })

                });
            }
        });

        return () => {
            isMounted = false;
        };
    }, [page]);

    const { listSolicitudesData, loading } = listSolicitudesState;
    const getlistSolicitudes = listSolicitudesData;


    const onChangeSerachTextInc = (e) => {
        setSerachText(e.target.value);
    }

    const onHandelerAcceptOrCancel = async (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        Swal.fire({
            title: 'Generando permiso al documento.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            html: '<b>Por favor espere...</b>',
            didOpen: () => {
                Swal.showLoading()
            },
        })
        await acceptOrCancelAccess(e.target.id, currentUser.idCompany);
    }



    /**
    * GET List of Incidences
    */
    const retriveIncidences = () => {
        //const endOffset = (page) + itemsPerPAge;
        const items = getlistSolicitudes.slice((page - 1) * itemsPerPAge, (page - 1) * itemsPerPAge + itemsPerPAge);
        setlistSolicitudes(items);
        setPageCount(Math.ceil(getlistSolicitudes.length / itemsPerPAge));
    }



    /**
     * update list each click or page change
     */
    useEffect(() => {
        let isMounted = true;

        retriveIncidences();
        return () => {
            isMounted = false;
        };

    }, [page, itemsPerPAge, listSolicitudesState]);


    //serachForm
    const searchIncidence = (event) => {

        event.preventDefault();

        setlistSolicitudess({
            listSolicitudesData: [],
            loading: true,
        });

        /*getIncidence(serachText,currentUser.idCompany,currentUser.id,currentUser.typeUser).then((result)=>{
            console.log(result);
            setlistSolicitudess({
                listSolicitudesData:result,
                loading:false,
            })
        })*/

        if (listSolicitudesState.listSolicitudesData.length > 0) {
            retriveIncidences();
        }



    }









    const handlePageChange = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
    };

    const handlerBackArrowPage = () => {
        const arrowBack = document.getElementById('backli');
        if (page === 1) {
            arrowBack.classList.add('disabled');
            setPage(1);
        }
        if (page > 1) {
            arrowBack.classList.remove('disabled');
            const backPage = page - 1;
            setPage(backPage);
        }

    }

    const handlerNextArrowPage = () => {
        const netxArrow = document.getElementById('nextli');
        if (page === pageCount) {
            netxArrow.classList.add('disabled');
            //setPage(pageCount);
        }
        if (page < pageCount) {
            netxArrow.classList.remove('disabled');
            const nextPage = page + 1;
            setPage(nextPage);
        }
    }


    console.log('Soliciutd List ', listSolicitudes);
    //console.log(pageCount);

    const renderPagination = () => {
        let listPageCount = [];
        for (let index = 0; index < pageCount; index++) {

            if (index == 0) {
                let num = 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)

            } else {
                let num = index + 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)

            }

        }

        return listPageCount;
    }




    //renderTable Information 
    const listTableIncidence = () => {
        if (listSolicitudes.length > 0) {
            return (
                <div className='contentTable'>
                    <table className='striped highlight  responsive-table centered tableFont'>
                        <thead className='borderbotomLine'>
                            <tr>
                                <th className='center'>Fecha</th>
                                <th className='center'>Operador</th>
                                <th className='center'>Documento</th>
                                <th className='center'>Observación</th>

                                {currentUser.typeUser === 3 ? <th className='center'></th> : <div></div>}

                            </tr>
                        </thead>
                        <tbody>
                            {

                                listSolicitudes.map((incidence) => {
                                    count++;
                                    return <tr className={incidence.status === 0 ? 'newsEvidences' : ''}
                                        id={count % 2 === 0 ? 'rowHighLigth' : ''}
                                        key={incidence.id}>
                                        <td>{incidence.date}</td>
                                        <td>{incidence.nameOper}</td>


                                        <td>{incidence.serviceGuide}</td>
                                        <td>{incidence.observa}</td>
                                        {currentUser.typeUser === 3 ? <td>
                                            {incidence.status === 0 ? <div className='divSpaceOptin'>
                                                <button className='btn btnFromLogin' id={`${incidence.serviceGuide},${incidence.observa},${1},${incidence.order},${incidence.email}`} onClick={onHandelerAcceptOrCancel}><i className='material-icons' style={{ pointerEvents: 'none' }}>check</i></button>
                                                <button className='btn btnFromLoginCancel' id={`${incidence.serviceGuide},${incidence.observa},${2},${incidence.order},${incidence.email}`} onClick={onHandelerAcceptOrCancel}><i className='material-icons' style={{ pointerEvents: 'none' }}>close</i></button>
                                            </div> : <></>}

                                        </td> : <td></td>}
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <div className='row center'>
                        <ul className='pagination'>
                            <li className={`${page === 1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                            {renderPagination()}
                            <li className={`${page === pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            )
        } else {
            //
            <div className='center'>No cuenta con solicitudes</div>
        }
    }

    return (
        <>
            <div className='containerEvid'>

                <div className="" style={{ background: '#5E5E68' }}>
                    <form className='center' style={{ padding: '10px 0px' }} onSubmit={searchIncidence}>
                        <div className="input-field inputSerach ">
                            <i className="material-icons  prefix " style={{ color: '#A3A3A9', left: '0px', top: '10px' }}>search</i>
                            <input id="search" type="text" placeholder='Buscar' className='inputSerachBorderBottom' value={serachText} onChange={onChangeSerachTextInc} />

                        </div>
                    </form>
                </div>

                <div className='row center'><h4>Solicitudes de Acceso</h4></div>

                {loading === true ? <div className='row center'><CircleGif /></div> : listTableIncidence()}

            </div>

        </>
    )
}

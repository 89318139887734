import { auth, db, myFirebase, myFirestore, myStorage } from '../config/ConfigFirebase';
import firebaseConfig from '../config/ConfigFirebase';
import { collection, query, where, getDocs, orderBy, doc, collectionGroup, DocumentReference, Firestore, addDoc, Timestamp } from "firebase/firestore";
import { nameProyectFirebase } from '../config/global-variables';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { AuthContext } from '../auth/authContext';
import { now } from 'moment';

const collectionUsers = 'Users';

const getUsers = async (companyID, searchText) => {
    let listUsers = [];

    try {
        // console.log("companyID=>" + companyID);
        if (searchText === "") {
            const response = query(collection(myFirestore, collectionUsers), where('idCompany', '==', companyID), orderBy('name'));
            const querySnapshot = await getDocs(response);

            querySnapshot.forEach((doc) => {
                // console.log("=======>" + doc.data()['typeUserName']);
                // if (getUsers) {
                let user = {
                    id: doc.id,
                    email: doc.data()['email'],
                    image: doc.data()['image'],
                    lastSeen: doc.data()['lastSeen'],
                    name: doc.data()['name'],
                    status: doc.data()['status'],
                    active: doc.data()['active'],
                    typeUser: doc.data()['TypeUser']
                }
                listUsers.push(user);
                //}
            });
        }

        if (searchText !== "") {
            const response = query(collection(myFirestore, collectionUsers), where('idCompany', '==', companyID));
            const querySnapshot = await getDocs(response);

            querySnapshot.forEach((doc) => {
                // console.log("=======>" + doc.data()['typeUserName']);
                const nameUser = doc.data()['name'];
                const type = doc.data()['TypeUser'];
                const active = doc.data()['active'];

                if (searchText.toUpperCase() === 'Operador'.toUpperCase()) {
                    if (type === 4) {
                        let user = {
                            id: doc.id,
                            email: doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen: doc.data()['lastSeen'],
                            name: doc.data()['name'],
                            status: doc.data()['status'],
                            active: doc.data()['active'],
                            typeUser: doc.data()['TypeUser']
                        }
                        listUsers.push(user);
                    }
                }
                else if (searchText.toUpperCase() === 'Monitorista'.toUpperCase()) {
                    if (type === 3) {
                        let user = {
                            id: doc.id,
                            email: doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen: doc.data()['lastSeen'],
                            name: doc.data()['name'],
                            status: doc.data()['status'],
                            active: doc.data()['active'],
                            typeUser: doc.data()['TypeUser']
                        }
                        listUsers.push(user);
                    }
                }
                else if (searchText.toUpperCase() === 'Admin'.toUpperCase() || searchText.toUpperCase() === 'Administrador'.toUpperCase()) {
                    if (type === 2) {
                        let user = {
                            id: doc.id,
                            email: doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen: doc.data()['lastSeen'],
                            name: doc.data()['name'],
                            status: doc.data()['status'],
                            active: doc.data()['active'],
                            typeUser: doc.data()['TypeUser']
                        }
                        listUsers.push(user);
                    }
                }
                else if (searchText.toUpperCase() === 'Activo'.toUpperCase()) {
                    if (active === '1') {
                        let user = {
                            id: doc.id,
                            email: doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen: doc.data()['lastSeen'],
                            name: doc.data()['name'],
                            status: doc.data()['status'],
                            active: doc.data()['active'],
                            typeUser: doc.data()['TypeUser']
                        }
                        listUsers.push(user);
                    }
                }
                else if (searchText.toUpperCase() === 'Inactivo'.toUpperCase()) {
                    if (active === '0') {
                        let user = {
                            id: doc.id,
                            email: doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen: doc.data()['lastSeen'],
                            name: doc.data()['name'],
                            status: doc.data()['status'],
                            active: doc.data()['active'],
                            typeUser: doc.data()['TypeUser']
                        }
                        listUsers.push(user);
                    }
                }
                else if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
                    // if (getUsers) {
                    let user = {
                        id: doc.id,
                        email: doc.data()['email'],
                        image: doc.data()['image'],
                        lastSeen: doc.data()['lastSeen'],
                        name: doc.data()['name'],
                        status: doc.data()['status'],
                        active: doc.data()['active'],
                        typeUser: doc.data()['TypeUser']
                    }
                    listUsers.push(user);
                    // }
                }
            });
        }

    }
    catch (error) {
        //console.log("Error=>");
        listUsers = [];
    }

    return listUsers;

}

const getUser = async (email) => {
    let listUser = [];
    // console.log("userIDXX=>" + userID);
    try {
        const response = query(collection(myFirestore, collectionUsers), where('email', '==', email));
        const querySnapshot = await getDocs(response);

        querySnapshot.forEach((doc) => {
            // console.log("=======>" + doc.data()['typeUserName']);
            let user = {
                id: doc.id,
                email: doc.data()['email'],
                image: doc.data()['image'],
                lastSeen: doc.data()['lastSeen'],
                name: doc.data()['name'],
                status: doc.data()['status'],
                typeUser: doc.data()['TypeUser'],
                active: doc.data()['active'],
            }
            listUser.push(user);
        });
    }
    catch (error) {
        console.log("Error=>" + error);
    }

    return listUser;

}

export { getUsers, getUser };

import firebase from '../config/global-variables';
import Swal from 'sweetalert2';

const createNewCompany = async (info) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/createNewCompany`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                "accessDoc": parseInt(info.accesDoc),
                "idCompany": info.idCompany,
                "keyAccess": info.keyAccess,
                "name": info.name,
                "routing": info.routing,
                "shortName": info.shortname,
                "status": parseInt(info.status),     // Nota: 1->Activo  0->Inactivo
                "storageUrl": info.storageUrl,
                "tokenAccess": info.tokenAccess,
                "tokenAccessERP": info.tokenAccessERP,
                "userApp": parseInt(info.userApp),
                "userWeb": parseInt(info.userWeb),
                "phone": info.phone,
                "address": info.address,
                "accessLocation": info.accessLocation,
                "locationRadius": parseFloat(info.locationRadius).toFixed(2),
                "validateArrival": parseInt(info.validateArrival),
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        console.log(response);
        const data = await response.json();
        return data;


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}

export { createNewCompany };
import React, { useReducer, useEffect } from 'react'
import {BrowserRouter, Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import {toast, ToastContainer} from 'react-toastify'
import { AuthContext } from '../auth/authContext'
import {onAuthStateChanged} from 'firebase/auth';
import { authReducer } from '../auth/authReducer';

import { LoginPage } from '../pages/LoginPage'
import { DashbordRoot } from '../Routes/DashbordRoot'
import { auth, logOut } from '../config/ConfigFirebase';
import { getCurrentUser } from '../helpers/loginFunc';

import { PasswordPage } from '../pages/PasswordPage';


//Data INIT user info
const initData = () =>{
   return !localStorage.getItem('currentUser') ? {logged:false} : JSON.parse(localStorage.getItem('currentUser'));
}

//ROOT 
export const Root = () => {

   

const [currentUser,dispatch] = useReducer(authReducer,{},initData);

useEffect(()=>{
    if(!currentUser)return;
    localStorage.setItem('currentUser',JSON.stringify(currentUser));
},[currentUser])

 /**this function listen if the user is has authenticated 
 * if is auth return uid else return ""
*/
 onAuthStateChanged(auth,async(user)=>{
    
    let userData={};
    if(user){
        console.log("User signed in! -> "+user.uid );
       const data = await getCurrentUser(user.uid);
       userData={...data,logged:true};
       //currentUser = userData; 
    }
    else{
        console.log("User NOT signed in!");
        logOut();
        userData = {logged:false};
        //currentUser = userData;
        
    }
    
    return userData;

});




    return (
       
        <AuthContext.Provider value={{currentUser,dispatch}}>
            <BrowserRouter>
            {
            /**
             * showToast={showToast}
             */
        }
               
                <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
                position={toast.POSITION.TOP_CENTER}
                />

        
              <Routes>
                  
                  <Route path="/login" element = {
                    <PublicRoute>
                        <LoginPage  />
                    </PublicRoute>  
                    
                  
                  }/>

                    <Route path="/forgetpass" element = {
                        <PublicRoute>
                            <PasswordPage  />
                        </PublicRoute>  
                    
                  
                    }/>
                  
                  <Route path="/*" element={
                      <PrivateRoute>
                          <DashbordRoot />
                      </PrivateRoute>    
                  }/>

                  

                 
            </Routes>      
               
              
            </BrowserRouter>
        </AuthContext.Provider>
    )
}




import Swal from 'sweetalert2';
import firebase from '../config/global-variables';

const getPositions = async(userID,searctxt)=> {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getOperatorsLastPosition`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({
               managerID:userID,
               searchText:searctxt
            }),
           // mode:'no-cors'
        };
        const response = 
        await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        console.log('Positions =>',Data);
        return Data;
    } catch (error) {

        
        Swal.fire({
            title:'Error',
            text:'No se pudo conectar al servidor de Firebase, intentelo mas tarde.',
            icon:'error',
            confirmButtonText:'Ok',
        });

        return [];
        
    }
}

export{getPositions}


import React from 'react'
import { ConceptoGastosIndex } from '../components/ConceptoGastos/ConceptoGastosIndex'

export const ConceptoGastos = () => {
    return (
        <ConceptoGastosIndex />
    )
}

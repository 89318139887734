
import firebase from '../config/global-variables';
import Swal from 'sweetalert2';

const createExpense = async (info, idCompany) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/addserviceComboExpenses`;//falta name function
        console.log(info);
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                "comboCaption": info["comboCaption"],
                "comboCaptionES": info["comboCaptionES"],
                "comboCode": info["comboCode"],
                "comboName": info["comboName"],
                "comboNameES": info["comboNameES"],
                "comboType": 1,
                "idCompany": idCompany,
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        // console.log(response);
        const data = await response.json();
        return data;


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}


const editExpense = async (info, idCompany, idConcepto) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/editServiceComboExpenses`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({

                "comboCaption": info["comboCaption"],
                "comboCaptionES": info["comboCaptionES"],
                "comboCode": info["comboCode"],
                "comboName": info["comboName"],
                "comboNameES": info["comboNameES"],
                "comboType": 1,
                "idCompany": idCompany,
                "idDoc": idConcepto
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        // console.log(response);
        const data = await response.json();
        return data;


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}

const createDevolution = async (info, idCompany) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/addCatCausesDevolutions`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                "description": info["description"],
                "name": info["name"],
                "nameES": info["nameES"],
                "idCompany": idCompany,
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        console.log(response);
        const data = await response.json();
        return data;


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}

const editDevolution = async (info, idCompany, idConcepto) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/editCatCausesDevolutions`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                "description": info["description"],
                "name": info["name"],
                "nameES": info["nameES"],
                "idCompany": idCompany,
                "idDoc": idConcepto
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        console.log(response);
        const data = await response.json();
        return data;


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}


const removeDevolution = async (idConcepto) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/removeDevolution`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({

                "idConcept": idConcepto,
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        if (response.status === 200) {
            Swal.close();
            Swal.fire({
                title: 'Eliminado',
                text: 'La devolución fue eliminada exitosamente.',
                icon: 'success',
            }).then(function () {
                window.location.reload();
            })
        }


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}


const removeExpense = async (idConcepto) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/removeExpense`;//falta name function

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({

                "idConcept": idConcepto,
            }),
            // mode:'no-cors'
        };

        const response =
            await fetch(url, optionsRequest);
        if (response.status === 200) {
            Swal.close();
            Swal.fire({
                title: 'Eliminado',
                text: 'El concepto fue eliminado exitosamente.',
                icon: 'success',
            }).then(function () {
                window.location.reload();
            })
        }


    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información' + error,
            icon: 'error'
        });
        return 0;
    }
}


const getExpenses = async (idCompany, serachText) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getExpenses`;
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                value: serachText,
                companyID: idCompany
            }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        console.log('dataText =>', data);
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('Expenses FROM SERVER =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop('Gastos de viaje');
        return [];
        //swal alert to return to home
    }
}

const getDevolutions = async (idCompany, serachText) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getDevolutions`;
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                value: serachText,
                companyID: idCompany
            }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        console.log('dataText =>', data);
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('devolutions FROM SERVER =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop(' devoluciónes');
        return [];
        //swal alert to return to home
    }
}

const swalMessagepop = (title) => {
    Swal.fire({
        title: 'Advertencia',
        text: 'No se pudo obtener la información de' + title + ', inténtelo más tarde.',
        icon: 'error',
        confirmButtonText: 'Ok',
    });
}

const getOneExpense = async (idConcepto) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getOneExpense/${idConcepto}`;
        const optionsRequest = {
            method: 'GET',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            // body: JSON.stringify({
            //     value: serachText,
            //     companyID: idCompany
            // }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        console.log('dataText =>', data);
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('gasto FROM SERVER =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop('conceptos de gasto');
        return [];
        //swal alert to return to home
    }
}

const getOneDevolution = async (idConcepto) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getOneDevolution/${idConcepto}`;
        const optionsRequest = {
            method: 'GET',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            // body: JSON.stringify({
            //     value: serachText,
            //     companyID: idCompany
            // }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        //console.log('dataText =>', data);
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('devolutions FROM SERVER =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop(' devolución ');
        return [];
        //swal alert to return to home
    }
}

export { createExpense, createDevolution, editDevolution, editExpense, removeDevolution, removeExpense, getExpenses, getDevolutions, getOneExpense, getOneDevolution };




import React from 'react'

import { RefaundInfo } from '../components/Refaunds/RefaundInfo'

export const RefaundInfoPage = () => {
    return (
        <RefaundInfo />
    )
}


import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../auth/authContext';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import { createExpense } from '../../helpers/conceptosFunc';


export const RegisterGasto = () => {

    const { currentUser } = useContext(AuthContext);

    // const [logingCompanies, setLoadingCompanies] = useState(true);
    // const [listCompanies, setListCompanies] = useState([]);
    const initialValues = { gastoName: "", gastoNameEs: "", comboCode: "", comboCaptionES: "", comboCaption: "" };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        let isMounted = true;
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);

        // getCompanies().then((result) => {
        //     setListCompanies(result)
        //     setLoadingCompanies(false)
        // })


        return () => {
            isMounted = false;
        };

    }, []);//logingCompanies

    const handleChange = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }


    const validate = (values) => {
        // e.preventDefault();
        //  console.log('Click enviar');
        let msgError = '';
        let flag;
        const typeStatus = document.getElementById("typeStatus");
        // if (values.company === "" || values.company === "DEFAULT") {
        //     msgError += "La compañia es requerida.\n";
        //     flag = false;
        // }
        if (values.gastoName === "") {
            msgError += "El nombre del gasto en ingles es requerido.\n";
            flag = false;
        }
        if (values.gastoNameEs === "") {
            msgError += "El nombre del gasto en español es requerido.\n";
            flag = false;
        }
        if (values.comboCode === "") {
            msgError += "El codigo del gasto es requerio.\n";
            flag = false;
        }
        if (values.comboCaption === "") {
            msgError += "El Nombre en ingles es requerido.\n";
            flag = false;
        }
        if (values.comboCaptionES === "") {
            msgError += "El Nombre en español es requerido.\n";
            flag = false;
        }


        if (msgError !== "") {
            msgError = "Todos los campos son requeridos."
            Swal.fire({
                title: 'Aviso',
                text: msgError,
                icon: 'warning',
            });

            flag = false;
        } else {
            flag = true;
        }

        return flag;

    }

    /* const handlerChangeSelector = (e) =>{
         e.preventDefault();
         console.log(e.target.value); // get the value = id firebase user
         selectCompanyState(e.target.value);
     }*/

    const handlerSaveRegister = async (e) => {
        e.preventDefault();
        const flag = validate(formValues);

        if (flag === true) {
            const data = {
                comboName: formValues.gastoName.toUpperCase(),
                comboNameES: formValues.gastoNameEs.toUpperCase(),
                comboCode: formValues.comboCode.toUpperCase(),
                comboCaptionES: formValues.comboCaptionES.toUpperCase(),
                comboCaption: formValues.comboCaption.toUpperCase(),

            }

            const result = await createExpense(data, currentUser.idCompany);
            if (result === 1) {
                Swal.fire({
                    title: 'Exitoso',
                    text: 'Concepto registrado.',
                    confirmButtonText: 'Ok',
                    icon: 'success',
                });

                setFormValues(initialValues);

            } else {
                //
                Swal.fire({
                    title: 'Aviso',
                    text: 'No se pudo registrar el concepto.',
                    confirmButtonText: 'Ok',
                    icon: 'warning',
                });

                setFormValues(initialValues);

            }
        }
        //saveRegisterStatusByCompany
    }







    return (

        // <>

        //     {currentUser.typeUser === 1 ? 
        <> <div className='containerUser'>
            <div className='contentForm' style={{ paddingTop: '300px' }}>
                <div className='col s12 m12 l12 center'>
                    <div className=' center'><h4>Registro de conceptos de gastos</h4></div>
                    <div className='grettingsDiv'>

                    </div>
                    <form className='col s12 m12 l12'>
                        <div className='row'>

                            {/* <div className="input-field white lighten-5 inputRegisterUser spaceBottom20">
                                <i className="tiny material-icons prefix iconPositionInput "></i>
                                <select name="company"

                                    onChange={handleChange} required
                                    defaultValue={'DEFAULT'}
                                >
                                    <option key='0' value='DEFAULT' disabled>Select company</option>

                                    {

                                        listCompanies.map((company) => {
                                            //console.log(user);
                                            return <option key={company.id} value={company.idCompany}>{company.name}</option>
                                        })

                                    }
                                </select>

                            </div> */}

                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="gastoName" placeholder="nombre en ingles" type="text" required id='gastoName'
                                    value={formValues.gastoName} onChange={handleChange} maxlength="50" />
                            </div>

                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="gastoNameEs" placeholder="nombre en español" type="text" required id='gastoNameEs'
                                    value={formValues.gastoNameEs} onChange={handleChange} maxlength="50" />
                            </div>


                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="comboCode" placeholder="código: ej -> TAL04" type="text" className="" required id='comboCode'
                                    value={formValues.comboCode} onChange={handleChange} maxlength="10" />
                            </div>


                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="comboCaption" placeholder="nombre en ingles: ej Food" type="text" className="" required id='repass'
                                    value={formValues.comboCaption} onChange={handleChange} maxlength="50" />
                            </div>

                            <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                <input name="comboCaptionES" placeholder="nombre en español: ej Comida" type="text" className="" required id='repass'
                                    value={formValues.comboCaptionES} onChange={handleChange} maxlength="50" />
                            </div>




                        </div>
                        <div className='registerDiv'>
                            <button
                                className='btn waves-effect waves-light btnFromRegisterCreate'
                                onClick={handlerSaveRegister}
                                type='button' name='Crear'>
                                Crear
                            </button>
                            <Link to='/conceptosGastos' className='btn btnFromLogin' id='btnFromLoginRU' style={{ background: '#5E5E68', width: '100px !important' }}>Salir</Link>
                        </div>
                    </form>

                </div>

            </div>
        </div></>
        // : <>
        //     <div><div className='containerUser'>
        //         <h3 className='center'>No tiene acceso a este apartado.</h3>
        //     </div>
        //     </div></>}

        // </>


    )
}

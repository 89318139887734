import Swal from 'sweetalert2';
import firebase from '../config/global-variables';

const registerVideo = async (info) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/VideosApp`;

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                title: info.title,
                url: info.url,
                description: info.description
            }),
            // mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        console.log(data);

        Swal.fire({
            title: 'Exitoso',
            text: 'El video a sido registrado.',
            icon: 'success'
        });

    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error al conectarse al servidor.',
            icon: 'error'
        });
        return false;

    }
}

export { registerVideo }
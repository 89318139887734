import { myFirestore } from "../config/ConfigFirebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  getDoc,
  onSnapshot,
  addDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import firebase from "../config/global-variables";
import Swal from "sweetalert2";
const collectionUser = "Users";
const collectionConversation = "Conversations";

const getMonitoristas = async (idCompany, uid) => {
  let listUsers = [];

  try {
    const response = query(
      collection(myFirestore, collectionUser),
      where("idCompany", "==", idCompany),
      where("TypeUser", "==", 3),
      orderBy("name")
    );
    const querySnapshot = await getDocs(response);

    //console.log(querySnapshot.size);
    querySnapshot.forEach((doc) => {
      let user = {
        id: doc.id,
        name: doc.data()["name"],
        emial: doc.data()["email"],
        // lastSeen:doc.data()['lastSeen'],
        //serviceID: doc.data()['serviceID'],
        status: doc.data()["status"],
        token: doc.data()["token"],
        tokenWeb: doc.data()["tokenWeb"],
        image: doc.data()["image"],
        userType: doc.data()["TypeUser"],
      };
      if (doc.id !== uid) {
        listUsers.push(user);
      }
    });

    // console.log(listUsers);
    return listUsers;
  } catch (error) {
    Swal.fire({
      title: "Problema de conexión.",
      text: "No se puedo obtener la lista de Monitoristas por algun problema de conexión al servicio de Firebase.",
      icon: "error",
    });
    return [];
  }
};

const getOperadores = async (idCompany, uid, searchText) => {
  let listUsers = [];

  try {
    if (searchText === "") {
      const response = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const querySnapshot = await getDocs(response);

      //console.log(querySnapshot.size);
      querySnapshot.forEach((doc) => {
        let user = {
          id: doc.id,
          name: doc.data()["name"],
          emial: doc.data()["email"],
          // lastSeen:doc.data()['lastSeen'],
          serviceID: doc.data()["serviceID"],
          status: doc.data()["status"],
          token: doc.data()["token"],
          tokenWeb: doc.data()["tokenWeb"],
          image: doc.data()["image"],
          userType: doc.data()["TypeUser"],
        };
        /*if(doc.id !== uid && doc.data()['managerID'] === ""){
                     listUsers.push(user);
                }*/
        if (doc.id !== uid) {
          listUsers.push(user);
        }
      });
    }

    if (searchText !== "") {
      const newResponse = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const newquerySnapshot = await getDocs(newResponse);

      //console.log(newquerySnapshot.size);
      newquerySnapshot.forEach((doc) => {
        const nameUser = doc.data()["name"];
        if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
          let user = {
            id: doc.id,
            name: doc.data()["name"],
            emial: doc.data()["email"],
            // lastSeen:doc.data()['lastSeen'],
            serviceID: doc.data()["serviceID"],
            status: doc.data()["status"],
            token: doc.data()["token"],
            tokenWeb: doc.data()["tokenWeb"],
            image: doc.data()["image"],
            userType: doc.data()["TypeUser"],
          };
          /*if(doc.id !== uid && doc.data()['managerID'] === ""){
                   listUsers.push(user);
              }*/
          if (doc.id !== uid) {
            listUsers.push(user);
          }
        }
      });
    }

    //console.log(listUsers);
    return listUsers;
  } catch (error) {
    Swal.fire({
      title: "Problema de conexión.",
      text: "No se puedo obtener la lista de operadores por algun problema de conexión a la base de datos.",
      icon: "error",
    });
    return [];
  }
};

const getOperadoresAsignados = async (idCompany, uid, searchText) => {
  let listUsers = [];

  try {
    if (searchText == "") {
      const response = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const querySnapshot = await getDocs(response);

      //console.log(querySnapshot.size);
      querySnapshot.forEach((doc) => {
        let user = {
          id: doc.id,
          name: doc.data()["name"],
          emial: doc.data()["email"],
          // lastSeen:doc.data()['lastSeen'],
          serviceID: doc.data()["serviceID"],
          status: doc.data()["status"],
          token: doc.data()["token"],
          tokenWeb: doc.data()["tokenWeb"],
          image: doc.data()["image"],
          userType: doc.data()["TypeUser"],
          managerID: doc.data()["managerID"],
        };
        /*if( doc.data()['managerID'] === uid ){
                   listUsers.push(user);
              }*/
        let listOfManagers = doc.data()["managerID"].split(",");
        console.log("Lista de Managers: ", listOfManagers);

        listOfManagers.forEach((mid) => {
          if (mid === uid) {
            listUsers.push(user);
          }
        });
      });
    }
    if (searchText != "") {
      const response = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const querySnapshot = await getDocs(response);

      //console.log(querySnapshot.size);
      querySnapshot.forEach((doc) => {
        const nameUser = doc.data()["name"];
        if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
          let user = {
            id: doc.id,
            name: doc.data()["name"],
            emial: doc.data()["email"],
            // lastSeen:doc.data()['lastSeen'],
            serviceID: doc.data()["serviceID"],
            status: doc.data()["status"],
            token: doc.data()["token"],
            tokenWeb: doc.data()["tokenWeb"],
            image: doc.data()["image"],
            userType: doc.data()["TypeUser"],
            managerID: doc.data()["managerID"],
          };
          /*if( doc.data()['managerID'] === uid ){
               listUsers.push(user);
          }*/
          let listOfManagers = doc.data()["managerID"].split(",");
          console.log("Lista de Managers: ", listOfManagers);

          listOfManagers.forEach((mid) => {
            if (mid === uid) {
              listUsers.push(user);
            }
          });
        }
      });
    }

    //console.log(listUsers);
    return listUsers;
  } catch (error) {
    Swal.fire({
      title: "Problema de conexión.",
      text: "No se puedo obtener la lista de operadores asignados, por algun problema de conexión al servicio de Firebase.",
      icon: "error",
    });
    return [];
  }
};

/*
  Operadres sin los asingados
 */
const getOperadoresSinAsignados = async (idCompany, uid, searchText) => {
  let listUsers = [];
  let flasg = false;
  try {
    if (searchText === "") {
      const response = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const querySnapshot = await getDocs(response);

      querySnapshot.forEach((doc) => {
        flasg = false;
        let user = {
          id: doc.id,
          name: doc.data()["name"],
          emial: doc.data()["email"],
          // lastSeen:doc.data()['lastSeen'],
          serviceID: doc.data()["serviceID"],
          status: doc.data()["status"],
          token: doc.data()["token"],
          tokenWeb: doc.data()["tokenWeb"],
          image: doc.data()["image"],
          userType: doc.data()["TypeUser"],
          managerID: doc.data()["managerID"],
        };
        /*if( doc.data()['managerID'] === uid ){
                 listUsers.push(user);
            }*/
        let listOfManagers = doc.data()["managerID"].split(",");
        console.log("Lista de Managers: ", listOfManagers);

        listOfManagers.forEach((mid) => {
          if (mid === uid) {
            flasg = true;
          }
        });

        if (flasg === false) {
          listUsers.push(user);
        }
      });
    }

    if (searchText !== "") {
      const response = query(
        collection(myFirestore, collectionUser),
        where("idCompany", "==", idCompany),
        where("TypeUser", "==", 4),
        orderBy("name")
      );
      const querySnapshot = await getDocs(response);

      querySnapshot.forEach((doc) => {
        flasg = false;
        const nameUser = doc.data()["name"];
        if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
          let user = {
            id: doc.id,
            name: doc.data()["name"],
            emial: doc.data()["email"],
            // lastSeen:doc.data()['lastSeen'],
            serviceID: doc.data()["serviceID"],
            status: doc.data()["status"],
            token: doc.data()["token"],
            tokenWeb: doc.data()["tokenWeb"],
            image: doc.data()["image"],
            userType: doc.data()["TypeUser"],
            managerID: doc.data()["managerID"],
          };
          /*if( doc.data()['managerID'] === uid ){
                 listUsers.push(user);
            }*/
          let listOfManagers = doc.data()["managerID"].split(",");
          console.log("Lista de Managers: ", listOfManagers);

          listOfManagers.forEach((mid) => {
            if (mid === uid) {
              flasg = true;
            }
          });

          if (flasg === false) {
            listUsers.push(user);
          }
        }
      });
    }

    // console.log('data sin el uid',listUsers);
    return listUsers;
  } catch (error) {
    Swal.fire({
      title: "Problema de conexión.",
      text: "No se puedo obtener la lista de operadores por algun problema de conexión a la base de datos.",
      icon: "error",
    });
    return [];
  }
};

/**
 * Asignar operadores
 */

const asignOperadores = async (list, managerID, acction) => {
  try {
    const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/UsersUpdateManagerID`;

    /*const infoReciber = doc(myFirestore,'Users',reciberID);
        const respReciber = await getDoc(infoReciber);
        let tokenRe='';
        if (respReciber.exists()) {
            tokenRe = respReciber.data()['token'];
        }*/

    const optionsRequest = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        ListOperators: list,
        ManagerID: managerID,
        TypeAction: acction,
      }),
      //mode:'no-cors'
    };

    const response = await fetch(url, optionsRequest);

    const data = await response.json();

    console.log("RESULT => ", data);
    return true;
  } catch (error) {
    Swal.fire({
      title: "Problema de conexión al servidor.",
      text: "No se puedo asignar los operadores, por algun problema de conexión al servicio de Firebase.",
      icon: "error",
    });
    return false;
  }
};

export {
  getMonitoristas,
  getOperadores,
  getOperadoresAsignados,
  asignOperadores,
  getOperadoresSinAsignados,
};

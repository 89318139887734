import React from "react";

export const ChatBlankPage = () => {
  return (
    <div className="center ">
      <h4 className="title">DRIVELOG</h4>
      <h5 className="">Conversa con tu equipo.</h5>
      <div className="container" id="fondoChat">
        <img
          id="fondoPicBlank"
          alt="homeCHat"
          src={`/assets/chatBlank.png`}
          className="responsive-img"
        />
      </div>
    </div>
  );
};

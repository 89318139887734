import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import Swal from "sweetalert2";
import {
  createOrGetConversation,
  sendGroupMessageByConversations,
} from "../../helpers/chatFunc";


export const CardInfo = (objcte) => {

  const { currentUser } = useContext(AuthContext);
  const [chatState, setChatStat] = useState("");
  //const {objcte} = objcte;
  //console.log('OBJECT IN CARD => ',objcte.objcte);
  // const { handlerCardInfo } = fucn;
  // console.log('FUNCTINONs=> ', fucn);
  //showPopMessage
  // const showPopupMessage = (chatID) => {
  //   Swal.fire({
  //     input: "textarea",
  //     //inputLabel: 'Message',
  //     title: "Enviar Mensaje",
  //     //text:name,
  //     inputPlaceholder: "Escriba su mensaje aquí...",
  //     inputAttributes: {
  //       "aria-label": "Escriba su mensaje aquí",
  //     },

  //     showCancelButton: true,
  //     confirmButtonText: "Enviar",
  //     cancelButtonText: "Cancelar",
  //   }).then(async (result) => {
  //     if (result.dismiss === "cancel") {
  //       setChatStat("");
  //     } else if (result.dismiss === "backdrop") {
  //       setChatStat("");
  //     } else {
  //       if (result.value !== "") {
  //         let listChatOnlyOne = [];
  //         listChatOnlyOne.push(chatID);
  //         //console.log('good',result.value);
  //         //const {selectedChats} = selectChat;
  //         //console.log('sendMessage to CHAT=>',listChatOnlyOne);
  //         await sendGroupMessageByConversations(
  //           listChatOnlyOne,
  //           currentUser.id,
  //           result.value
  //         );
  //         setChatStat("");
  //         Swal.fire({
  //           icon: "success",
  //           title: "Entrega exitosa",
  //           text: "El mensaje fue enviado con éxito",
  //         });
  //         //funcion para enviar mensajes por n conversaciones
  //       }

  //       if (result.value === "") {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Aviso",
  //           text: "El mensaje no puede ir vació.",
  //         });
  //       }
  //     }
  //   });
  // };

  //getConversationID
  // const handlerCreateOrGetChat = (e) => {
  //   e.preventDefault();
  //   let recipentID = e.target.id;
  //   //console.log(recipentID);
  //   createOrGetConversation(
  //     currentUser.id,
  //     recipentID,
  //     currentUser.idCompany
  //   ).then((res) => {
  //     console.log(res);
  //     setChatStat(res);
  //     if (res !== "") {
  //       showPopupMessage(res);
  //     } else {
  //       //erro pop
  //     }
  //   });
  // };

  return (
    <div className="card cdesing" style={{ pointerEvents: "none" }}>
      <div className="card-content pad15" style={{ pointerEvents: "none" }}>
        <div className="dataInfoCardrw" style={{ pointerEvents: "none" }}>
          <div className="colFlex3" style={{ pointerEvents: "none" }}>
            <div
              style={{ pointerEvents: "none" }}

            >
              <b>
                <span className="black-text" style={{ pointerEvents: "none" }}>
                  {objcte.objcte.user_name}
                </span>
              </b>
            </div>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title  black-text text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Destino</b>
              </span>
              <span
                className=" sizeDestino text11"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.destination}
              </span>
            </div>

            <div className="colFlex">
              {/* {currentUser.typeUser === 3 ? (
                <Link
                  to=""
                  id={objcte.objcte.user_id}
                  style={{ marginRight: "10px" }}
                  onClick={handlerCreateOrGetChat}
                >
                  <i
                    className="material-icons iconsList "
                    id={objcte.objcte.user_id}
                    style={{ pointerEvents: "auto" }}
                  >
                    question_answer
                  </i>
                </Link>
              ) : (
                <></>
              )} */}
            </div>
          </div>

          <div className="colFlex3" style={{ pointerEvents: "none" }}>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title center black-text text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Unidad</b>
              </span>
              <span
                className="center text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.vehicleNumber}
              </span>
            </div>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title  black-text center text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Estatus</b>
              </span>
              <span
                className="center  text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.serviceStatus}
              </span>
            </div>


            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title center  black-text  text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none", }}>Ubicación</b>
              </span>
              <span
                className="  text11  center cardtextColorInformation"
                style={{ pointerEvents: "none", }}
              >
                {/* <abbr title={`${objcte.objcte.location}`} style={{ textDecoration: "unset" }}> */}
                {objcte.objcte.location}
                {/* </abbr> */}
              </span>
            </div>

          </div>

          <div className="colFlex20" style={{ pointerEvents: "none" }}>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title center black-text text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Distancia</b>
              </span>
              <span
                className="center text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.distance}
              </span>
            </div>

            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span className="title center  black-text text11">
                <b style={{ pointerEvents: "none" }}>Destinatario</b>
              </span>
              <span
                className=" center  text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.addressee}
              </span>
            </div>
          </div>

          <div className="colFlex20" style={{ pointerEvents: "none" }}>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title center  black-text text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Remolques</b>
              </span>
              <span
                className=" center  text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.trailerNumber}
              </span>
            </div>
            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title center  black-text text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Bitácora </b>
              </span>
              <span
                className=" center  text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.serviceReference}
              </span>
            </div>

            <div className="colFlex" style={{ pointerEvents: "none" }}>
              <span
                className="title  black-text center text11"
                style={{ pointerEvents: "none" }}
              >
                <b style={{ pointerEvents: "none" }}>Fecha</b>
              </span>
              <span
                className="center  text11 cardtextColorInformation"
                style={{ pointerEvents: "none" }}
              >
                {objcte.objcte.dateString}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { objEvidences } from './exampleEvidenceObj';
import Swal from "sweetalert2";
import { MapPopup } from "../MapPopup";
import M from "materialize-css";
import { AuthContext } from "../../auth/authContext";
import {
    createOrGetConversation,
    sendGroupMessageByConversations,
} from "../../helpers/chatFunc";

import { CircleGif } from "../CircleGif";
//import { deleteRowAndphotos } from "../../helpers/refundsFunc"; // CAMBIAR
import { getRefunds } from "../../helpers/refundsFunc";

export const Refaunds = () => {
    const itemsPerPAge = 8;
    let count = 0;

    //Global info user dispatch
    const { currentUser } = useContext(AuthContext);
    const [listRefunds, setListRefunds] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    const [locations, setLocations] = useState({
        latitude: undefined,
        longitude: undefined,
    });
    const [savePage, setSavePage] = useState({
        pagination: 1,
    });
    const [stateDataToMap, setDataToMap] = useState({});
    const [chatState, setChatStat] = useState("");

    //const getListIncidence = objEvidences; // ciclica onsanpshot
    const { pagination } = savePage;
    let optionsDate = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric"
    };

    //variables to DatesPICKERS
    function subtractDays(date, days) {
        date.setDate(date.getDate() - days);

        return date.toLocaleString('es-MX', optionsDate).split(',')[1];
    }
    const backDays = subtractDays(new Date(Date.now()), 15)
    const defaultDate = new Date(Date.now()).toLocaleString('es-MX', optionsDate).split(',')[1];

    let currenDate = defaultDate;
    const [initDate, setInitDate] = useState(backDays);
    const [endDate, setEndDate] = useState(currenDate);

    useEffect(() => {
        let isMounted = true;
        let modalM = document.querySelectorAll(".modal");
        M.Modal.init(modalM);
        let pickerDate = document.getElementById("initDate");
        let endDatePick = document.getElementById("endDate");
        //START DATE
        M.Datepicker.init(pickerDate, {
            format: 'dd/mm/yyyy',
            autoClose: true,
            selectMonths: true,
            // onClose: handleDate(),
            i18n: {
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
                weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
                cancel: 'Cancelar',
                clear: 'Limpar',
                done: 'Ok',
                today: 'Hoy',

                previousMonth: '<',
                nextMonth: '>',
            },
            onSelect: function (date) {
                //console.info(date.getDate());
                let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];
                document.getElementById("initDate").value = date.toLocaleString().split(',')[0];
                setInitDate(date.toLocaleString().split(',')[0]);
            },
            onOpen: function () {
                let instance = M.Datepicker.getInstance(document.getElementById("initDate"));
                let splitInitDate = document.getElementById("initDate").value.split('/');
                instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
            }
        });
        //END DATE
        M.Datepicker.init(endDatePick, {
            format: 'dd/mm/yyyy',
            autoClose: true,
            selectMonths: true,
            // onClose: handleDate(),
            i18n: {
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
                weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
                cancel: 'Cancelar',
                clear: 'Limpar',
                done: 'Ok',
                today: 'Hoy',

                previousMonth: '<',
                nextMonth: '>',
            },
            onSelect: function (date) {

                let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];
                console.error(finalDate);
                document.getElementById("endDate").value = date.toLocaleString().split(',')[0];
                setEndDate(date.toLocaleString().split(',')[0]);
            },
            onOpen: function () {
                let instance = M.Datepicker.getInstance(document.getElementById("endDate"));
                let splitInitDate = document.getElementById("endDate").value.split('/');
                instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
            }


        });
        //reset pagination evidences to 1
        localStorage.setItem(
            "savePage",
            JSON.stringify({
                pagination: 1,
            })
        );
        let pagination;
        //save the pagination

        if (!localStorage.getItem("savePageInc")) {
            const remeberPage = localStorage.getItem("savePageInc");
            if (remeberPage !== null) {
                const objRemebermeData = JSON.parse(remeberPage);
                console.log("Pagination => ", objRemebermeData["pagination"]);
                setPage(objRemebermeData["pagination"]);
            }
            setSavePage({
                pagination: page,
            });
            pagination = {
                pagination: page,
            };
            localStorage.setItem("savePageInc", JSON.stringify(pagination));
        } else {
            const remeberPage = localStorage.getItem("savePageInc");
            if (remeberPage !== null) {
                const objRemebermeData = JSON.parse(remeberPage);
                console.log("Pagination => ", objRemebermeData["pagination"]);
                setPage(objRemebermeData["pagination"]);
            }
            localStorage.removeItem("savePageInc");
            setSavePage({
                pagination: page,
            });
            pagination = {
                pagination: page,
            };
            localStorage.setItem("savePageInc", JSON.stringify(pagination));
        }
        // save and get pagination END

        return () => {
            isMounted = false;
        };
    }, []);

    //list of Incidences
    const [listRefundsState, setListRefundss] = useState({
        listRefundsData: [],
        loading: true,
    });

    useEffect(() => {
        let isMounted = true;

        if (document.getElementById("endDate").value !== '') {
            let startDateF = document.getElementById("initDate").value;
            let endDateF = document.getElementById("endDate").value;
            if (Date.parse(endDateF) < Date.parse(startDateF)) {
                //show dialog
                Swal.fire({
                    icon: "warning",
                    title: "Aviso",
                    text: "La Fecha final debe ser mayor a la fecha inicial.",
                });
                let endDateF = document.getElementById("endDate").value = "";
                setEndDate(endDateF);
                getRefunds(
                    serachText,
                    currentUser.idCompany,
                    currentUser.id,
                    currentUser.typeUser,
                    document.getElementById("initDate").value,
                    document.getElementById("endDate").value
                ).then((result) => {
                    //console.log(result);
                    setListRefundss({
                        listRefundsData: result,
                        loading: false,
                    });
                });
            } else {
                getRefunds(
                    serachText,
                    currentUser.idCompany,
                    currentUser.id,
                    currentUser.typeUser,
                    document.getElementById("initDate").value,
                    document.getElementById("endDate").value
                ).then((result) => {
                    //console.log(result);
                    setListRefundss({
                        listRefundsData: result,
                        loading: false,
                    });
                });
            }
        } else {
            getRefunds(
                serachText,
                currentUser.idCompany,
                currentUser.id,
                currentUser.typeUser,
                document.getElementById("initDate").value,
                document.getElementById("endDate").value
            ).then((result) => {
                //console.log(result);
                setListRefundss({
                    listRefundsData: result,
                    loading: false,
                });
            });
        }

        return () => {
            isMounted = false;
        };
    }, [page, endDate, initDate]);

    const { listRefundsData, loading } = listRefundsState;
    const getListIncidence = listRefundsData;

    const onChangeSerachTextInc = (e) => {
        setSerachText(e.target.value);
    };

    /**
     * GET List of Incidences
     */
    const retriveIncidences = () => {
        //const endOffset = (page) + itemsPerPAge;
        const items = getListIncidence.slice(
            (page - 1) * itemsPerPAge,
            (page - 1) * itemsPerPAge + itemsPerPAge
        );
        setListRefunds(items);
        setPageCount(Math.ceil(getListIncidence.length / itemsPerPAge));
    };

    /**
     * update list each click or page change
     */
    useEffect(() => {
        let isMounted = true;

        retriveIncidences();
        return () => {
            isMounted = false;
        };
    }, [page, itemsPerPAge, listRefundsState]);

    //serachForm
    const searchIncidence = (event) => {
        event.preventDefault();

        setListRefundss({
            listRefundsData: [],
            loading: true,
        });

        getRefunds(
            serachText,
            currentUser.idCompany,
            currentUser.id,
            currentUser.typeUser,
            document.getElementById("initDate").value,
            document.getElementById("endDate").value
        ).then((result) => {
            console.log(result);
            setListRefundss({
                listRefundsData: result,
                loading: false,
            });
        });

        if (listRefundsState.listRefundsData.length > 0) {
            retriveIncidences();
        }
    };

    //showPopMessage
    const showPopupMessage = (chatID) => {
        Swal.fire({
            input: "textarea",
            //inputLabel: 'Message',
            title: "Enviar Mensaje",
            //text:name,
            inputPlaceholder: "Escriba su mensaje aquí...",
            inputAttributes: {
                "aria-label": "Escriba su mensaje aquí",
            },

            showCancelButton: true,
            confirmButtonText: "Enviar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.dismiss === "cancel") {
                setChatStat("");
            } else if (result.dismiss === "backdrop") {
                setChatStat("");
            } else {
                if (result.value !== "") {
                    let listChatOnlyOne = [];
                    listChatOnlyOne.push(chatID);
                    //console.log('good',result.value);
                    //const {selectedChats} = selectChat;
                    //console.log('sendMessage to CHAT=>',listChatOnlyOne);
                    await sendGroupMessageByConversations(
                        listChatOnlyOne,
                        currentUser.id,
                        result.value
                    );
                    setChatStat("");
                    Swal.fire({
                        icon: "success",
                        title: "Entrega exitosa",
                        text: "El mensaje fue enviado con éxito.",
                    });
                    //funcion para enviar mensajes por n conversaciones
                }

                if (result.value === "") {
                    Swal.fire({
                        icon: "warning",
                        title: "Aviso",
                        text: "El mensaje no puede ir vacío.",
                    });
                }
            }
        });
    };

    //get Location
    const handlerLocation = (e) => {
        e.preventDefault();
        // console.log(e.target.id);
        let getLocations = e.target.id.split("|");
        setLocations({
            latitude: parseFloat(getLocations[0]),
            longitude: parseFloat(getLocations[1]),
        });
        setDataToMap({
            user_name: getLocations[2],
            vehicleNumber: getLocations[3],
            distance: parseFloat(getLocations[4]),
            dateString: getLocations[5],
        });
        //setLatLng(e.target.id);
    };

    //getConversationID
    const handlerCreateOrGetChat = (e) => {
        e.preventDefault();
        let recipentID = e.target.id;
        //console.log(recipentID);
        createOrGetConversation(
            currentUser.id,
            recipentID,
            currentUser.idCompany
        ).then((res) => {
            console.log(res);
            setChatStat(res);
            if (res !== "") {
                showPopupMessage(res);
            } else {
                //erro pop
            }
        });
    };

    const handlePageChange = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
    };

    const handlerBackArrowPage = () => {
        const arrowBack = document.getElementById("back");
        if (page === 1) {
            arrowBack.classList.add("disabled");
            setPage(1);
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: 1,
                })
            );
        }
        if (page > 1) {
            arrowBack.classList.remove("disabled");
            const backPage = page - 1;
            setPage(backPage);
            setFormValues({ ...formValues, pageActive: backPage });
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: backPage,
                })
            );
        }
    };

    const handlerNextArrowPage = () => {
        const netxArrow = document.getElementById("next");
        if (page === pageCount) {
            netxArrow.classList.add("disabled");
            //setPage(pageCount);
        }
        if (page < pageCount) {
            netxArrow.classList.remove("disabled");
            const nextPage = page + 1;
            setPage(nextPage);
            setFormValues({ ...formValues, pageActive: nextPage });
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: nextPage,
                })
            );
        }
    };

    /**
     * delete row photos from inicidecies
     *
     */
    const handletDeleteRowPhotos = async (e) => {
        e.preventDefault();
        console.info(e.target.id);
        let dataToSend = e.target.id.split(",");
        console.info(dataToSend);
        Swal.fire({
            title: "Aviso",
            text: "¿Estás seguro de eliminar la incidencia?",
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            //confirmButtonColor:"#7cb342",
            //cancelButtonColor:"#e53935",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Enviando información.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    html: "<b>Por favor espere...</b>",
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                // deleteRowAndphotos(dataToSend[0], dataToSend[1], dataToSend[2]).then(
                //     (res) => {
                //         console.log("response the function");
                //     }
                // );
            } else {
                Swal.close();
            }
        });
    };

    console.log("List INCIDENTES ", listRefunds);
    //console.log(pageCount);

    const renderPagination = () => {
        let listPageCount = [];
        for (let index = 0; index < pageCount; index++) {
            if (index == 0) {
                let num = 1;
                listPageCount.push(
                    <li className={`${page === num ? "active" : ""}`} key={index}>
                        <Link to="" onClick={handlePageChange} id={num}>
                            {num}
                        </Link>
                    </li>
                );
            } else {
                let num = index + 1;
                listPageCount.push(
                    <li className={`${page === num ? "active" : ""}`} key={index}>
                        <Link to="" onClick={handlePageChange} id={num}>
                            {num}
                        </Link>
                    </li>
                );
            }
        }

        return listPageCount;
    };

    const jsonPagination = JSON.parse(localStorage.getItem("savePageInc"));
    const getPagination = jsonPagination["pagination"];
    const initialValues = { pageActive: getPagination };

    const [formValues, setFormValues] = useState(initialValues);

    const handleGoBackPage = (e) => {
        e.preventDefault();
        let numPag = document.getElementById("pageActive").value;
        console.log(numPag);
        setPage(parseInt(numPag));
        localStorage.setItem(
            "savePageInc",
            JSON.stringify({
                pagination: parseInt(numPag),
            })
        );
    };
    const onChangePageInput = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const newPagination = () => {
        return (
            <div className="row">
                <div className="contentPagination">
                    <Link
                        className={`${page === 1 ? "disabled" : ""}`}
                        to=""
                        id="back"
                        onClick={handlerBackArrowPage}
                    >
                        <i className=" medium material-icons">chevron_left</i>
                    </Link>
                    {/* <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20"> */}
                    <form className="center" onSubmit={handleGoBackPage}>
                        <div className="white inputPage">
                            <input
                                name="pageActive"
                                type="number"
                                className=""
                                required
                                id="pageActive"
                                value={formValues.pageActive}
                                onChange={onChangePageInput}
                                min={1}
                                max={pageCount}
                                style={{ textAlign: "center" }}
                            />
                            {/* onChangeCapture={onChangePageInput} */}
                        </div>
                    </form>

                    {/* </div> */}

                    <label className="deText center">de</label>
                    <label className="deText center">{pageCount}</label>
                    <Link
                        className={`${page === pageCount ? "disabled" : ""}`}
                        to=""
                        id="next"
                        onClick={handlerNextArrowPage}
                    >
                        <i className=" medium material-icons">chevron_right</i>
                    </Link>
                </div>
            </div>
        );
    };

    //renderTable Information
    const listTableRefunds = () => {
        if (listRefunds.length > 0) {
            return (
                <div className="contentTable">
                    <table className="striped highlight  responsive-table centered tableFont">
                        <thead className="borderbotomLine">
                            <tr>
                                <th className="center">Fecha</th>
                                <th className="center">Operador</th>
                                <th className="center">Cliente</th>
                                <th className="center">Lugar</th>
                                <th className="center">Causa</th>
                                <th className="center">Núm.</th>
                                <th className="leftHead" >Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listRefunds.map((refund) => {
                                count++;
                                return (
                                    <tr
                                        className={refund.visto === 0 ? "newsEvidences" : ""}
                                        id={count % 2 === 0 ? "rowHighLigth" : ""}
                                        key={`${refund.dateString}-${count}`}
                                    >
                                        <td>{refund.dateString}</td>
                                        <td>{refund.userName}</td>

                                        <td>{refund.customer}</td>
                                        <td>{refund.place}</td>

                                        <td>
                                            {refund.causes}
                                            {/* {refund.observation !== ""
                                                ? refund.observation.substring(0, 16)
                                                : "NA"} */}
                                        </td>
                                        <td>{refund.numEvidences}</td>
                                        <td>
                                            <div className="optionsEvi">
                                                {/* {currentUser.typeUser === 3 ? (
                                                    <Link
                                                        to=""
                                                        id={refund.userUid}
                                                        onClick={handlerCreateOrGetChat}
                                                    >
                                                        <i
                                                            className="material-icons iconsList"
                                                            id={refund.userUid}
                                                            style={{ pointerEvents: "none" }}
                                                        >
                                                            question_answer
                                                        </i>
                                                    </Link>
                                                ) : (
                                                    <></>
                                                )} */}
                                                <Link
                                                    to={`/devoluciones/${refund.serviceDetail_ID}`}
                                                    id={refund.serviceDetail_ID}
                                                >
                                                    <i
                                                        className="material-icons iconsList"
                                                        id={refund.serviceDetail_ID}
                                                    >
                                                        collections
                                                    </i>
                                                </Link>
                                                <Link
                                                    to=""
                                                    data-target="modal1"
                                                    className=" modal-trigger"
                                                    id={`${refund.statusLat}|${refund.statusLon}|${refund.userName}|${refund.vehicleNumber}|${refund.distanceRemain}|${refund.dateString}`}
                                                    onClick={handlerLocation}
                                                >
                                                    <i
                                                        className="material-icons iconsList"
                                                        style={{ pointerEvents: "none" }}
                                                    >
                                                        pin_drop
                                                    </i>
                                                </Link>
                                                {/**new option delete images */}
                                                {/* {currentUser.typeUser === 3 ? (
                                                    <div
                                                        style={{
                                                            marginLeft: "0px",
                                                            cursor: "pointer",
                                                            textAlign: "left",
                                                        }}
                                                        onClick={handletDeleteRowPhotos}
                                                    >
                                                        <i
                                                            className="material-icons iconsInputs red-text"
                                                            id={`${currentUser.idCompany},${""},${refund.id
                                                                }`}
                                                        >
                                                            delete_forever
                                                        </i>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )} */}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {/* {renderPagination()} */}
                    {newPagination()}
                    {/* <div className='center'> */}

                    {/* <ul className='pagination'>
                    <li className={`${page===1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                        {newPagination()}
                    <li className={`${page===pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                </ul> */}
                    {/* </div> */}
                </div>
            );
        } else {
            //
        }
    };

    return (
        <>
            <div className="containerEvid">
                <div className="" style={{ background: "#5E5E68" }}>
                    <form
                        className="center"
                        style={{ padding: "10px 0px" }}
                        onSubmit={searchIncidence}
                    >
                        <div className="input-field inputSerach ">
                            <i
                                className="material-icons  prefix "
                                style={{ color: "#A3A3A9", left: "0px", top: "10px" }}
                            >
                                search
                            </i>
                            <input
                                id="search"
                                type="text"
                                placeholder="Buscar"
                                className="inputSerachBorderBottom"
                                value={serachText}
                                onChange={onChangeSerachTextInc}
                            />
                        </div>
                    </form>
                </div>

                <div className="row center">
                    <h4>DEVOLUCIONES</h4>
                </div>


                {/** FILTER DATES */}
                <div className="row">
                    <div className="col s12 m3 l3"></div>
                    <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="initDate" value={initDate} /></div> </div>
                    <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="endDate" value={endDate} placeholder={endDate === '' ? 'Fecha Final' : ''} /></div></div>
                    <div className="col s12 m3 l3"></div>
                </div>
                {/** FILTER DATES */}

                {loading === true ? (
                    <div className="row center">
                        <CircleGif />
                    </div>
                ) : (
                    listTableRefunds()
                )}
            </div>
            <MapPopup
                latitude={locations.latitude}
                longitude={locations.longitude}
                infoData={stateDataToMap}
            />
        </>
    );
};

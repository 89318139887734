/**
 * Home functions conunters.
 */


 import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { myFirestore} from '../config/ConfigFirebase';
 import firebase from '../config/global-variables';

 /**
  * GET TOTAL MESSAGES UNREADED
  */

 const getAllMsgUnread = async(currenUserId) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getMessageByConversationUnread/${currenUserId}`;
        const headers = {"Content-Type":"application/json"};
        const response = await fetch(url);
        
       const data = await response.json();
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;
      
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de mensajes nuevos, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return {};
    }
 }

 /**
  * GET NEW EVIDENCES 
  */

  const getAllEvidences = async(userCompany,uid) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountEvidences`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany, managerID:uid}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de evidencias nuevas, inténtelo más tarde, problema de conexión a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

  /**
  * GET NEW INCIDENCIAS 
  */

   const getAllIncidencias = async(userCompany,uid) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountIncidents`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany,managerID:uid}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de inicidencias nuevas, inténtelo más tarde, problema de conexión a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }


 /**
  * GET ALL USERS 
  */

  const getAllUsers = async(userCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountUsers`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia' + error,
            text:'No se pudo obtener el total de usuarios, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

 const getCompanyData = async(idCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCompanyName/${idCompany}`;
        const headers = {"Content-Type":"application/json"};
        const response = await fetch(url);
        
       const data = await response.json();
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;
      
        return data;
    } catch (error) {
        //console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'El nombre de la compañia no se pudo obtener, fallo la petición del servidor.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return {};
    }
 }

 //GET all travels 
 const getCountTravels = async(userCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/CantidadViajesCurso`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de viajes en curso, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

 //get el acces to a card solicitudes de pdfs
 const getAccessCompanyDocument =async(idCompany) =>{
        try {
            let accessDocInfo = false;
            const request = collection(myFirestore,'Companies');
            const resultadosDocs = await getDocs(request);

            resultadosDocs.forEach((doc)=>{
                if(doc.data()['idCompany'] === idCompany){
                    accessDocInfo = doc.data()['accessDoc']
                }
            });
            
            return accessDocInfo;

            
        } catch (error) {
            console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la propiedad de accessos a documentos, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'error',
            confirmButtonText:'Ok',
        })
        }
 }


  /**
  * GET TOTAL pdf s UNREADED
  */

   const getCountRequest = async(idCompany, uid) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountRequest`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: idCompany, managerID:uid}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de solicitudes, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

 /**
  * Licencias
  */
 const getLicenciesByCompany = async(idCompany) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountLicenses`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({idCompany:idCompany}),
            //mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
     
       const parseData = JSON.parse(data)
     
       const {Data} = parseData;
      // console.log('DATA =>',Data[0]);
       return Data[0];
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la info de licencias, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return {};
        //swal alert to return to home
    }

 }

 export {getAllMsgUnread,
    getAllEvidences,
    getAllIncidencias,
    getAllUsers,
    getCompanyData,
    getCountTravels,
    getAccessCompanyDocument,
    getCountRequest,getLicenciesByCompany}

import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../auth/authContext';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import { DialogValidation } from '../DialogValidation';
import { getCompanies, saveRegisterStatusByCompany } from '../../helpers/statusFunc';


export const StatusForm = () => {

    const { currentUser } = useContext(AuthContext);
    const [selectTypeStatus, selectTypeStatusState] = useState(0);
    const [logingCompanies, setLoadingCompanies] = useState(true);
    const [listCompanies, setListCompanies] = useState([]);
    const initialValues = { company: "DEFAULT", statusCaption: "", statusCaptionEs: "", statusCode: "", statusName: "", statusNameEs: "", statusOrder: 0, statusType: 0 };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        let isMounted = true;
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);

        getCompanies().then((result) => {
            setListCompanies(result)
            setLoadingCompanies(false)
        })


        return () => {
            isMounted = false;
        };

    }, [logingCompanies]);

    const handleChange = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const handleSelectTypeStatus = (e) => {
        console.log(e.target.value);
        selectTypeStatusState(parseInt(e.target.value));
    }

    const validate = (values) => {
        // e.preventDefault();
        //  console.log('Click enviar');
        let msgError = '';
        let flag;
        const typeStatus = document.getElementById("typeStatus");
        if (values.company === "" || values.company === "DEFAULT") {
            msgError += "La compañia es requerida.\n";
            flag = false;
        }
        if (values.statusCaption === "") {
            msgError += "El titulo en ingles es requerido.\n";
            flag = false;
        }
        if (values.statusCaptionEs === "") {
            msgError += "El titulo en español es requerido.\n";
            flag = false;
        }
        if (values.statusCode === "") {
            msgError += "El codigo del status es requerio.\n";
            flag = false;
        }
        if (values.statusName === "") {
            msgError += "El Nombre en ingles es requerido.\n";
            flag = false;
        }
        if (values.statusNameEs === "") {
            msgError += "El Nombre en español es requerido.\n";
            flag = false;
        }
        if (values.statusOrder === 0 && selectTypeStatus === 1) {
            msgError += "El Orden es requerido.\n";
            flag = false;
        }
        if (values.statusType === 0) {
            msgError += "El tipo es requerido.";
            flag = false;
        }
        if (typeStatus.value === "") {
            msgError += "El tipo es requerido.";
            flag = false;
        }

        if (msgError !== "") {
            msgError = "Todos los campos son requeridos."
            Swal.fire({
                title: 'Aviso',
                text: msgError,
                icon: 'warning',
            });

            flag = false;
        } else {
            flag = true;
        }

        return flag;

    }

    /* const handlerChangeSelector = (e) =>{
         e.preventDefault();
         console.log(e.target.value); // get the value = id firebase user
         selectCompanyState(e.target.value);
     }*/

    const handlerSaveRegister = async (e) => {
        e.preventDefault();
        const flag = validate(formValues);

        if (flag === true) {
            const data = {
                statusCaption: formValues.statusCaption.toUpperCase(),
                statusCaptionEs: formValues.statusCaptionEs.toUpperCase(),
                statusCode: formValues.statusCode.toUpperCase(),
                statusName: formValues.statusName.toUpperCase(),
                statusNameEs: formValues.statusNameEs.toUpperCase(),
                statusOrder: parseInt(formValues.statusOrder),
                statusType: parseInt(formValues.statusType)
            }

            const result = await saveRegisterStatusByCompany(formValues.company, data, selectTypeStatus);
            if (result === 1) {
                Swal.fire({
                    title: 'Exitoso',
                    text: 'Status registrado.',
                    confirmButtonText: 'Ok',
                    icon: 'success',
                });

                setFormValues(initialValues);
                document.getElementById("typeStatus").value = '0';
            } else {
                //
                Swal.fire({
                    title: 'Aviso',
                    text: 'No se pudo registrar el status.',
                    confirmButtonText: 'Ok',
                    icon: 'warning',
                });

                setFormValues(initialValues);
                document.getElementById("typeStatus").value = '0';
            }
        }
        //saveRegisterStatusByCompany
    }







    return (

        <>

            {currentUser.typeUser === 1 ? <> <div className='containerUser'>
                <div className='contentForm' style={{ paddingTop: '300px' }}>
                    <div className='col s12 m12 l12 center'>
                        <div className=' center'><h4>Registro de Status</h4></div>
                        <div className='grettingsDiv'>

                        </div>
                        <form className='col s12 m12 l12'>
                            <div className='row'>

                                <div className="input-field white lighten-5 inputRegisterUser spaceBottom20">
                                    <i className="tiny material-icons prefix iconPositionInput "></i>
                                    <select name="company"

                                        onChange={handleChange} required
                                        defaultValue={'DEFAULT'}
                                    >
                                        <option key='0' value='DEFAULT' disabled>Select company</option>

                                        {

                                            listCompanies.map((company) => {
                                                //console.log(user);
                                                return <option key={company.id} value={company.idCompany}>{company.name}</option>
                                            })

                                        }
                                    </select>

                                </div>


                                <div className="input-field white lighten-5 inputRegisterUser spaceBottom20">
                                    <i className="tiny material-icons prefix iconPositionInput "></i>
                                    <select name="active" defaultValue={'0'} onChange={handleSelectTypeStatus} required id='typeStatus'>
                                        <option key='0' value='0' disabled>Seleccione tipo de status</option>
                                        <option key='1' value='1'>Vehiculo</option>
                                        <option key='2' value='2'>Remolque</option>
                                    </select>
                                </div>

                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusCaption" placeholder="statusCaption" type="text" required id='statusCaption'
                                        value={formValues.statusCaption} onChange={handleChange} />
                                </div>

                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusCaptionEs" placeholder="statusCaptionEs" type="text" required id='statusCaptionEs'
                                        value={formValues.statusCaptionEs} onChange={handleChange} />
                                </div>


                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusCode" placeholder="statusCode" type="text" className="" required id='statusCode'
                                        value={formValues.statusCode} onChange={handleChange} />
                                </div>


                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusName" placeholder="statusName" type="text" className="" required id='repass'
                                        value={formValues.statusName} onChange={handleChange} />
                                </div>

                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusNameEs" placeholder="statusNameEs" type="text" className="" required id='repass'
                                        value={formValues.statusNameEs} onChange={handleChange} />
                                </div>

                                {selectTypeStatus === 0 || selectTypeStatus === 1 ?
                                    <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                        <input name="statusOrder" type="number" className="" required id='order'
                                            value={formValues.statusOrder} onChange={handleChange} min={0} max={100} />
                                        <label htmlFor="order" className="active">statusOrder</label>
                                    </div>
                                    : <></>}

                                <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20">

                                    <input name="statusType" type="number" className="" required id='type'
                                        value={formValues.statusType} onChange={handleChange} min={0} max={3} />
                                    <label htmlFor="type" className="active">statusType</label>
                                </div>


                            </div>
                            <div className='registerDiv'>
                                <button
                                    className='btn waves-effect waves-light btnFromRegisterCreate'
                                    onClick={handlerSaveRegister}
                                    type='button' name='Crear'>
                                    Crear
                                </button>
                                <Link to='/' className='btn btnFromLogin' id='btnFromLoginRU' style={{ background: '#5E5E68', width: '100px !important' }}>Salir</Link>
                            </div>
                        </form>

                    </div>

                </div>
            </div></> : <>
                <div><div className='containerUser'>
                    <h3 className='center'>No tiene acceso a este apartado.</h3>
                </div>
                </div></>}

        </>


    )
}

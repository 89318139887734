
import { myFirestore } from '../config/ConfigFirebase';
import { collection, query, where, getDocs, orderBy, doc, getDoc, onSnapshot, addDoc, updateDoc, arrayUnion, setDoc } from "firebase/firestore";

import firebase from '../config/global-variables';
import Swal from 'sweetalert2';
import { connectStorageEmulator } from 'firebase/storage';
//{ firebase.nameProyectFirebase, keyMessaging, firebaseNotificationSend }

//GET CONTACTS BY USER

const collectionUser = 'Users';
const collectionConversation = 'Conversations';

const getContacts = async (idCompany, uid, searchText, typeUser) => {
    let listUsers = [];

    try {
        if (searchText == "") {
            const response = query(collection(myFirestore, collectionUser), where('idCompany', '==', idCompany), orderBy('name'));
            const querySnapshot = await getDocs(response);

            //console.log(querySnapshot.size);
            querySnapshot.forEach((doc) => {
                let user = {
                    id: doc.id,
                    name: doc.data()['name'],
                    emial: doc.data()['email'],
                    lastSeen: doc.data()['lastSeen'],
                    serviceID: doc.data()['serviceID'],
                    status: doc.data()['status'],
                    token: doc.data()['token'],
                    image: doc.data()['image'],
                    userType: doc.data()['TypeUser'],
                    managerID: doc.data()['managerID'],
                    active: doc.data()['active'],
                }
                if (doc.id !== uid) {
                    let listManagerID = doc.data()['managerID'].split(',');
                    if (typeUser === 2) {
                        if (doc.data()['TypeUser'] === 3 && doc.data()['active'] === '1') {
                            listUsers.push(user);
                        }
                        if (doc.data()['TypeUser'] === 2 && doc.id !== uid && doc.data()['active'] === '1') {
                            listUsers.push(user);
                        }
                    }
                    if (typeUser === 3) {

                        if (doc.data()['TypeUser'] === 4 && doc.data()['active'] === '1') {

                            listManagerID.forEach(mid => {

                                if (mid === uid) {
                                    listUsers.push(user);
                                }

                            });

                        }
                        if (doc.data()['idCompany'] === idCompany && doc.data()['TypeUser'] === 2) {
                            listUsers.push(user);
                        }
                    }

                }

            });
        }

        if (searchText !== "") {

            const newQuery = query(collection(myFirestore, collectionUser), where('idCompany', '==', idCompany), orderBy('name'));
            //query(collection(myFirestore,collectionUser),where('idCompany','==',idCompany),where('name','==',searchText));
            const querySnapshotsSR = await getDocs(newQuery);

            querySnapshotsSR.forEach((newdoc) => {
                const nameUser = newdoc.data()['name'];
                if (nameUser.toUpperCase().includes(searchText.toUpperCase())) {
                    let user = {
                        id: newdoc.id,
                        name: newdoc.data()['name'],
                        emial: newdoc.data()['email'],
                        lastSeen: newdoc.data()['lastSeen'],
                        serviceID: newdoc.data()['serviceID'],
                        status: newdoc.data()['status'],
                        token: newdoc.data()['token'],
                        image: newdoc.data()['image'],
                        userType: newdoc.data()['TypeUser'],
                        managerID: newdoc.data()['managerID'],
                        active: newdoc.data()['active'],
                    }
                    if (newdoc.id !== uid) {

                        let listManID = newdoc.data()['managerID'].split(',');

                        if (typeUser === 2) {
                            if (newdoc.data()['TypeUser'] === 3 && newdoc.data()['active'] === '1') {
                                listUsers.push(user);
                            }
                            if (newdoc.data()['TypeUser'] === 2 && newdoc.id !== uid && newdoc.data()['active'] === '1') {
                                listUsers.push(user);
                            }
                        }
                        if (typeUser === 3) {
                            if (newdoc.data()['TypeUser'] === 4 && newdoc.data()['active'] === '1') {

                                listManID.forEach(mid => {

                                    if (mid === uid) {
                                        listUsers.push(user);
                                    }

                                });
                                //listUsers.push(user);
                            }
                            if (newdoc.data()['idCompany'] === idCompany && newdoc.data()['TypeUser'] === 2) {
                                listUsers.push(user);
                            }
                        }
                        //listUsers.push(user);
                    }
                }

            })
        }

        return listUsers;
    } catch (error) {
        Swal.fire({
            title: 'Problema de conexión.',
            text: 'No se puede obtener la lista de contactos.',
            icon: 'error',
        })
        return [];
    }

}


const getCurrentConversationInfo = async (uid, converID) => {

    try {
        //CODITION TO TAKE BOTH IDS RECIPENT AND CONVERSID
        let splitIDs = converID.split(',');
        let recipentID = '';
        let converationID = splitIDs[0];
        if (splitIDs.length > 1) {
            recipentID = splitIDs[1];
        }
        const dataVariables = `${uid},${converID}`;
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getConversationInfo/${uid},${converationID},${recipentID}`;
        const response = await fetch(url);
        const data = await response.json();
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        const objReturn = {
            id: Data[0].id,
            conversationID: Data[0].conversationID,
            name: Data[0].name,
            image: Data[0].image,
            status: Data[0].status
        }
        return objReturn;
    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Advertencia',
            text: 'No se pudo obtener la información del chat seleccionado, inténtelo mas tarde, problema de conexión a la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Ok',
        })
        return {};
    }
}

/**
 * GET new Chats counter by converation
 * uid ==> is the id of the user logged
 */
const getCountChatByConversation = async (uid) => {

    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getMessageByConversationUnreadByUser/${uid}`;
        const response = await fetch(url);
        const data = await response.json();
        const parseData = JSON.parse(data)
        const { Data } = parseData;

        return Data;
    } catch (error) {
        console.error(error);
        Swal.fire({
            title: 'Advertencia',
            text: 'No se pudo obtener la información del contador de chat, inténtelo mas tarde, problema de conexión a la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Ok',
        })
        return [];
    }
}



async function updateMessagesUnreaded(reciberID, conversationID) {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/conversationsLastMsgWEB2/${conversationID}`;
        //const header = ;
        const requestOptions = {
            method: 'PUT',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({ RecibirID: reciberID }),
        }

        const response = await fetch(url, requestOptions);
        const res = await response.json();
        console.log(res);
        return res;

    } catch (error) {
        console.error(error)

    }
}

/**
 * create or getConversation
 */
const createOrGetConversation = async (currentID, recipentID, idCompany) => {
    let BreakException = {};
    try {
        let chatRoom;
        let founded = false;
        let idConversation = '';
        let idConversationR = '';


        //find if we have a conversation with this person
        const queryDoc = query(collection(myFirestore, collectionUser, currentID, 'Conversations'));
        const colectionUserConver = await getDocs(queryDoc);
        //preguntar si recipetID tiene al currentid en las subcollecion.
        const queryRecipChats = query(collection(myFirestore, collectionUser, recipentID, 'Conversations'));
        const collecitonChatsRecipet = await getDocs(queryRecipChats);

        //tamaño de conversacion de currentUser
        if (colectionUserConver.size > 0) {
            //buscamos en la coleccion del currentID
            colectionUserConver.forEach((doc) => {
                //console.log(doc.data()['conversationID'])
                if (doc.id === recipentID) {

                    idConversation = doc.data()['conversationID'];
                    //founded = true;
                }///
            });

        } else {
            idConversation = '';
            //idConversationR = '';
            founded = false;
        }

        if (collecitonChatsRecipet.size > 0) {
            //buscamos en la coleccion del recipentID
            collecitonChatsRecipet.forEach((doc) => {
                //console.log(doc.data()['conversationID'])
                if (doc.id === currentID) {

                    idConversationR = doc.data()['conversationID'];
                    //founded = true;
                }///
            });
        } else {


            idConversationR = '';
            founded = false;



        }

        //comparar ids 

        //vamos a validar los idConversations si son iguales.
        if (idConversationR !== '' && idConversation !== '') {
            if ((idConversation === idConversationR)) {
                founded = true;
            }
            else if (idConversation !== idConversationR) {

                let idConversUpdated = await conversationIDsUpdate(currentID, recipentID, idConversation, idConversationR);
                founded = true;
                idConversation = idConversUpdated;
                idConversationR = idConversUpdated;
            }
        }

        else if (idConversation === '' && idConversationR !== '') {
            idConversation = idConversationR;
            founded = true;
        }
        else if (idConversation !== '' && idConversationR === '') {
            idConversationR = idConversation;
            founded = true;
        }


        if (founded === false) {
            //create conversation && idConversation === ''
            const getReciber = doc(myFirestore, "Users", recipentID);
            const infoReciber = await getDoc(getReciber);
            //tamaño del recipent id chats


            //  else {

            //     // si no es operador es == 2 Admin entoces se va a lo de abajo de codigo descomentado.
            //     idConversationR = '';
            //     founded = false;
            // }
            const newConversation = await addDoc(collection(myFirestore, collectionConversation), {
                "members": [currentID, recipentID],
                "ownerID": currentID,
                "idCompany": idCompany,
                "messages": [],
            });


            //revisar si el usuario es operador si es operador
            //vamos a verificar que sea == 4 y que este activo
            if (infoReciber.data()["TypeUser"] === 4 &&
                infoReciber.data()["active"] === "1") {
                // const senDer = doc(myFirestore, "Users", currentID);
                // const senderDOc = await getDoc(senDer);
                //vamos a generar la sub colection conversation en operador
                // collection(myFirestore, `Users/${infoReciber.id}/Conversations`);
                // await setDoc(doc(myFirestore, `Users/${infoReciber.id}/Conversations`, senderDOc.id), {
                //     conversationID: idConversation,
                //     image: senderDOc.data()["image"],
                //     name: senderDOc.data()["name"],
                //     unseenCount: 0
                // })
                //para poder mostrar dicha nueva conversación. // se setea
                // el idConversationR a idConversation y founded True
                let valResp = await createSubCollectionConver(currentID, recipentID, newConversation.id);
                // idConversationR = valResp;
                // idConversation = valResp;
                // founded = true;
            }

            return newConversation.id;

        } else {


            return idConversation;
        }

    } catch (error) {
        console.error('ERRO DE CHAT AL CREAR O OBTER CHAT DESDE CONTACTOS=>', error)
        Swal.fire({
            title: 'Advertencia',
            text: 'No se pudo obtener la información crear o obtener la conversación, inténtelo mas tarde, problema de conexión a la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Ok',
        })
        return '';
    }

}

/**GET LOCATION BY CONVERSATION */
const getLocationByChat = async (recipentID) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getPositionByChat/${recipentID}`;
        const response = await fetch(url);
        const data = await response.json();
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        //console.log('DATA=>',Data);
        return Data;
    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Advertencia',
            text: 'No se pudo obtener la información de ubicación del chat, inténtelo mas tarde, problema de conexión a la base de datos.',
            icon: 'warning',
            confirmButtonText: 'Ok',
        })
        return [];
    }
}

/**SEND group Message */
const sendGroupMessageByConversations = async (listConversations, currentIDUser, message, type) => {
    try {
        const type = 'text';
        const senderID = currentIDUser;
        const date = Date.now();
        const transformDate = new Date(date);

        const request = doc(myFirestore, 'Users', currentIDUser);
        const response = await getDoc(request);
        let nameSender = '';
        if (response.exists()) {
            nameSender = response.data()['name'];
        }

        if (message !== "") {
            for (let chat = 0; chat < listConversations.length; chat++) {
                const newMessage = doc(myFirestore, 'Conversations/', listConversations[chat]);
                const union = arrayUnion(...[{
                    "message": message,
                    "senderID": senderID,
                    "timestamp": transformDate,
                    "type": type,
                    "status": 0,
                }]);
                updateDoc(newMessage, { messages: union });

                //code to send a chat and arrayUnion

                //notificacion masima
                const conversation = await getDoc(newMessage);
                if (conversation.exists()) {
                    let reciberID = '';
                    const member = conversation.data()['members'];
                    if (member[0] !== senderID) {
                        reciberID = member[0];
                    }
                    if (member[1] !== senderID) {
                        reciberID = member[1];
                    }



                    //codigo para validar mensaje enviado por grupo
                    const getQuery = doc(myFirestore, 'Users', reciberID, 'Conversations', senderID);
                    const infoRecentConver = await getDoc(getQuery);
                    const getReciber = doc(myFirestore, 'Users', reciberID);
                    const infoReciber = await getDoc(getReciber);
                    //query(collection(myFirestore,'Users',reciber,'Conversations'),where('conversationID','==',chatID));
                    console.info('Reciber =>', infoReciber);
                    if (infoRecentConver.exists()) {
                        if (type === 2 && infoReciber.data()['TypeUser'] === 3) {
                            if (infoRecentConver.data()['isCurrentChat'] === true) {
                                updateDoc(getQuery, {
                                    "newMsg": 0,

                                });
                            } else {
                                updateDoc(getQuery, {
                                    "newMsg": 1,
                                    "isCurrentChat": false,
                                });
                            }
                        }
                        if (type === 3 && infoReciber.data()['TypeUser'] === 2) {
                            if (infoRecentConver.data()['isCurrentChat2'] === true) {
                                updateDoc(getQuery, {
                                    "newMsg": 0
                                });
                            } else {
                                updateDoc(getQuery, {
                                    "newMsg": 1,
                                    "isCurrentChat2": false,
                                });
                            }
                        }
                    }


                    await sendNotification(nameSender, reciberID, message);

                }


            }
        } else {
            Swal.fire({
                title: 'Error al enviar!',
                text: 'No se puedo enviar el mensaje intento lo mas tarde o vaya directamente a chat y envielo por la sala de chat.',
                icon: 'error',
            })
        }
    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Error al enviar!',
            text: 'No se puedo enviar el mensaje intento lo mas tarde o vaya directamente a chat y envielo por la sala de chat.',
            icon: 'error',
        })
    }
}


const sendNotification = async (senderName, reciberID, contentMsg) => {

    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/sendNotificationWEB`;

        const infoReciber = doc(myFirestore, 'Users', reciberID);
        const respReciber = await getDoc(infoReciber);
        let tokenRe = '';
        if (respReciber.exists()) {
            if (respReciber.data()['TypeUser'] === 2 || respReciber.data()['TypeUser'] === 3) {
                tokenRe = respReciber.data()['tokenWeb'];
            } else {
                tokenRe = respReciber.data()['token'];
            }

        }

        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({ senderName: senderName, tokenReciber: tokenRe, contentMsg: contentMsg }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();

        console.log('NOTIFICACION RESULT => ', data);


    } catch (error) {
        console.error(error)
    }

}

//**GET CONVERSATIONS BY FIREBASE DIREC */
const getRecentConversationFB = async (uid, searchTex) => {

    let listConversations = [];
    try {
        if (searchTex === "") {
            listConversations = [];
            /*const myquery = query(collection(myFirestore,collectionUser,uid,'Conversations') , orderBy("timestamp", "desc"));
           // const subCollectionConvers = await getDocs(myquery);
             onSnapshot(myquery, (querySnapshot)=>{
               //console.logg('dasfd');
               
                querySnapshot.forEach((doc)=>{
                    let conversation={
                        id:doc.id,
                        conversationID:doc.data()['conversationID'],
                        image: doc.data()['image'],
                        lastMessage:doc.data()['lastMessage'],
                        name: doc.data()['name'],
                        type:doc.data()['type'],
                        timestamp:doc.data()['timestamp']
                      
                     }
    
                     listConversations.push(conversation);
                });
                console.log("LISTCHAT==ONSnapshot==>",listConversations);
               
            });*/

            /*subCollectionConvers.forEach((doc)=>{
            
                //listConversations.push(doc)
                let conversation={
                    id:doc.id,
                    conversationID:doc.data()['conversationID'],
                    image: doc.data()['image'],
                    lastMessage:doc.data()['lastMessage'],
                    name: doc.data()['name'],
                    type:doc.data()['type'],
                    timestamp:doc.data()['timestamp']
                  
                 }

                 listConversations.push(conversation);

               

            });*/

        }


        if (searchTex !== '' && searchTex !== null) {
            listConversations = [];
            const myquerySearc = query(collection(myFirestore, collectionUser, uid, 'Conversations'), orderBy('name'));
            const subCollectionConversSerac = await getDocs(myquerySearc);

            subCollectionConversSerac.forEach((newdoc) => {
                const nameUser = newdoc.data()['name'];
                if (nameUser.toUpperCase().includes(searchTex.toUpperCase())) {
                    let converSerc = {
                        id: newdoc.id,
                        conversationID: newdoc.data()['conversationID'],
                        image: newdoc.data()['image'],
                        lastMessage: newdoc.data()['lastMessage'],
                        name: newdoc.data()['name'],
                        type: newdoc.data()['type'],
                        timestamp: newdoc.data()['timestamp']
                    }
                    listConversations.push(converSerc);
                }
            })

        }

        return listConversations;

    } catch (error) {
        console.error(error);
        Swal.fire({
            title: 'Error al servidor',
            text: 'No se pudo conectar o obtener las conversaciónes, por favor intente mas tarde.',
            icon: 'error',
        })
        return listConversations;
    }

}


const conversationIDsUpdate = async (uidSender, uiReciper, conversationIDSender, conversationIDReciper) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/UpdateConversationsById`;
        //const header = ;
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                CurrendUser: uidSender,
                ReciberID: uiReciper,
                idConversationSender: conversationIDSender,
                idConversationReciper: conversationIDReciper
            }),
        }

        const response = await fetch(url, requestOptions);

        if (response.status == 200) {
            const res = await response.json();
            // console.log(res);
            // if (res)
            return res;
        }

    } catch (error) {
        Swal.fire({
            title: 'Error al servidor',
            text: 'No se pudo conectar para verificar las conversaciónes, por favor intente mas tarde.',
            icon: 'error',
        })
        return conversationIDSender;
    }
}


const createSubCollectionConver = async (uidSender, uiReciper, conversationIDSender) => {
    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/CreateSubCollectionConver`;
        //const header = ;
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                moniID: uidSender,
                operatorID: uiReciper,
                idConversation: conversationIDSender,

            }),
        }

        const response = await fetch(url, requestOptions);

        if (response.status == 200) {
            const res = await response.json();
            // console.log(res);
            // if (res)
            return res;
        }

    } catch (error) {
        Swal.fire({
            title: 'Error al servidor',
            text: 'No se pudo generar la converacion , por favor intente mas tarde.',
            icon: 'error',
        })
        return conversationIDSender;
    }
}



export {

    getContacts,
    //getConversations, 
    getRecentConversationFB,
    getCurrentConversationInfo,
    getCountChatByConversation,
    updateMessagesUnreaded,
    createOrGetConversation,
    getLocationByChat,
    sendGroupMessageByConversations,
    sendNotification
}

import { myFirestore, myFirebase, auth } from "../config/ConfigFirebase"
import { sendPasswordResetEmail, updatePassword } from "firebase/auth"
import Swal from "sweetalert2";



const forgetPass = async (email) => {
    try {

        auth.languageCode = 'it';
        await sendPasswordResetEmail(auth, email).then(() => {
            Swal.fire({
                title: 'Exitoso',
                text: 'Verifique su correo electrónico para recuperar su contraseña.',
                icon: 'success'
            })
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            Swal.fire({
                title: 'Upss!',
                text: `La solicitud no pudo ser entregada por código error.\nFallo al conectar con el servidor.`,
                icon: 'error'
            })
            // ..
        });

    } catch (error) {
        Swal.fire({
            title: 'Upss!',
            text: `La solicitud no pudo ser entregada por.\nFallo al conectar con el servidor.`,
            icon: 'error'
        })
    }
}

const editPassword = async (newPassword) => {

    try {

        const user = auth.currentUser;
        //console.log('CurrentUser => ',user);
        updatePassword(user, newPassword).then(() => {
            Swal.fire({
                title: 'Exitoso',
                text: 'Cambio existoso.\nContraseña actualizada correctamente.',
                icon: 'success'
            })



        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            Swal.fire({
                title: 'Upss!',
                text: `La solicitud no pudo ser entregada código error: ${errorCode}.\nFallo en el servidor de firebase.`,
                icon: 'error'
            })
            // ..
        });

    } catch (error) {
        Swal.fire({
            title: 'Upss!',
            text: `La solicitud no pudo ser entregada por.\nFallo en el servidor de firebase.`,
            icon: 'error'
        })
    }

}

export {
    forgetPass,
    editPassword
};


import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
//import { userCreditial } from '../../config/ConfigFirebase';
import { sinIn } from '../../helpers/loginFunc';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/type';
import Swal from 'sweetalert2';
import { editPassword, forgetPass } from '../../helpers/passwordFunc';


export const ChangePassword = () => {

    //const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);


    //changePassword
    const headlerLogin = async()=>{
        const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
       if((password === null || password === "") && (password2 === null || password2 === "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
            
             

            Swal.fire({
              title:'Aviso',
              text:'Los campos no pueden ir vacios.',
              confirmButtonText:'Ok',
              icon:'warning',
            })
            return false;
        }
     
       

        if( (password !== "" && password2 === "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
           
                Swal.fire({
                    title:'Aviso',
                    text:'La confirmación de la contraseña no puede ir vacía.',
                    confirmButtonText:'Ok',
                    icon:'warning',
                  })
                  return false;
            
           
        }
        
        if( (password === "" && password2 !== "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
            
                Swal.fire({
                    title:'Aviso',
                    text:'El campo de nueva contraseña no puede ir vacío.',
                    confirmButtonText:'Ok',
                    icon:'warning',
                  })
                  return false;
            
           
        }


        if( (password !== password2)){
            //console.log("El correo /o contraseña no pueden ser vacios.");
          
                Swal.fire({
                    title:'Aviso',
                    text:'Las contraseñas no coinciden.',
                    confirmButtonText:'Ok',
                    icon:'warning',
                  })
                  return false;
            
           
        }
       

       await editPassword(password);
       const pass = document.getElementById('pass');
       const pass2 = document.getElementById('pass2');
       pass.value = '';
       pass2.value = '';
       setPassword('');
       setPassword2('');
      

 
      
    }

    return (
        <div className='body-home row'>
        <div className=' contentForm'>
            
            <div className='col s12 m12 l12 center'>

               { /*<div className='logoAppDiv'>
                    
                </div>*/ }

                <div className='grettingsDiv' style={{paddingTop:'20%'}}>
                    <span className='black-text'>Contraseña</span>
                    
                    <span className='black-text'>Cambiar tu contraseña.</span>
                </div>
              
                        <form className='col s12 m12 l12'>
                            <div className='row'>
                              
                                <div className="input-field  formInputPass">
                                    <i className="tiny material-icons prefix iconPositionInput" >lock</i>
                                    <input placeholder="Nueva Contraseña" type="password" className="sizeInput" value={password} onChange={(e)=>setPassword(e.target.value)} id='pass'/>
                        
                                </div>
                                <div className="input-field  formInputPass">
                                    <i className="tiny material-icons prefix iconPositionInput" >lock</i>
                                    <input placeholder="Confirmar contraseña" type="password" className="sizeInput" value={password2} onChange={(e)=>setPassword2(e.target.value)} id='pass2'/>
                        
                                </div>

                            </div>
                           
                            <div className='accessDiv'>
                                <button className='btn waves-effect waves-light btnFromLogin' type='button' name='login' onClick={headlerLogin}>Cambiar Contraseña</button>
                              
                            </div>
                        </form>
                      
                        

                       
               
            </div>
            
         
            
        </div>
        </div>
     
    )
}

/**
 *  <div className='registerDiv'>
                        <span className='spanLRH' >¿Aún no tienes una cuenta?</span> <a className='links'  href='#'>Crear cuenta</a>
                        </div>
 */

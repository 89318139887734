import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import M from "materialize-css";
import Swal from "sweetalert2";
import { registerVideo } from "../../helpers/videosFunc";

export const VideoForm = () => {
    const { currentUser } = useContext(AuthContext);
    // const [selectTypeStatus,selectTypeStatusState] = useState(0);
    //const [logingCompanies,setLoadingCompanies] = useState(true);
    // const [listCompanies,setListCompanies] = useState([]);
    const initialValues = {
        accesDoc: "DEFAULT",
        // idCompany: "",
        // keyAccess: "",
        name: "",
        routing: "",
        description: "",
        // storageUrl: "",
        // tokenAccess: "",
        // tokenAccessERP: "",
        // phone: "",
        // address: "",
        // status: 0,
        // userApp: 0,
        // userWeb: 0,
    };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        let isMounted = true;
        const select = document.querySelectorAll("select");
        M.FormSelect.init(select);

        return () => {
            isMounted = false;
        };
    }, []);

    const handleChange = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSelectTypeStatus = (e) => {
        console.log(e.target.value);
        // selectTypeStatusState(parseInt( e.target.value));
    };

    const validate = (values) => {
        // e.preventDefault();
        //  console.log('Click enviar');
        let msgError = "";
        let newErrr = "";
        let flag;
        console.log(formValues);
        const regExpUrlIps =
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
        const regEXpPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
        // const typeStatus = document.getElementById("typeStatus");

        if (values.name === "") {
            msgError += "El nombre del video es requerio.\n";
            flag = false;
        }
        if (values.routing === "") {
            msgError += "El url del video es requerido.\n";
            flag = false;
        }
        if (values.description === "") {
            msgError += "La descripción es requerido.\n";
            flag = false;
        }


        if (msgError !== "" || newErrr !== "") {
            msgError = "Todos los campos son requeridos.";

            Swal.fire({
                title: "Aviso",
                text: newErrr !== "" ? newErrr : msgError,
                icon: "warning",
            });

            flag = false;
        } else {
            flag = true;
        }

        return flag;
    };

    /* const handlerChangeSelector = (e) =>{
          e.preventDefault();
          console.log(e.target.value); // get the value = id firebase user
          selectCompanyState(e.target.value);
      }*/

    const handlerSaveRegister = async (e) => {
        e.preventDefault();
        const flag = validate(formValues);

        if (flag === true) {
            const data = {

                title: formValues.name.trim(),
                url: formValues.routing.trim(),
                description: formValues.description.trim(),

            };

            await registerVideo(data);

            setFormValues(initialValues);
        }
        //saveRegisterStatusByCompany
    };

    return (
        <>
            {currentUser.typeUser === 1 ? (
                <>
                    {" "}
                    <div className="containerUser" style={{ position: "absolute" }}>
                        <div
                            className="contentFormCompany"
                            style={
                                {
                                    /*paddingTop:'300px'*/
                                }
                            }
                        >
                            <div className="col s12 m12 l12 center">
                                <div className=" center">
                                    <h4>Registro de Videos</h4>
                                </div>
                                <br></br>
                                <form className="col s12 m12 l12 center">


                                    <div className="row">

                                        <div className="col s12 m3 l3"></div>
                                        <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                                            <input
                                                name="name"
                                                placeholder="name"
                                                type="text"
                                                required
                                                id="statusCaption"
                                                value={formValues.name}
                                                onChange={handleChange}
                                                maxLength={100}
                                            />
                                            <label htmlFor="name" className="active">
                                                Name
                                            </label>
                                        </div>
                                        <div className="col s12 m3 l3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col s12 m3 l3"></div>
                                        <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                                            <input
                                                name="routing"
                                                placeholder="url"
                                                type="text"
                                                required
                                                id="statusCaption"
                                                value={formValues.routing}
                                                onChange={handleChange}
                                                maxLength={200}
                                            />

                                            <label htmlFor="routing" className="active">
                                                Url
                                            </label>
                                        </div>
                                        <div className="col s12 m3 l3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col s12 m3 l3"></div>
                                        <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                                            <input
                                                name="description"
                                                placeholder="description"
                                                type="text"
                                                required
                                                id="statusCaption"
                                                value={formValues.description}
                                                onChange={handleChange}
                                                maxLength={255}
                                            />

                                            <label htmlFor="shortName" className="active">
                                                Description
                                            </label>
                                        </div>
                                        <div className="col s12 m3 l3"></div>






                                    </div>

                                    <div className="registerDiv">
                                        <button
                                            className="btn waves-effect waves-light btnFromRegisterCreate"
                                            onClick={handlerSaveRegister}
                                            type="button"
                                            name="Crear"
                                        >
                                            Crear
                                        </button>
                                        <Link
                                            to="/"
                                            className="btn btnFromLogin"
                                            id="btnFromLoginRU"
                                            style={{ background: "#5E5E68" }}
                                        >
                                            Salir
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className="containerUser">
                            <h3 className="center">No tiene acceso a este apartado.</h3>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};


import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
//import { objEvidences } from './exampleEvidenceObj';
import Swal from "sweetalert2";
import { MapPopup } from "../MapPopup";
import M from "materialize-css";
import { AuthContext } from "../../auth/authContext";


import { CircleGif } from "../CircleGif";
//import { deleteRowAndphotos } from "../../helpers/refundsFunc"; // CAMBIAR


import { generatePDF, getOperators } from "../../helpers/bitacorFunc";

export const Bitacoras = () => {
    const itemsPerPAge = 8;
    let count = 0;

    //Global info user dispatch
    const { currentUser } = useContext(AuthContext);
    const [listOperators, setListOperator] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    const [locations, setLocations] = useState({
        latitude: undefined,
        longitude: undefined,
    });
    const [savePage, setSavePage] = useState({
        pagination: 1,
    });

    /**
     * NEW STATES
     */
    const [selectAll, setSelectAll] = useState(false);
    const checkbox = useRef();
    const [selectCheckboxlist, setCheckboxlist] = useState([]);


    //const getListIncidence = objEvidences; // ciclica onsanpshot
    const { pagination } = savePage;
    let optionsDate = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric"
    };

    //variables to DatesPICKERS
    function subtractDays(date, days) {
        date.setDate(date.getDate() - days);

        return date.toLocaleString('es-MX', optionsDate).split(',')[1];
    }
    const backDays = subtractDays(new Date(Date.now()), 15)
    const defaultDate = new Date(Date.now()).toLocaleString('es-MX', optionsDate).split(',')[1];

    let currenDate = defaultDate;
    const [initDate, setInitDate] = useState(backDays);
    const [endDate, setEndDate] = useState(currenDate);

    useEffect(() => {
        let isMounted = true;
        let modalM = document.querySelectorAll(".modal");
        M.Modal.init(modalM);
        let pickerDate = document.getElementById("initDate");
        let endDatePick = document.getElementById("endDate");
        //START DATE
        M.Datepicker.init(pickerDate, {
            format: 'dd/mm/yyyy',
            autoClose: true,
            selectMonths: true,
            // onClose: handleDate(),
            i18n: {
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
                weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
                cancel: 'Cancelar',
                clear: 'Limpar',
                done: 'Ok',
                today: 'Hoy',

                previousMonth: '<',
                nextMonth: '>',
            },
            onSelect: function (date) {
                //console.info(date.getDate());
                let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];
                document.getElementById("initDate").value = date.toLocaleString().split(',')[0];
                setInitDate(date.toLocaleString().split(',')[0]);
            },
            onOpen: function () {
                let instance = M.Datepicker.getInstance(document.getElementById("initDate"));
                let splitInitDate = document.getElementById("initDate").value.split('/');
                instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
            }
        });
        //END DATE
        M.Datepicker.init(endDatePick, {
            format: 'dd/mm/yyyy',
            autoClose: true,
            selectMonths: true,
            // onClose: handleDate(),
            i18n: {
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
                weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
                weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
                cancel: 'Cancelar',
                clear: 'Limpar',
                done: 'Ok',
                today: 'Hoy',

                previousMonth: '<',
                nextMonth: '>',
            },
            onSelect: function (date) {

                let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];
                console.error(finalDate);
                document.getElementById("endDate").value = date.toLocaleString().split(',')[0];
                setEndDate(date.toLocaleString().split(',')[0]);
            },
            onOpen: function () {
                let instance = M.Datepicker.getInstance(document.getElementById("endDate"));
                let splitInitDate = document.getElementById("endDate").value.split('/');
                instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
            }


        });
        //reset pagination evidences to 1
        localStorage.setItem(
            "savePage",
            JSON.stringify({
                pagination: 1,
            })
        );
        let pagination;
        //save the pagination

        if (!localStorage.getItem("savePageInc")) {
            const remeberPage = localStorage.getItem("savePageInc");
            if (remeberPage !== null) {
                const objRemebermeData = JSON.parse(remeberPage);
                console.log("Pagination => ", objRemebermeData["pagination"]);
                setPage(objRemebermeData["pagination"]);
            }
            setSavePage({
                pagination: page,
            });
            pagination = {
                pagination: page,
            };
            localStorage.setItem("savePageInc", JSON.stringify(pagination));
        } else {
            const remeberPage = localStorage.getItem("savePageInc");
            if (remeberPage !== null) {
                const objRemebermeData = JSON.parse(remeberPage);
                console.log("Pagination => ", objRemebermeData["pagination"]);
                setPage(objRemebermeData["pagination"]);
            }
            localStorage.removeItem("savePageInc");
            setSavePage({
                pagination: page,
            });
            pagination = {
                pagination: page,
            };
            localStorage.setItem("savePageInc", JSON.stringify(pagination));
        }
        // save and get pagination END

        return () => {
            isMounted = false;
        };
    }, []);

    // //list of Incidences
    const [listOperatorsState, setListOperators] = useState({
        listUsers: [],
        loadingCon: true,
    });

    useEffect(() => {
        let isMounted = true;

        // if (document.getElementById("endDate").value !== '') {
        //     let startDateF = document.getElementById("initDate").value;
        //     let endDateF = document.getElementById("endDate").value;
        //     if (Date.parse(endDateF) <= Date.parse(startDateF)) {
        //         //show dialog
        //         Swal.fire({
        //             icon: "warning",
        //             title: "Aviso",
        //             text: "La Fecha final debe ser mayor a la fecha inicial.",
        //         });
        //         let endDateF = document.getElementById("endDate").value = "";
        //         setEndDate(endDateF);
        //         getRefunds(
        //             serachText,
        //             currentUser.idCompany,
        //             currentUser.id,
        //             currentUser.typeUser,
        //             document.getElementById("initDate").value,
        //             document.getElementById("endDate").value
        //         ).then((result) => {
        //             //console.log(result);
        //             setListOperators({
        //                 listOperatorsData: result,
        //                 loading: false,
        //             });
        //         });
        //     } else {
        //         getRefunds(
        //             serachText,
        //             currentUser.idCompany,
        //             currentUser.id,
        //             currentUser.typeUser,
        //             document.getElementById("initDate").value,
        //             document.getElementById("endDate").value
        //         ).then((result) => {
        //             //console.log(result);
        //             setListOperators({
        //                 listOperatorsData: result,
        //                 loading: false,
        //             });
        //         });
        //     }
        // } else {
        //     getRefunds(
        //         serachText,
        //         currentUser.idCompany,
        //         currentUser.id,
        //         currentUser.typeUser,
        //         document.getElementById("initDate").value,
        //         document.getElementById("endDate").value
        //     ).then((result) => {
        //         //console.log(result);
        //         setListOperators({
        //             listOperatorsData: result,
        //             loading: false,
        //         });
        //     });
        // }

        getOperators(currentUser.idCompany, currentUser.id, serachText, currentUser.typeUser).then((users) => {
            let customeCheckOpe = [];
            for (let index = 0; index < users.length; index++) {
                const element = users[index];
                customeCheckOpe.push({ id: element.id, checked: false, name: element.name });
            }
            console.log(customeCheckOpe);
            setListOperators({
                listUsers: customeCheckOpe,
                loadingCon: false,
            });

            ///setCheckboxlist(customeCheckOpe);
        });



        return () => {
            isMounted = false;
        };
    }, []);//page

    const { listUsers, loading } = listOperatorsState;
    const getListIncidence = listUsers;

    const onChangeSerachTextInc = (e) => {
        setSerachText(e.target.value);
    };

    /**
     * SELECT ALL CHECKBOX
     */
    const handleSelectAll = (e) => {


        const getAllchecks = document.querySelectorAll('.ck1');
        // if (!e.target.checked) {

        //     for (let i = 0; i < getAllchecks.length; i++) {
        //         getAllchecks[i].checked = false;

        //     }
        //     document.getElementById('allSelected').checked = false;
        // } else {

        //     for (let i = 0; i < getAllchecks.length; i++) {
        //         getAllchecks[i].checked = true;

        //     }
        //     document.getElementById('allSelected').checked = true;

        // }
        if (!e.target.checked) {

            for (let i = 0; i < listOperatorsState.listUsers.length; i++) {
                listOperatorsState.listUsers[i].checked = false;

            }
            document.getElementById('allSelected').checked = false;
            setSelectAll(e.target.checked);
        } else {

            for (let i = 0; i < listOperatorsState.listUsers.length; i++) {
                listOperatorsState.listUsers[i].checked = true;

            }
            document.getElementById('allSelected').checked = true;
            setSelectAll(e.target.checked);
        }

        console.log(listOperatorsState.listUsers);

    }

    /**
 * SELECT ALL CHECKBOX
 */
    const handleSelectByOne = (e) => {
        // console.log(e.target.checked);
        // const id = e.target.id;
        if (selectAll === true) {
            Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "Has seleccionado todos en la opción, debes de desmarcar todos en la opción, para poder seleccionar los requeridos, individualmente.",
            });
            return false;
        } else {
            if (!e.target.checked) {
                listOperatorsState.listUsers.forEach((element, index) => {
                    if (element.id === e.target.id) {
                        listOperatorsState.listUsers[index].checked = false;
                    }
                });
            } else {
                listOperatorsState.listUsers.forEach((element, index) => {
                    if (element.id === e.target.id) {
                        listOperatorsState.listUsers[index].checked = true;
                    }
                });
            }
        }
        // if (e.target.checked === true && selectAll === true) {
        //     // e.target.checked = false;
        //     // checkbox.current.checked = false;
        //     // console.log(e.target.checked);
        //     for (let index = 0; index < listOperators.length; index++) {
        //         //const element = listOperators[index];
        //         if (e.target.id === listOperators[index].id) {
        //             listOperators[index].checked = false;
        //             e.target.checked = false;
        //             break;
        //         }
        //     }
        // } if (e.target.checked === false && selectAll === true) {
        //     // e.target.checked = true;
        //     // checkbox.current.checked = true;
        //     // console.log(e.target.checked);
        //     for (let index = 0; index < listOperators.length; index++) {
        //         //const element = listOperators[index];
        //         if (e.target.id === listOperators[index].id) {
        //             listOperators[index].checked = true;
        //             e.target.checked = true;
        //             break;
        //         }
        //     }
        // }

        // if (e.target.checked === false && selectAll === false) {
        //     // e.target.checked = true;
        //     // checkbox.current.checked = true;
        //     // console.log(e.target.checked);
        //     for (let index = 0; index < listOperators.length; index++) {
        //         //const element = listOperators[index];
        //         if (e.target.id === listOperators[index].id) {
        //             listOperators[index].checked = false;
        //             e.target.checked = false;
        //         }
        //     }
        // }
        // if (e.target.checked === true && selectAll === false) {
        //     // e.target.checked = false;
        //     // checkbox.current.checked = false;
        //     // console.log(e.target.checked);
        //     // for (let index = 0; index < listOperators.length; index++) {
        //     //     //const element = listOperators[index];
        //     //     if (e.target.id === listOperators[index].id) {
        //     //         listOperators[index].checked = true;
        //     //         e.target.checked = true;
        //     //     }
        //     // }
        //     listOperators.forEach((element, index) => {
        //         if (element.id === e.target.id) {
        //             listOperators[index].checked = true;
        //         }
        //     });

        // }
        console.log(listOperatorsState.listUsers);


    }
    /**
     * GENERATE PDF HANDLER FUNCION
     */
    const handlerGeneratePDF = async (e) => {
        e.preventDefault();
        console.log(e.target);
        let end = new Date(document.getElementById("endDate").value); //Date.parse(document.getElementById("endDate").value);
        let init = new Date(document.getElementById("initDate").value) //Date.parse(document.getElementById("initDate").value);
        let newListOperatorsID = [];
        if (Date.parse(end) <= Date.parse(init)) {

            //show dialog
            Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "La Fecha final debe ser mayor a la fecha inicial.",
            });
            document.getElementById("endDate").value = "";
        } else {
            const getAllchecks = document.querySelectorAll('.ck1');
            for (let i = 0; i < listOperatorsState.listUsers.length; i++) {
                if (listOperatorsState.listUsers[i].checked === true) {
                    newListOperatorsID.push(listOperatorsState.listUsers[i].id);
                }

            }
            console.log("list ids to send => ", newListOperatorsID)
            if (newListOperatorsID.length == 0) {
                Swal.fire({
                    icon: "warning",
                    title: "Aviso",
                    text: "Al menos debes seleccionar un operador.",
                });
                return false;
            }
            await generatePDF(document.getElementById("initDate").value, document.getElementById("endDate").value, newListOperatorsID, currentUser.idCompany);
        }
    }
    /**
     * GET List of Incidences
     */
    const retriveIncidences = () => {
        //const endOffset = (page) + itemsPerPAge;
        const items = getListIncidence.slice(
            (page - 1) * itemsPerPAge,
            (page - 1) * itemsPerPAge + itemsPerPAge
        );
        console.log("ITEMS RETRIVE => ", items);
        setListOperator(items);
        setPageCount(Math.ceil(getListIncidence.length / itemsPerPAge));
    };

    /**
     * update list each click or page change
     */
    useEffect(() => {
        let isMounted = true;

        retriveIncidences();

        return () => {
            isMounted = false;
        };
    }, [page, itemsPerPAge, listOperatorsState]);

    //serachForm
    const searchIncidence = (event) => {
        event.preventDefault();

        setListOperators({
            listUsers: [],
            loadingCon: true,
        });

        getOperators(
            currentUser.idCompany, currentUser.id, serachText, currentUser.typeUser
        ).then((result) => {

            let customeCheckOpe = [];
            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                customeCheckOpe.push({ id: element.id, checked: false, name: element.name });
            }
            console.log(customeCheckOpe);
            setListOperators({
                listUsers: customeCheckOpe,
                loadingCon: false,
            });
        });

        if (listOperatorsState.listUsers.length > 0) {
            retriveIncidences();
        }
    };



    const handlePageChange = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        // if (selectAll === true) {
        //     listOperatorsState.listUsers.forEach((element, index) => {

        //         listOperatorsState.listUsers[index].checked = true;

        //     });
        // } else {
        //     listOperatorsState.listUsers.forEach((element, index) => {

        //         listOperatorsState.listUsers[index].checked = false;

        //     });
        // }
        setPage(currentPage);
    };

    const handlerBackArrowPage = () => {
        const arrowBack = document.getElementById("back");
        if (page === 1) {
            arrowBack.classList.add("disabled");
            setPage(1);
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: 1,
                })
            );
        }
        if (page > 1) {
            arrowBack.classList.remove("disabled");
            const backPage = page - 1;
            setPage(backPage);
            setFormValues({ ...formValues, pageActive: backPage });
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: backPage,
                })
            );
        }
    };

    const handlerNextArrowPage = () => {
        const netxArrow = document.getElementById("next");
        if (page === pageCount) {
            netxArrow.classList.add("disabled");
            //setPage(pageCount);
        }
        if (page < pageCount) {
            netxArrow.classList.remove("disabled");
            const nextPage = page + 1;
            setPage(nextPage);
            setFormValues({ ...formValues, pageActive: nextPage });
            localStorage.setItem(
                "savePageInc",
                JSON.stringify({
                    pagination: nextPage,
                })
            );
        }
    };



    console.log("List Operators ", listOperators);
    //console.log(pageCount);

    const renderPagination = () => {
        let listPageCount = [];
        for (let index = 0; index < pageCount; index++) {
            if (index == 0) {
                let num = 1;
                listPageCount.push(
                    <li className={`${page === num ? "active" : ""}`} key={index}>
                        <Link to="" onClick={handlePageChange} id={num}>
                            {num}
                        </Link>
                    </li>
                );
            } else {
                let num = index + 1;
                listPageCount.push(
                    <li className={`${page === num ? "active" : ""}`} key={index}>
                        <Link to="" onClick={handlePageChange} id={num}>
                            {num}
                        </Link>
                    </li>
                );
            }
        }

        return listPageCount;
    };

    const jsonPagination = JSON.parse(localStorage.getItem("savePageInc"));
    const getPagination = jsonPagination["pagination"];
    const initialValues = { pageActive: getPagination };

    const [formValues, setFormValues] = useState(initialValues);

    const handleGoBackPage = (e) => {
        e.preventDefault();
        let numPag = document.getElementById("pageActive").value;
        console.log(numPag);
        setPage(parseInt(numPag));
        localStorage.setItem(
            "savePageInc",
            JSON.stringify({
                pagination: parseInt(numPag),
            })
        );
    };
    const onChangePageInput = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const newPagination = () => {
        return (
            <div className="row">
                <div className="contentPagination">
                    <Link
                        className={`${page === 1 ? "disabled" : ""}`}
                        to=""
                        id="back"
                        onClick={handlerBackArrowPage}
                    >
                        <i className=" medium material-icons">chevron_left</i>
                    </Link>
                    {/* <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20"> */}
                    <form className="center" onSubmit={handleGoBackPage}>
                        <div className="white inputPage">
                            <input
                                name="pageActive"
                                type="number"
                                className=""
                                required
                                id="pageActive"
                                value={formValues.pageActive}
                                onChange={onChangePageInput}
                                min={1}
                                max={pageCount}
                                style={{ textAlign: "center" }}
                            />
                            {/* onChangeCapture={onChangePageInput} */}
                        </div>
                    </form>

                    {/* </div> */}

                    <label className="deText center">de</label>
                    <label className="deText center">{pageCount}</label>
                    <Link
                        className={`${page === pageCount ? "disabled" : ""}`}
                        to=""
                        id="next"
                        onClick={handlerNextArrowPage}
                    >
                        <i className=" medium material-icons">chevron_right</i>
                    </Link>
                </div>
            </div>
        );
    };

    //renderTable Information
    const listTableRefunds = () => {
        if (listOperators.length > 0) {
            return (
                <div className="contentTable">
                    <table className="striped highlight  responsive-table  tableFont">
                        <thead className="borderbotomLine">
                            <tr style={{ pointerEvents: "none" }}>

                                <th className="leftContent" >Operador</th>

                                <th className="leftHead" style={{ pointerEvents: "none" }}>
                                    <div className="" style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", pointerEvents: "none" }}>
                                        <span style={{ pointerEvents: "none" }}>Seleccionar todos</span>

                                        <input className='ckBAll filled-in sizeCheckBitacora' type="checkbox" checked={selectAll} id="allSelected" style={{ top: "5px" }} onChange={handleSelectAll} />
                                    </div>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listOperators.map((operator) => {
                                count++;
                                return (
                                    <tr
                                        // className={operator.visto === 0 ? "newsEvidences" : ""}
                                        id={count % 2 === 0 ? "rowHighLigth" : ""}
                                        key={`${operator.id}-${count}`}
                                    >
                                        <td>{operator.name}</td>
                                        {selectAll === true ?
                                            <td>
                                                <div className="optionsEvi">
                                                    <input className='ck1 filled-in sizeCheckBitacora' type="checkbox" value={operator.id} ref={checkbox} id={operator.id} onChange={handleSelectByOne} checked={operator.checked} />
                                                </div>
                                            </td>
                                            : operator.checked === true ?
                                                <td>
                                                    <div className="optionsEvi">
                                                        <input className='ck1 filled-in sizeCheckBitacora' type="checkbox" value={operator.id} ref={checkbox} id={operator.id} onChange={handleSelectByOne} checked={operator.checked} />
                                                    </div>
                                                </td> :
                                                <td>
                                                    <div className="optionsEvi">
                                                        <input className='ck1 filled-in sizeCheckBitacora' type="checkbox" value={operator.id} ref={checkbox} id={operator.id} onChange={handleSelectByOne} />
                                                    </div>
                                                </td>
                                        }

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {newPagination()}

                </div>
            );
        } else {
            //
        }
    };

    return (
        <>
            <div className="containerEvid">
                <div className="" style={{ background: "#5E5E68" }}>
                    <form
                        className="center"
                        style={{ padding: "10px 0px" }}
                        onSubmit={searchIncidence}
                    >
                        <div className="input-field inputSerach ">
                            <i
                                className="material-icons  prefix "
                                style={{ color: "#A3A3A9", left: "0px", top: "10px" }}
                            >
                                search
                            </i>
                            <input
                                id="search"
                                type="text"
                                placeholder="Buscar"
                                className="inputSerachBorderBottom"
                                value={serachText}
                                onChange={onChangeSerachTextInc}
                            />
                        </div>
                    </form>
                </div>

                <div className="row center">
                    <h4>Bitácora</h4>
                </div>


                {/** FILTER DATES */}
                <div className="row">
                    <div className="col s12 m3 l3"></div>
                    <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="initDate" value={initDate} /></div> </div>
                    <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="endDate" value={endDate} placeholder={endDate === '' ? 'Fecha Final' : ''} /></div></div>
                    <div className="col s12 m3 l3"><button className="btn btnBitacora" style={{ borderRadius: "18px", position: "relative", top: "5px", marginLeft: "20%" }} onClick={handlerGeneratePDF}>Generar PDF</button></div>
                </div>
                {/** FILTER DATES */}

                {loading === true ? (
                    <div className="row center">
                        <CircleGif />
                    </div>
                ) : (
                    listTableRefunds()
                )}
            </div>

        </>
    );
};

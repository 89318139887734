

import React from 'react'
import { StatusForm } from '../components/Status/StatusForm'
import '../css/Register.css';
export const StatusPage = () => {
  return (
    <StatusForm/>
  )
}

import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/type';
//import Select from 'react-select'
import { useGetTypeUserList } from '../../hooks/hooks_register';
import { getTypeUser, registerUser } from '../../helpers/registerFunc';
import M from 'materialize-css';
import Swal from 'sweetalert2';


export const RegisterForm = () => {

    const [selectState, setSelectState] = useState(0);
    const handleStateChange = () => {
        let count = 0;
        count++;
        setSelectState(count);
    };

    const { loading, listTypeUser } = useGetTypeUserList(selectState);
    // console.log("listTypeUser2=>" + listTypeUser);
    const { currentUser } = useContext(AuthContext);
    const listaValores = listTypeUser;

    const initialValues = { user: "", email: "", password: "", confirmPassword: "", typeUser: "", active: "1" };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const validate = async (values) => {
        let procesoOk = true;
        const errors = {};
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        if (!values.user) {
            errors.user = "El usuario es requerido.";
            procesoOk = false;
        }
        if (!values.email) {
            errors.email = "El email es requerido.";
            procesoOk = false;
        } else if (!regex.test(values.email)) {
            errors.email = "El email no tiene el formato correcto.";
            procesoOk = false;
        }
        if (!values.password) {
            errors.password = "El password es requerido.";
            procesoOk = false;
        } else if (values.password.length < 6) {
            errors.password = "El password debe tener como mínimo 6 caracteres.";
            procesoOk = false;
        } else if (values.password != values.confirmPassword) {
            errors.confirmPassword = "No coincide el password con el de confirmación.";
            procesoOk = false;
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = "La confirmación del password es requerida.";
            procesoOk = false;
        }
        if (!values.typeUser) {
            errors.typeUser = "El tipo de usuario es requerido.";
            procesoOk = false;
        }
        return [errors, procesoOk];
    }

    useEffect(() => {
        let isMounted = true;
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
        setFormValues(initialValues);
        document.getElementById('user').value = '';
        document.getElementById('email').value = '';
        document.getElementById('pass').value = '';
        document.getElementById('repass').value = '';
        document.getElementById('rol').value = '0';
        return () => {
            isMounted = false;
        };


    }, []);

    //Register
    const RegisterUser_OnClick = async (e) => {
        e.preventDefault();
        const result = await validate(formValues);
        const resErrors = result[0];
        const contErrors = result[1];
        setFormErrors(resErrors);

        if (contErrors == true) {
            const resulRegister = await registerUser(formValues.email, formValues.password, formValues.user,
                "", currentUser.idCompany, formValues.typeUser, formValues.active);
            if (resulRegister) {

                Swal.fire({
                    title: 'Aviso',
                    text: 'Usuario registrado.',
                    confirmButtonText: 'Ok',
                    icon: 'success',
                });
                document.getElementById('user').value = '';
                document.getElementById('email').value = '';
                document.getElementById('pass').value = '';
                document.getElementById('repass').value = '';
                document.getElementById('rol').value = '0';
                setFormValues(initialValues);

            } /*else {
                Swal.fire({
                    title:'Aviso',
                    text:'El usuario no se registró.',
                    confirmButtonText:'Ok',
                    icon:'warning',
                  });
            }*/
        }

    }

    return (
        <div className='containerUser'>
            <div className='contentForm' style={{ paddingTop: '200px' }}>
                <div className='col s12 m12 l12 center'>
                    <div className=' center'><h4>Registro de usuarios</h4></div>
                    <div className='grettingsDiv'>

                    </div>
                    <form className='col s12 m12 l12'>
                        <div className='row'>

                            <div className="input-field  white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput ">person</i>
                                <input name="user" placeholder="Usuario" type="text" required id='user'
                                    value={formValues.user} onChange={handleChange} />
                            </div>
                            <p className="red-text">{formErrors.user}</p>
                            <div className="input-field  white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput ">email</i>
                                <input name="email" placeholder="Correo electrónico" type="email" className="validate" required id='email'
                                    value={formValues.email} onChange={handleChange} />
                            </div>
                            <p className="red-text">{formErrors.email}</p>

                            <div className="input-field white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput "></i>
                                <select onClick={handleStateChange} name="typeUser" id='rol'
                                    value={formValues.typeUser}
                                    onChange={handleChange} required>
                                    <option key='0' value='0'>Seleccione tipo usuario</option>
                                    {currentUser.typeUser === 2 ? <></> : <option key='1' value='1'>SuperAdmin</option>}
                                    {currentUser.typeUser === 2 ? <></> : <option key='2' value='2'>Admin</option>}
                                    <option key='3' value='3'>Monitorista/Jefe Flotilla</option>
                                    {/*<option key='4' value='4'>Operador</option>*/}
                                    {
                                        /* <option key='3' value='3'></option>
                                        listTypeUser.map((typeUser) => {
                                            console.log(typeUser.typeUserNameEs);
                                            return <option key={typeUser.typeUser} value={typeUser.typeUser}>
                                                {typeUser.typeUserNameEs}
                                            </option>
                                        })
                                        */
                                        /*
                                         listaValores.map((typeUser) => {
                                             // console.log("typeUser.typeUser=>" + typeUser.typeUser + " " + "typeUser.typeUserNameEs=>" + typeUser.typeUserNameEs);
                                             console.log(`<option key='${typeUser.typeUser}' value='${typeUser.typeUser}'>${typeUser.typeUserNameEs}</option>`);
                                             return `<option key='${typeUser.typeUser}' value='${typeUser.typeUser}'>${typeUser.typeUserNameEs}</option>`
                                         })
                                         */
                                    }
                                </select>

                            </div>
                            <p className="red-text">{formErrors.typeUser}</p>

                            <div className="input-field  white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput" >lock</i>
                                <input name="password" placeholder="Contraseña" type="password" className="" required id='pass'
                                    value={formValues.password} onChange={handleChange} />
                            </div>
                            <p className="red-text">{formErrors.password}</p>

                            <div className="input-field  white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput" >lock</i>
                                <input name="confirmPassword" placeholder="Confirma tu contraseña" type="password" className="" required id='repass'
                                    value={formValues.confirmPassword} onChange={handleChange} />
                            </div>
                            <p className="red-text">{formErrors.confirmPassword}</p>


                            <div className="input-field white lighten-5 inputRegisterUser">
                                <i className="tiny material-icons prefix iconPositionInput "></i>
                                <select name="active" value={formValues.active} onChange={handleChange} required placeholder='Activo' id='activo'>
                                    <option key='1' value='1'>Activo</option>
                                    <option key='0' value='0'>Inactivo</option>
                                </select>
                            </div>

                        </div>
                        <div className='registerDiv'>
                            <button
                                className='btn waves-effect waves-light btnFromRegisterCreate'
                                onClick={RegisterUser_OnClick}
                                type='button' name='Crear'>
                                Crear
                            </button>
                            <Link to='/usuarios' className='btn btnFromLogin' id='btnFromLoginRU' style={{ background: "#5E5E68", width: "100px" }}>Regresar</Link>


                        </div>
                    </form>

                </div>

            </div>
        </div>

    )
}

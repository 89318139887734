

import React from 'react'
import { ConceptoDevolutionsIndex } from '../components/ConceptoDevoluciones/ConceptoDevolutionsIndex'

export const ConceptoDevoluciones = () => {
    return (

        <ConceptoDevolutionsIndex />
    )
}



import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';







export const MapPopup = ({ latitude = 21, longitude = -100, infoData }) => {

  const [activeMarker, setActiveMarker] = useState(null);
  let zoo = 7;
  const [zoom, setZoom] = useState(15);
  const [loadMap, setLoadMap] = useState({});
  const [centerLocation, setCenter] = useState({ lat: 21, lon: -100 });
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { isLoaded } = useLoadScript({
    id: "script-id",
    language: "es",
    googleMapsApiKey: "AIzaSyCUuTHJGUQ8kkpNFzDB7vjuS8vixaI3o58",
  });


  // console.log(infoData.latitude);



  // useMemo(() => {
  //   setCenter({ lat: infoData.latitude, lon: infoData.longitude });
  //   // console.log('loadMap', loadMap)
  //   setZoom(12)
  //   zoo = 12
  // }, [latitude, longitude])

  useEffect(() => {
    // if () {
    //   latitude = 21;
    //   longitude = -100;

    // }
    let isMounted = true;
    if (latitude !== 21 && longitude !== -100) {
      setCenter({ lat: infoData.latitude, lon: infoData.longitude });

      setZoom(12)
      zoo = 12
    }
    return () => {
      isMounted = false;
    };

  }, [centerLocation, latitude, longitude])

  const center = {
    lat: latitude, //20.214526,
    lng: longitude, //-101.122280,
  };

  // const { latCome, longCome } = latLong;
  // console.log('LAT STATU => ', latLong);
  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds({ lat: latitude, lng: longitude });
    map.fitBounds(bounds);

    // map.zoom(10);
  };

  const containerStyle = {
    width: '100%',
    height: '400px'
  };




  const handlerActiveMarker = (marker) => {
    //marker.preventDefault();
    console.log(marker);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  }

  // const onUnmount = React.useCallback(function callback(map) {

  //   setMap(null)
  // }, [])




  const renderMap = () => {
    //zoo = 12;
    console.log(React.version);
    if (!isLoaded) {
      return <div>EL Mapa no se puede cargar, ocurrio un error.</div>;
    } else {

      return <GoogleMap
        id="map-page"

        onLoad={handleOnLoad}
        mapContainerStyle={containerStyle}
        center={center}

        //defaultZoom={zoo}

        zoom={12}

        onClick={() => setActiveMarker(null)}


      >

        <Marker

          key={infoData.userUid}
          position={{ lat: latitude, lng: longitude }}
          onClick={() => {
            //e.preventDefault();
            handlerActiveMarker(infoData.userUid)
          }}
        >

          {
            activeMarker === infoData.userUid ? (
              <InfoWindow

                onCloseClick={() => setActiveMarker(null)}>
                <div className='formatInfoMarker'>
                  <span style={{ fontSize: '14pt', fontWeight: 'bold' }}>Operador: </span><span style={{ fontSize: '14pt', fontWeight: 'normal' }}>{infoData.user_name}</span>
                  <br />
                  <br />
                  <span style={{ fontSize: '14pt', fontWeight: 'bold' }}>Unidad: </span><span style={{ fontSize: '14pt', fontWeight: 'normal' }}>{infoData.vehicleNumber}</span>
                  <br />
                  <br />


                  <span style={{ fontSize: '14pt', fontWeight: 'bold' }}>Fecha: </span><span style={{ fontSize: '14pt', fontWeight: 'normal' }}>{infoData.dateString}</span>

                </div>

              </InfoWindow>) : null
          }

        </Marker>


      </GoogleMap>
    }

  }

  const closeMap = (e) => {
    e.preventDefault();
    setCenter({ lat: 21, lon: -100 });
  }

  return (
    <div id="modal1" className="modal">
      <div className="modal-content">
        {/*MAPA */

          renderMap()

        }


      </div>
      <div className="modal-footer">
        <Link to='' onClick={closeMap} className="modal-close btn-flat">Aceptar</Link>
      </div>
    </div>
  )
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/authContext";
import {
  asignOperadores,
  getMonitoristas,
  getOperadores,
  getOperadoresAsignados,
  getOperadoresSinAsignados,
} from "../../helpers/grupos";
import { CircleGif } from "../CircleGif";
import { doc } from "firebase/firestore";
export const GruposForm = () => {
  //currentUser logeado
  const { currentUser } = useContext(AuthContext);
  //lista para operadoer libres seleccionados
  let listSelectOpF = [];
  let listSelectOpA = [];
  //lista para operdadores asignados selecciondos

  //listMonitoristas
  const [listMonitoristas, setListMonitoristas] = useState({
    listMonitoristasarr: [],
    loading: true,
  });
  //listAllOperators
  const [listOperadores, setListOperadores] = useState({
    listOperadoress: [],
    loadingO: true,
  });
  //listAllOperatorsAsignados
  const [listOperadoresasig, setListOperadoresasig] = useState({
    listOpeAsig: [],
    loadingOAsi: true,
  });
  const [changeMonitorita, setMonitorista] = useState("");
  const [searchText, setSeachTextState] = useState("");
  const { listMonitoristasarr, loading } = listMonitoristas;
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllAsigned, setCheckAllAsigned] = useState(false);
  const [seachTextAsigned, setSeachTextAsignedState] = useState("");
  //console.log(listMonitoristasarr);
  const { listOperadoress, loadingO } = listOperadores;
  const { listOpeAsig, loadingOAsi } = listOperadoresasig;
  //const isSubscribed = useRef(true);
  //init
  useEffect(() => {
    let isMounted = true;
    const select = document.querySelectorAll("select");
    M.FormSelect.init(select);

    getMonitoristas(currentUser.idCompany, currentUser.id).then((result) => {
      setListMonitoristas({
        listMonitoristasarr: result,
        loading: false,
      });
    });

    if (changeMonitorita !== "") {
      getOperadoresSinAsignados(
        currentUser.idCompany,
        changeMonitorita,
        searchText
      ).then((opes) => {
        // console.log('opers clean',opes);
        setListOperadores({
          listOperadoress: opes,
          loadingO: false,
        });
      });
    } else {
      getOperadores(currentUser.idCompany, currentUser.id, searchText).then(
        (result) => {
          setListOperadores({
            listOperadoress: result,
            loadingO: false,
          });
        }
      );
    }

    if (changeMonitorita !== "") {
      //call the funcion of operadores asigandos.
      getOperadoresAsignados(
        currentUser.idCompany,
        changeMonitorita,
        seachTextAsigned
      ).then((oper) => {
        //console.log(oper);
        setListOperadoresasig({
          listOpeAsig: oper,
          loadingOAsi: false,
        });
      });
    }

    return () => {
      isMounted = false;
    };
  }, [listMonitoristas.loading, searchText, seachTextAsigned]);
  //func para esuchar el cambio del select Monitorista
  const handlerChangeSelector = (e) => {
    e.preventDefault();
    console.log(e.target.value); // get the value = id firebase user
    setListOperadoresasig({
      listOpeAsig: [],
      loadingOAsi: true,
    });

    setMonitorista(e.target.value);

    if (e.target.value !== "" || e.target.value !== "0") {
      setCheckAll(false);
      document.querySelector(".allSelected").checked = false;
      getOperadoresAsignados(
        currentUser.idCompany,
        e.target.value,
        seachTextAsigned
      ).then((oper) => {
        //console.log(oper);
        setListOperadoresasig({
          listOpeAsig: oper,
          loadingOAsi: false,
        });
      });

      getOperadoresSinAsignados(
        currentUser.idCompany,
        e.target.value,
        searchText
      ).then((opes) => {
        console.log("opers clean", opes);
        setListOperadores({
          listOperadoress: opes,
          loadingO: false,
        });
      });
    }
    /////////////////
  };

  // Funcion para seleccionar todos los operadores de free seleccionarlos a todos
  const handleSelectAllOpeFree = async () => {
    // e.preventDefault();
    // console.log(e.target.checked);
    //let listOpeFee = [];

    if (checkAll === false) {
      setCheckAll(true);
      document.querySelector(".allSelected").checked = true;
      const getAllSelecteOperatorsFree = document.querySelectorAll(".opeFree");
      for (let x = 0; x < getAllSelecteOperatorsFree.length; x++) {
        getAllSelecteOperatorsFree[x].checked = true;
        //listOpeFee.push(getAllSelecteOperatorsFree[x].value);
      }
    } else {
      setCheckAll(false);
      document.querySelector(".allSelected").checked = false;
      const getAllSelecteOperatorsFree = document.querySelectorAll(".opeFree");
      for (let x = 0; x < getAllSelecteOperatorsFree.length; x++) {
        getAllSelecteOperatorsFree[x].checked = false;
        //listOpeFee.push(getAllSelecteOperatorsFree[x].value);
      }
    }
  };

  // Funcion para seleccionar todos los operadores de free seleccionarlos a todos
  const handleSelectAllOpeAsigned = async () => {
    // e.preventDefault();
    // console.log(e.target.checked);
    //let listOpeFee = [];

    if (checkAllAsigned === false) {
      setCheckAllAsigned(true);
      document.querySelector(".allSelectedAsigned").checked = true;
      const getAllSelecteOperatorsAsigned =
        document.querySelectorAll(".opeAsig");
      for (let x = 0; x < getAllSelecteOperatorsAsigned.length; x++) {
        getAllSelecteOperatorsAsigned[x].checked = true;
        //listOpeFee.push(getAllSelecteOperatorsAsigned[x].value);
      }
    } else {
      setCheckAllAsigned(false);
      document.querySelector(".allSelectedAsigned").checked = false;
      const getAllSelecteOperatorsAsigned =
        document.querySelectorAll(".opeAsig");
      for (let x = 0; x < getAllSelecteOperatorsAsigned.length; x++) {
        getAllSelecteOperatorsAsigned[x].checked = false;
        //listOpeFee.push(getAllSelecteOperatorsFree[x].value);
      }
    }
  };

  // Funcion para escuhar el clic en algun li de las dos listas y seleccionar operadores

  const handleSelectOperators = async (e) => {
    e.preventDefault();

    //obtenemos todos los operadores de ambas listas
    const getAllSelecteOperatorsFree = document.querySelectorAll(".opeFree");
    const getAllSelecteOperAsig = document.querySelectorAll(".opeAsig");

    let listOpeFee = [];
    let listOpeAsig = [];
    if (e.target.id == "rightBTN") {
      for (let x = 0; x < getAllSelecteOperatorsFree.length; x++) {
        if (getAllSelecteOperatorsFree[x].checked === true) {
          listOpeFee.push(getAllSelecteOperatorsFree[x].value);
        }
      }
      // console.log(listOpeFee.length)
      // console.log(changeMonitorita);
      if (listOpeFee.length === 0) {
        Swal.fire({
          title: "Aviso",
          text: "Debes al menos seleccionar un operador libre para hacer esta acción.",
          icon: "warning",
        });
        return false;
      }
      if (changeMonitorita === "" || changeMonitorita === "0") {
        Swal.fire({
          title: "Aviso",
          text: "Debes al menos seleccionar un Monitorista o jefe de flotilla para hacer esta acción.",
          icon: "warning",
        });
        return false;
      }

      await sendListOfOperators(listOpeFee, changeMonitorita, 1);
    } else {
      for (let x = 0; x < getAllSelecteOperAsig.length; x++) {
        if (getAllSelecteOperAsig[x].checked === true) {
          listOpeAsig.push(getAllSelecteOperAsig[x].value);
        }
      }
      //console.log(listOpeAsig.length)
      //console.log(changeMonitorita);
      if (listOpeAsig.length === 0) {
        Swal.fire({
          title: "Aviso",
          text: "Debes al menos seleccionar un operador asignado para hacer esta acción.",
          icon: "warning",
        });
        return false;
      }
      if (changeMonitorita === "" || changeMonitorita === "0") {
        Swal.fire({
          title: "Aviso",
          text: "Debes al menos seleccionar un Monitorista o jefe de flotilla para hacer esta acción.",
          icon: "warning",
        });
        return false;
      }

      await sendListOfOperators(listOpeAsig, changeMonitorita, 2);
    }

    //console.log(e);
  };

  //function to get send the list of a uid of operatos and move to another asig or free
  const sendListOfOperators = async (
    listOfOpperators,
    monitorista,
    acction
  ) => {
    Swal.fire({
      title: "Asignando operadores.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: "<b>Por favor espere...</b>",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const updateOrAddManagerID = await asignOperadores(
      listOfOpperators,
      monitorista,
      acction
    );
    if (updateOrAddManagerID === false) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: "Intentelo mas tarde.",
        confirmButtonText: "Ok",
        icon: "error",
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Éxito",
        text: "Operación realizada correctamente.",
        icon: "success",
      }).then(function () {
        window.location.reload();
      });
    }
  };

  /**
   * SEARCH
   *
   */
  const searchOperator = (e) => {
    e.preventDefault();

    if (changeMonitorita !== "") {
      getOperadoresSinAsignados(
        currentUser.idCompany,
        changeMonitorita,
        searchText
      ).then((opes) => {
        // console.log('opers clean',opes);
        setListOperadores({
          listOperadoress: opes,
          loadingO: false,
        });
      });
    } else {
      getOperadores(currentUser.idCompany, currentUser.id, searchText).then(
        (result) => {
          setListOperadores({
            listOperadoress: result,
            loadingO: false,
          });
        }
      );
    }
  };
  //SEARCH CHANGE
  const onchangeSearchText = (e) => {
    e.preventDefault();
    setSeachTextState(e.target.value);
  };

  //SEARCH CHANGE
  const onchangeSearchTextAsigned = (e) => {
    e.preventDefault();
    setSeachTextAsignedState(e.target.value);
  };

  // console.log(listOperadoress);

  //Render Lista de operadores libres
  const listOfOperatorsFree = () => {
    let listOper = [];
    if (listOperadoress.length > 0) {
      listOperadoress.forEach((operator, index) => {
        listOper.push(
          <>
            <li key={operator.id} id={index} className="sizeCardItem ">
              {/*isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={operator.id} />  : '' */}
              <input
                className="opeFree filled-in"
                type="checkbox"
                value={operator.id}
              />
              <Link to="" id={`${operator.id}`}>
                <div className="row" style={{ pointerEvents: "none" }}>
                  <div
                    className="col s4 m4 l3 item-img-prof imgCenterOper"
                    style={{ pointerEvents: "none" }}
                  >
                    <img
                      src={
                        operator.image !== ""
                          ? operator.image
                          : `/assets/marvel-thor.png`
                      }
                      alt={operator.name}
                      className="icoPicture"
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                  <div
                    className="col s6 m6 l7 item-list-align textNameOpercenter"
                    style={{ pointerEvents: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        display: "block",
                        color: "#203152",
                        pointerEvents: "none",
                      }}
                    >
                      <b>{operator.name.substring(0, 65)}</b>
                    </span>
                  </div>
                </div>
              </Link>
            </li>
            <li className="divider"></li>
          </>
        );
      });
    } else {
      listOper = [];
    }

    return listOper;
  };

  //lista render operadores asigandados por monitorista
  const listOfOperatorsAssignados = () => {
    let listOperFree = [];
    if (listOpeAsig.length > 0) {
      listOpeAsig.forEach((operator, index) => {
        listOperFree.push(
          <>
            <li key={operator.id} id={index} className="sizeCardItem ">
              {/*isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={operator.id} />  : '' */}
              <input
                className="opeAsig filled-in"
                type="checkbox"
                value={operator.id}
              />
              <Link to="" id={`${operator.id}`} className="">
                <div className="row" style={{ pointerEvents: "none" }}>
                  <div
                    className="col s4 m4 l3 item-img-prof imgCenterOper"
                    style={{ pointerEvents: "none" }}
                  >
                    <img
                      src={
                        operator.image !== ""
                          ? operator.image
                          : `/assets/marvel-thor.png`
                      }
                      alt={operator.name}
                      className="icoPicture"
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                  <div
                    className="col s6 m6 l7 item-list-align textNameOpercenter textNameLeftOperAsig"
                    style={{ pointerEvents: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        display: "block",
                        color: "#203152",
                        pointerEvents: "none",
                      }}
                    >
                      <b>{operator.name.substring(0, 65)}</b>
                    </span>
                  </div>
                </div>
              </Link>
            </li>
            <li className="divider"></li>
          </>
        );
      });
    } else {
      listOperFree = [];
    }

    return listOperFree;
  };

  const validateListOfAsignados = () => {
    if (changeMonitorita !== "" || changeMonitorita !== "0") {
      return listOpeAsig.length > 0 ? (
        listOfOperatorsAssignados()
      ) : (
        <li key={0}>Sin Operadores</li>
      );
    } else {
      return <li key={0}>Seleciona un monitorista o jefe de flotilla</li>;
    }
  };

  return (
    <div className="containerUser" style={{ padding: "3%" }}>
      <div className="row center">
        <h4>Asignar Operadores</h4>
      </div>

      <form className="col s12 m12 l12">
        <div className="row">
          <div className="input-field white lighten-5 inputRegisterUser">
            <i className="tiny material-icons prefix iconPositionInput "></i>
            <select name="typeUser" required onChange={handlerChangeSelector}>
              <option key="0" value="0" selected disabled>
                Selec. Monitor/Jefe Flotilla
              </option>

              {listMonitoristasarr.map((user) => {
                //console.log(user);
                return (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="contentDivs">
          <div className="white divsListOperators ">
            <div className="center">
              <h4>Operadores libres</h4>
              <div className="select_AllDiv">
                <input
                  className="allSelected filled-in "
                  type="checkbox"
                  name="all"
                  style={{ top: "-5px", marginLeft: "5px" }}
                  onClick={handleSelectAllOpeFree}
                />
                <label
                  className=""
                  htmlFor="all"
                  style={{
                    marginLeft: "10px",
                    fontSize: "11pt",
                    display: "block",
                    color: "#203152",
                  }}
                >
                  Todos
                </label>
              </div>
              {/*SERACH */}
              <div
                className="center"
                style={{
                  background: "#DDDDDF",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <form onSubmit={searchOperator}>
                  <div className="input-field col s12 m12 l12 serachChat">
                    <i className="material-icons prefix iconPositionInput">
                      search
                    </i>
                    <input
                      placeholder="Buscar"
                      id="buscador"
                      name="searchText"
                      type="text"
                      className="inputSerachBorderBottom"
                      style={{ color: "#000000" }}
                      onChange={onchangeSearchText}
                    />
                  </div>
                </form>
              </div>
              {/*SERACH */}
            </div>
            <ul style={{ padding: "10px" }}>
              {loadingO === true ? (
                <div className="row center">
                  <CircleGif />
                </div>
              ) : (
                listOfOperatorsFree()
              )}
            </ul>
          </div>

          <div className="center">
            <br></br>
            <button
              className="btn "
              id="rightBTN"
              onClick={handleSelectOperators}
            >
              <i className="material-icons" style={{ pointerEvents: "none" }}>
                chevron_right
              </i>
            </button>
            <br></br>
            <br></br>
            <button
              className="btn"
              id="leftBTN"
              onClick={handleSelectOperators}
            >
              <i className="material-icons" style={{ pointerEvents: "none" }}>
                chevron_left
              </i>
            </button>
          </div>

          <div className="white divsListOperators ">
            <div className="center">
              <h4>Operadores asignados</h4>
              {changeMonitorita != "" ? (
                <>
                  <div className="select_AllDiv">
                    <input
                      className="allSelectedAsigned filled-in "
                      type="checkbox"
                      name="all"
                      style={{ top: "-5px", marginLeft: "5px" }}
                      onClick={handleSelectAllOpeAsigned}
                    />
                    <label
                      className=""
                      htmlFor="all"
                      style={{
                        marginLeft: "10px",
                        fontSize: "11pt",
                        display: "block",
                        color: "#203152",
                      }}
                    >
                      Todos
                    </label>
                  </div>
                  {/*SERACH */}
                  <div
                    className="center"
                    style={{
                      background: "#DDDDDF",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <form onSubmit={searchOperator}>
                      <div className="input-field col s12 m12 l12 serachChat">
                        <i className="material-icons prefix iconPositionInput">
                          search
                        </i>
                        <input
                          placeholder="Buscar"
                          id="buscador"
                          name="searchText"
                          type="text"
                          className="inputSerachBorderBottom"
                          style={{ color: "#000000" }}
                          onChange={onchangeSearchTextAsigned}
                        />
                      </div>
                    </form>
                  </div>
                  {/*SERACH */}
                </>
              ) : (
                <></>
              )}
              <ul style={{ padding: "10px" }}>{validateListOfAsignados()}</ul>
            </div>
          </div>
        </div>
        <div
          className="registerDiv btnActReg-cente"
          style={{ textAlign: "center" }}
        >
          {/*<button 
                            className='btn waves-effect waves-light btnFromRegisterCreate' 
                           
                            type='button' name='Crear'>
                                Actualizar
                        </button> */}
          <Link
            to="/usuarios"
            className="btn btnFromLogin"
            id="btnFromGrups"
            style={{ background: "#5E5E68" }}
          >
            Regresar
          </Link>
        </div>
      </form>
    </div>
  );
};

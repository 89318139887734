import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
//import { userCreditial } from '../../config/ConfigFirebase';
import { sinIn, isActiveUser } from "../../helpers/loginFunc";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/type";
import Swal from "sweetalert2";

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  // state para el remainder de email & password
  const [remeber, setRemeber] = useState({
    email: "",
    password: "",
    remeberCheck: false,
  });

  //funciton to checked or uncheck the remeber button
  const handlerRememberME = (e) => {
    // e.preventDefault();
    // e == true or false;
    console.log(e);
    let dataRem;
    if (e === true) {
      setRemeber({
        email: email,
        password: password,
        remeberCheck: true,
      });
      dataRem = {
        email: email,
        password: password,
        remeberCheck: true,
      };
    } else {
      setRemeber({
        email: "",
        password: "",
        remeberCheck: false,
      });
      dataRem = {
        email: "",
        password: "",
        remeberCheck: false,
      };
    }

    if (!localStorage.getItem("remeber")) {
      localStorage.setItem("remeber", JSON.stringify(dataRem));
    } else {
      localStorage.removeItem("remeber");
      localStorage.setItem("remeber", JSON.stringify(dataRem));
    }
  };

  useEffect(() => {
    let isMounted = true;
    const remeberMeData = localStorage.getItem("remeber");

    if (remeberMeData !== null) {
      const objRemebermeData = JSON.parse(remeberMeData);
      console.log(objRemebermeData["email"]);

      setEmail(objRemebermeData["email"]);
      setPassword(objRemebermeData["password"]);
      if (objRemebermeData["remeberCheck"] === true) {
        document.getElementById("chekRemember").checked = true;
      } else {
        document.getElementById("chekRemember").checked = false;
      }
    }

    return () => {
      isMounted = false;
    };
  }, [remeber]);

  //  Password toggle handler
  const togglePassword = (e) => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    console.log(e);
    let dataRem;
    if (e === true) {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };

  //Login
  const headlerLogin = async () => {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      (email === null || email === "") &&
      (password === null || password === "")
    ) {
      //console.log("El correo /o contraseña no pueden ser vacios.");

      Swal.fire({
        title: "Aviso",
        text: "El correo /o contraseña no pueden ir vacios.",
        confirmButtonText: "Ok",
        icon: "warning",
      });
      return false;
    }
    if (password === null || password === "") {
      //console.log("El correo /o contraseña no pueden ser vacios.");

      Swal.fire({
        title: "Aviso",
        text: "La contraseña debe ser introducida.",
        confirmButtonText: "Ok",
        icon: "warning",
      });
      return false;
    }

    if (email === null || email === "") {
      //console.log("El correo /o contraseña no pueden ser vacios.");

      Swal.fire({
        title: "Aviso",
        text: "El correo debe ser introducido.",
        confirmButtonText: "Ok",
        icon: "warning",
      });
      return false;
    }

    if (email !== null || email !== "") {
      //console.log("El correo /o contraseña no pueden ser vacios.");
      if (!email.match(regExp)) {
        Swal.fire({
          title: "Aviso",
          text: "El correo introducido no es válido",
          confirmButtonText: "Ok",
          icon: "warning",
        });
        return false;
      }
    }

    Swal.fire({
      title: "Iniciando sesión.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: "<b>Por favor espere...</b>",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const isActive = await isActiveUser(email);
    //Swal.showLoading();
    if (isActive === "1") {
      const userDat = await sinIn(email, password);
      if (userDat.logged === true) {
        Swal.close();
        const action = { type: types.login, userData: userDat };

        dispatch(action);

        navigate("/", {
          replace: true,
        });
      } else {
        Swal.close();
        Swal.fire({
          title: "Advertencia",
          text: "Datos incorrectos, verifique email y contraseña.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.close();
      Swal.fire({
        title: "Advertencia",
        text: "El usuario está inactivo, no puedes iniciar sesión.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div className="Container row">
      <div className=" contentForm">
        <div className="col s12 m12 l12 center">
          <div className="logoAppDiv"></div>

          <div className="grettingsDiv">
            <span>Bienvenido</span>

            <span>Inicia sesión para continuar</span>
          </div>

          <form className="col s12 m12 l12">
            <div className="row">
              <div className="input-field  blue-grey lighten-5 formInput">
                <i className="tiny material-icons prefix iconPositionInput ">
                  person
                </i>
                <input
                  placeholder="advan@gmail.com"
                  type="email"
                  className="validate sizeInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                />
              </div>
              <div className="input-field  blue-grey lighten-5 formInput">
                <i className="tiny material-icons prefix iconPositionInput">
                  lock
                </i>
                <input
                  placeholder="*********"
                  type={passwordShown ? "text" : "password"}
                  className="sizeInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                />
              </div>
            </div>
            <p className="checkBoxRemainder">
              <label>
                <input
                  type="checkbox"
                  className="filled-in checkbox-orange"
                  id="checkSeePassword"
                  onChange={(e) => togglePassword(e.target.checked)}
                />
                <span>Ver contraseña</span>
              </label>
            </p>
            <br />
            <p className="checkBoxRemainder">
              <label>
                <input
                  type="checkbox"
                  className="filled-in checkbox-orange"
                  id="chekRemember"
                  onChange={(e) => handlerRememberME(e.target.checked)}
                />
                <span>Recordar contraseña</span>
              </label>
            </p>

            <div className="accessDiv">
              <button
                className="btn waves-effect waves-light btnFromLogin"
                type="button"
                name="login"
                onClick={headlerLogin}
              >
                Iniciar Sesión
              </button>
              <p>
                <Link className="links" to="/forgetpass">
                  ¿Olvidaste tu contraseña?
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

/**
 *  <div className='registerDiv'>
                        <span className='spanLRH' >¿Aún no tienes una cuenta?</span> <a className='links'  href='#'>Crear cuenta</a>
                        </div>
 */

import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import M from "materialize-css";
import { logOut } from "../../config/ConfigFirebase";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/type";
import { getCompanyData, getLicenciesByCompany } from "../../helpers/homeFunc";
import { CircleGif } from "../CircleGif";
import Swal from "sweetalert2";

export const Navbar = () => {
  //get info del useContext user information
  const navigate = useNavigate();
  const { currentUser, dispatch } = useContext(AuthContext);
  const [companyName, companySetState] = useState({ nombreCompany: "Advan" });

  useEffect(() => {
    let dropnav = document.querySelectorAll(".dropdown-trigger");
    var elems = document.querySelectorAll(".sidenav");
    let zoomImgs = document.querySelectorAll(".materialboxed");

    M.Materialbox.init(zoomImgs);
    M.Dropdown.init(dropnav);
    M.Sidenav.init(elems);

    getCompanyData(currentUser.idCompany).then((result) => {
      console.log("Compañia => ", result);
      companySetState({ nombreCompany: result });
    });
  }, []);

  const { nombreCompany } = companyName;
  console.log(nombreCompany);

  //LOGOUT
  const heandlerLogOut = async () => {
    //POR HACER
    console.log("logout");

    await logOut();

    dispatch({
      type: types.logout,
    });

    navigate("/login", {
      replace: true,
    });
  };

  const handlerShowLicences = async (e) => {
    e.preventDefault();
    let licences = {};
    getLicenciesByCompany(currentUser.idCompany).then((res) => {
      licences = res;
      console.log("Licences ", licences);
      if (licences !== null) {
        Swal.fire({
          title: "<strong>Licencias</strong>",
          // icon:'info',
          showCloseButton: true,
          html:
            '<div className="center"><img  src="/assets/logoNew.png"  className="licenciaLog" style="width:200px" }}/></div>' +
            '<div className="center"><h5>DriveLog</h5></div>' +
            '<table className="striped highlight  responsive-table centered tableFont">' +
            "<tbody>" +
            "<tr><td>Licencias Web:</td><td><strong>" +
            licences.web_contratadas +
            "</strong></td></tr>" +
            "<tr><td>Consumidas Web:</td><td><strong>" +
            licences.web_consumidas +
            "</strong></td></tr>" +
            "<tr><td>Licencias App:</td><td><strong>" +
            licences.app_contratadas +
            "</strong></td></tr>" +
            "<tr><td>Consumidas App:</td><td><strong>" +
            licences.app_consumidad +
            "</strong></td></tr>" +
            "</tbody>" +
            "</table>",
        });
      }
    });
  };

  return (
    <>
      <nav style={{ height: "200px" }} className="imgNav">
        <div className="nav-wrapper navSizeDiv" style={{ top: "33%" }}>
          <NavLink className="brand-logo" to="/">
            <div className="logoNav"></div>
            <div className="center">
              <h5>
                {nombreCompany === "Advan" ? <CircleGif /> : nombreCompany}
              </h5>
            </div>
          </NavLink>
          <NavLink to="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </NavLink>
          <ul
            id="nav-mobile"
            className="right hide-on-med-and-down margin-right"
          >
            {currentUser.typeUser === 1 ? (
              <li>
                <NavLink to="creatUsers" id="status">
                  <span>Crear Usuarios</span>
                </NavLink>
              </li>
            ) : (
              <li></li>
            )}
            {currentUser.typeUser === 1 ? (
              <li>
                <NavLink to="createCompany" id="status">
                  <span>Crear Compañias</span>
                </NavLink>
              </li>
            ) : (
              <li></li>
            )}
            {currentUser.typeUser === 1 ? (
              <li>
                <NavLink to="status" id="status">
                  <span>Crear Status</span>
                </NavLink>
              </li>
            ) : (
              <li></li>
            )}

            {currentUser.typeUser === 1 ? (
              <li>
                <NavLink to="videos" id="status">
                  <span>Subir Video</span>
                </NavLink>
              </li>
            ) : (
              <li></li>
            )}
            {currentUser.typeUser === 2 || currentUser.typeUser === 3 ? (
              <>
                <li>
                  <NavLink to="chat" id="chat">
                    <span>Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="mapa" id="mapa">
                    Mapa
                  </NavLink>
                </li>
                <li>
                  <NavLink to="evidencias" id="evi">
                    Evidencias
                  </NavLink>
                </li>
                <li>
                  <NavLink to="incidencias" id="inc">
                    Incidencias
                  </NavLink>
                </li>
                {
                  currentUser.companyType == 'D' ?
                    <li>
                      <NavLink to="devoluciones" id="dev">
                        Devoluciones
                      </NavLink>
                    </li> : <li></li>
                }

                <li>
                  <NavLink to="bitacora" id="dev">
                    Bitácora
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li></li>
              </>
            )}
            {currentUser.typeUser === 2 ? (
              <li>
                <NavLink to="usuarios" id="usuarios">
                  Usuarios
                </NavLink>
              </li>
            ) : (
              <li></li>
            )}
            {
              // MENU CONCEPTS
            }
            {currentUser.typeUser === 2 ? (
              <li>
                <NavLink
                  to="#"
                  className="dropdown-trigger"
                  data-target="conceptsMenu"
                >

                  <i className='material-icons menuConcepts'>menu</i>

                </NavLink>
              </li>
            ) : (
              <li></li>
            )}
            <ul id="conceptsMenu" className="dropdown-content show-ps">
              <li>
                <Link to="conceptosGastos">
                  <span className="title ">Conceptos de Gastos</span>
                </Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to="conceptosDevolucion">
                  <span className="title ">Conceptos de Devoluciones</span>
                </Link>
              </li>
              <li className="divider"></li>

            </ul>
            {/* click user */}
            <li className="center">
              <NavLink
                to="#"
                className="dropdown-trigger"
                data-target="dropdown1"
              >
                <button className="btn-floating btn-medium">
                  <img
                    alt={currentUser.name}
                    src={
                      currentUser.image != ""
                        ? currentUser.image
                        : `/assets/marvel-thor.png`
                    }
                    className="navBarPic"
                  />
                </button>
              </NavLink>
              <span style={{ fontSize: "8.5px", textOverflow: "ellipsis" }}>
                {currentUser.email}
              </span>
              <ul id="dropdown1" className="dropdown-content show-ps">
                <li>
                  <Link to="changePassword">
                    <span className="title ">Cambiar contraseña</span>
                  </Link>
                </li>
                <li className="divider"></li>
                <li>
                  <Link to="perfil">
                    <span className="title ">Editar Perfil</span>
                  </Link>
                </li>
                <li className="divider"></li>
                <li>
                  <Link to="" onClick={heandlerLogOut}>
                    <span className="title">Cerrar Sesión</span>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={handlerShowLicences}>
                    <span className="title">Licencias</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>

      {/*SIDE NAV MObile & tablet */}
      <ul id="mobile-demo" className="sidenav">
        <li>
          <div className="user-view userViewM">
            <div className="background">
              <img src="images/office.jpg" />
            </div>
            <Link to="">
              <img
                alt={currentUser.name}
                src={
                  currentUser.image != ""
                    ? currentUser.image
                    : `/assets/marvel-thor.png`
                }
                className="circle responsive-img"
              />
            </Link>
            <span className="white-text name">{currentUser.name}</span>
            <span className="white-text email">{currentUser.email}</span>
          </div>
        </li>
        {currentUser.typeUser === 1 ? (
          <li>
            <NavLink to="creatUsers" id="status">
              <i className="material-icons ">person</i>
              <span>Crear Usuarios</span>
            </NavLink>
          </li>
        ) : (
          <li></li>
        )}
        {currentUser.typeUser === 1 ? (
          <li>
            <NavLink to="createCompany" id="status">
              <i className="material-icons ">business</i>
              <span>Crear Compañias</span>
            </NavLink>
          </li>
        ) : (
          <li></li>
        )}
        {currentUser.typeUser === 1 ? (
          <>
            <li>
              <NavLink to="status" id="status">
                <i className="material-icons ">style</i>
                <span>Crear Status</span>
              </NavLink>
            </li>
          </>
        ) : (
          <></>
        )}

        {currentUser.typeUser === 1 ? (
          <>
            <li>
              <NavLink to="videos" id="status">
                <i className="material-icons ">video_library</i>
                <span>Subir Video</span>
              </NavLink>
            </li>
          </>
        ) : (
          <></>
        )}
        {currentUser.typeUser === 2 || currentUser.typeUser === 3 ? (
          <>
            <li>
              <NavLink to="chat" id="chat">
                <i className="material-icons ">question_answer</i>
                <span>Chat</span>
              </NavLink>
            </li>
            <li className="divider"></li>
            <li>
              <NavLink to="mapa" id="mapa">
                <i className="material-icons ">map</i>Mapa
              </NavLink>
            </li>
            <li className="divider"></li>
            <li>
              <NavLink to="evidencias" id="evi">
                <i className="material-icons ">assignment</i>Evidencias
              </NavLink>
            </li>
            <li className="divider"></li>
            <li>
              <NavLink to="incidencias" id="inc">
                <i className="material-icons ">collections</i>Incidencias
              </NavLink>
            </li>
            <li className="divider"></li>
          </>
        ) : (
          <>
            <li></li>
          </>
        )}
        {currentUser.typeUser === 2 ? (
          <li>
            <NavLink to="usuarios" id="usuarios">
              <i className="material-icons ">people</i>Usuarios
            </NavLink>
          </li>
        ) : (
          <li></li>
        )}
        <li className="divider"></li>

        <li>
          <Link to="changePassword">
            <i className="material-icons ">lock</i>Cambiar contraseña
          </Link>
        </li>
        <li className="divider"></li>
        <li>
          <Link to="perfil">
            <i className="material-icons ">person</i>Editar Perfil
          </Link>
        </li>
        <li className="divider"></li>
        <li>
          <Link to="" onClick={heandlerLogOut}>
            <i className="material-icons ">logout</i>Cerrar Sesión
          </Link>
        </li>
        <li className="divider"></li>
        <li>
          <Link to="" onClick={handlerShowLicences}>
            <span className="title">Licencias</span>
          </Link>
        </li>
      </ul>
      {/**END SIDE NAV */}
    </>
  );
};

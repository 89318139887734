import React, { useEffect, useState } from 'react'
import { getImgDevoluciones } from '../helpers/refundsFunc';


//idcurrentUSer to filter =>
const useGetimgDevoluciones = (serviceDetailID, idCompany) => {
    const [stateImgDevoluciones, setStateImgDevoluciones] = useState({
        listImgDevoluciones: [],
        loadingImg: true,
    });

    //effectData
    useEffect(() => {
        setStateImgDevoluciones({
            listImgDevoluciones: [],
            loadingImg: true,
        });
        getImgDevoluciones(serviceDetailID, idCompany).then((result) => {

            setStateImgDevoluciones({
                listImgDevoluciones: result,
                loadingImg: false,
            });
        })
    }, [serviceDetailID]);

    return stateImgDevoluciones;
}

export {
    useGetimgDevoluciones,
}
import Swal from 'sweetalert2';
import firebase from '../config/global-variables';

const getRefunds = async (serachText, idCompany, uid, type, initDate, endDate) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getHeadRefaund`; //NAME FUNCTION LEFT
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({
                field: 0, value: serachText, companyID: idCompany, typeUser: type, managerID: uid, initDate: initDate,
                endDate: endDate
            }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        console.log('dataText =>', data);
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('REFUNDS FROM SERVER =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop();
        return [];
        //swal alert to return to home
    }
}

const getSelectedRefund = async (id, idCompany) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/`; //GET ONE REFUND SELECTD INFO
        const optionsRequest = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
            body: JSON.stringify({ value: `${id}`, companyID: idCompany }),
            //mode:'no-cors'
        };

        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        const parseData = JSON.parse(data)
        const { Data } = parseData;
        console.log('SELECTED REFUND =>', Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop();
        return [];
        //swal alert to return to home
    }
}

const getImgDevoluciones = async (id, idCompany) => {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getEvidences`;
        const optionsRequest = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ serviceDetailID: id, companyID: idCompany }),
            //mode:'no-cors'
        };
        const response = await fetch(url, optionsRequest);
        const data = await response.json();
        const parseData = JSON.parse(data);
        const { Data } = parseData;

        console.log("ImageEvidences =>", Data);
        return Data;
    } catch (error) {
        console.log(error);
        //console.log('ImageEvidences =>',[]);
        swalMessagepop();
        return [];
    }
};


const swalMessagepop = () => {
    Swal.fire({
        title: 'Advertencia',
        text: 'No se pudo obtener la información de Incidencias, inténtelo más tarde.',
        icon: 'error',
        confirmButtonText: 'Ok',
    });
}

export { getImgDevoluciones, getRefunds, getSelectedRefund }



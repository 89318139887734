import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
  OverlayView,
} from "@react-google-maps/api";
import { CircleGif } from "../CircleGif";
import { AuthContext } from "../../auth/authContext";
import { getPositions } from "../../helpers/mapFunc";
import { CardInfo } from "./CardInfo";
import { Link } from "react-router-dom";
import { createOrGetConversation, sendGroupMessageByConversations } from "../../helpers/chatFunc";
import Swal from "sweetalert2";

export const BigMap = () => {
  //const zoom = 5;

  //Global info user dispatch
  const { currentUser } = useContext(AuthContext);
  let counter = 0;
  let zoo = 5;
  const itemsPerPAge = 6;
  const [listOperators, setListOperators] = useState({
    listOperatorsd: [],
    loading: true,
  });
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const [activeMarker, setActiveMarker] = useState(null);
  const [listpageOperators, setOperators] = useState([]);
  const [zoom, setZoom] = useState(10);
  const [serachText, setSerachText] = useState("");
  const [chatState, setChatStat] = useState("");

  const { isLoaded } = useLoadScript({
    id: "script-id",
    language: "es",
    googleMapsApiKey: "AIzaSyCUuTHJGUQ8kkpNFzDB7vjuS8vixaI3o58",
  });

  const containerStyle = {
    width: "100vw",
    height: "95vh",
  };

  const center = {
    lat: 21, //20.214526,
    lng: -100, //-101.122280,
  };

  //de preuba
  useEffect(() => {
    let isMounted = true;
    //reset the paginations of evi and inci
    localStorage.setItem(
      "savePage",
      JSON.stringify({
        pagination: 1,
      })
    );
    localStorage.setItem(
      "savePageInc",
      JSON.stringify({
        pagination: 1,
      })
    );
    getPositions(currentUser.id, serachText).then((result) => {
      //console.log(result);
      setListOperators({
        listOperatorsd: result,
        loading: false,
      });
    });

    zoo = 9;
    return () => {
      isMounted = false;
    };
  }, [page]);

  const { listOperatorsd, loading } = listOperators;
  const getListOperators = listOperatorsd;

  const retriveOperators = () => {
    const items = getListOperators.slice(
      (page - 1) * itemsPerPAge,
      (page - 1) * itemsPerPAge + itemsPerPAge
    );
    setOperators(items);
    setPageCount(Math.ceil(getListOperators.length / itemsPerPAge));
  };

  useEffect(() => {
    let isMounted = true;
    retriveOperators();
    return () => {
      isMounted = false;
    };
  }, [page, itemsPerPAge, listOperators]);

  const handlerActiveMarker = (marker) => {
    //marker.preventDefault();
    //console.log(marker);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  function handleZoomChanged() {
    // console.log("zoom : ",this.getZoom());
    //this refers to Google Map instance
    //setZoom(this.getZoom());
  }

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    listOperatorsd.forEach(({ latitude, longitude }) =>
      bounds.extend({ lat: latitude, lng: longitude })
    );
    map.fitBounds(bounds);
    // map.zoom(10);
  };

  //search
  const onChangeSerachText = (e) => {
    //e.preventDefault();
    //const searchText = e.target.value;
    setSerachText(e.target.value);
  };

  //searchMapAcctionForm
  //serachForm
  const searchMap = (event) => {
    event.preventDefault();

    setListOperators({
      listOperatorsd: [],
      loading: true,
    });

    getPositions(currentUser.id, serachText).then((result) => {
      //console.log(result);
      setListOperators({
        listOperatorsd: result,
        loading: false,
      });
    });

    if (listOperatorsd.length > 0) {
      retriveOperators();
    }
  };

  const handlerCardInfo = (event) => {
    event.preventDefault();
    console.log(event.target.id);
    setListOperators({
      listOperatorsd: [],
      loading: true,
    });

    setSerachText(event.target.id);

    getPositions(currentUser.id, event.target.id).then((result) => {
      //console.log(result);
      setListOperators({
        listOperatorsd: result,
        loading: false,
      });
    });

    if (listOperatorsd.length > 0) {
      retriveOperators();
    }
  };

  //page change btn
  const handlePageChange = (e) => {
    e.preventDefault();
    //console.log(e.target.id);
    const currentPage = parseInt(e.target.id);
    setPage(currentPage);
  };

  //backArrow
  const handlerBackArrowPage = () => {
    const arrowBack = document.getElementById("backli");
    if (page === 1) {
      arrowBack.classList.add("disabled");
      setPage(1);
    }
    if (page > 1) {
      arrowBack.classList.remove("disabled");
      const backPage = page - 1;
      setPage(backPage);
    }
  };

  //Next Arrow
  const handlerNextArrowPage = () => {
    const netxArrow = document.getElementById("nextli");
    if (page === pageCount) {
      netxArrow.classList.add("disabled");
      //setPage(pageCount);
    }
    if (page < pageCount) {
      netxArrow.classList.remove("disabled");
      const nextPage = page + 1;
      setPage(nextPage);
    }
  };

  //  //getConversationID
  const handlerCreateOrGetChat = (e) => {
    e.preventDefault();
    let recipentID = e.target.id;
    //console.log(recipentID);
    createOrGetConversation(
      currentUser.id,
      recipentID,
      currentUser.idCompany
    ).then((res) => {
      console.log(res);
      setChatStat(res);
      if (res !== "") {
        showPopupMessage(res);
      } else {
        //erro pop
      }
    });
  };

  //showPopMessage
  ////////////////////////////////
  const showPopupMessage = (chatID) => {
    Swal.fire({
      input: "textarea",
      //inputLabel: 'Message',
      title: "Enviar Mensaje",
      //text:name,
      inputPlaceholder: "Escriba su mensaje aquí...",
      inputAttributes: {
        "aria-label": "Escriba su mensaje aquí",
      },

      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.dismiss === "cancel") {
        setChatStat("");
      } else if (result.dismiss === "backdrop") {
        setChatStat("");
      } else {
        if (result.value !== "") {
          let listChatOnlyOne = [];
          listChatOnlyOne.push(chatID);
          //console.log('good',result.value);
          //const {selectedChats} = selectChat;
          //console.log('sendMessage to CHAT=>',listChatOnlyOne);

          await sendGroupMessageByConversations(
            listChatOnlyOne,
            currentUser.id,
            result.value
          );
          setChatStat("");
          Swal.fire({
            icon: "success",
            title: "Entrega exitosa",
            text: "El mensaje fue enviado con éxito",
          });
          //funcion para enviar mensajes por n conversaciones
        }


        if (result.value === "") {
          Swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "El mensaje no puede ir vació.",
          });
        }
      }
    });
  };

  //////////////////////////////

  //RENDER PAGINATION
  const renderPagination = () => {
    let listPageCount = [];
    for (let index = 0; index < pageCount; index++) {
      if (index === 0) {
        let num = 1;
        listPageCount.push(
          <li className={`${page === num ? "active" : ""}`} key={index}>
            <Link to="" onClick={handlePageChange} id={num}>
              {num}
            </Link>
          </li>
        );
      } else {
        let num = index + 1;
        listPageCount.push(
          <li className={`${page === num ? "active" : ""}`} key={index}>
            <Link to="" onClick={handlePageChange} id={num}>
              {num}
            </Link>
          </li>
        );
      }
    }

    return listPageCount;
  };

  //RENDER MAP
  const renderMap = () => {
    console.log("Positions==>", listOperatorsd);
    if (!isLoaded) {
      return <div>EL Mapa no se puede cargar, ocurrio un error.</div>;
    } else {
      if (listOperatorsd.length > 0) {
        return (
          <GoogleMap
            id="map-page"
            onLoad={handleOnLoad}
            mapContainerStyle={containerStyle}
            //zoom={zoom-10}
            defaultZoom={zoo}
            // center={center}
            zoom={zoo}
            //onZoomChanged={handleZoomChanged}
            onClick={() => setActiveMarker(null)}
          >
            {listOperatorsd.map(
              ({
                user_name,
                user_id,
                vehicleNumber,
                latitude,
                longitude,
                origin,
                destination,
                distance,
                dateString,
              }) => {
                return (
                  <div>
                    <div className="" style={{ backgroundColor: "black" }}>
                      {vehicleNumber}
                    </div>

                    <Marker
                      icon={{
                        url: "/assets/camion.png",
                        scaledSize: new window.google.maps.Size(25, 25),
                        labelOrigin: { x: 12, y: -10 },
                      }}
                      key={user_id}
                      position={{ lat: latitude, lng: longitude }}
                      onClick={() => {
                        //e.preventDefault();
                        handlerActiveMarker(user_id);
                      }}
                    >
                      {activeMarker === user_id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                          <div className="formatInfoMarker">
                            <span
                              style={{ fontSize: "14pt", fontWeight: "bold" }}
                            >
                              Operador:{" "}
                            </span>
                            <span
                              style={{ fontSize: "14pt", fontWeight: "normal" }}
                            >
                              {user_name}
                            </span>
                            <br />
                            <br />
                            <span
                              style={{ fontSize: "14pt", fontWeight: "bold" }}
                            >
                              Unidad:{" "}
                            </span>
                            <span
                              style={{ fontSize: "14pt", fontWeight: "normal" }}
                            >
                              {vehicleNumber}
                            </span>
                            <br />
                            <br />
                            {/* <span style={{fontSize:'14pt', fontWeight:'bold'}}>Cliente: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{vehicleNumber}</span>
                          <br/><br/> */}

                            <span
                              style={{ fontSize: "14pt", fontWeight: "bold" }}
                            >
                              Fecha:{" "}
                            </span>
                            <span
                              style={{ fontSize: "14pt", fontWeight: "normal" }}
                            >
                              {dateString}
                            </span>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                    <OverlayView
                      key="mwl"
                      position={{ lat: latitude, lng: longitude }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset
                    >
                      <div
                        style={{
                          background: `#203254`,
                          padding: `5px 5px`,
                          fontSize: "11px",
                          color: `white`,
                          borderRadius: "4px",
                          width: "80px",
                          textAlign: "center"
                        }}
                      >
                        {vehicleNumber}
                      </div>
                    </OverlayView>
                  </div>
                );
              }
            )}
          </GoogleMap>
        );
      } //esle no data
      else {
        return (
          <GoogleMap
            id="map-page"
            //onLoad={handleOnLoad}
            mapContainerStyle={containerStyle}
            //zoom={zoom-10}
            defaultZoom={zoo}
            center={center}
            zoom={zoo}
            //onZoomChanged={handleZoomChanged}
            onClick={() => setActiveMarker(null)}
          ></GoogleMap>
        );
      }
    }
  }; //en render googleMap

  const renderIconChat = (operator) => {
    return (
      <div className="card cdMessgaeDesing" style={{ pointerEvents: "none" }}>
        <div className="card-content pad15" style={{ pointerEvents: "none" }}>
          <div className="dataInfoCardrw" style={{ pointerEvents: "none" }}>
            {currentUser.typeUser === 3 ? (
              <Link
                to=""
                id={operator.user_id}
                style={{ marginRight: "0px" }}
                onClick={handlerCreateOrGetChat}
              >
                <i
                  className="material-icons iconsList "
                  id={operator.user_id}
                  style={{ pointerEvents: "auto" }}
                >
                  question_answer
                </i>
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
  //render CardsInfo Map
  const renderCards = () => {
    let ListCards = [];
    if (listpageOperators.length > 0) {
      return (
        <ul>
          {listpageOperators.map((operator) => {
            console.log(operator.user_id);
            return (
              <li key={operator.user_id} style={{ pointerEvents: "none" }}>
                <div className="fatCardMap">
                  {renderIconChat(operator)}
                  <div>
                    <Link
                      id={`${operator.vehicleNumber}`}
                      to=""
                      style={{ pointerEvents: "auto" }}
                      onClick={handlerCardInfo}
                    >
                      <CardInfo objcte={operator} />
                    </Link>
                  </div>
                </div>

              </li>
            );
          })}
        </ul>
      );
    } else {
      ///return simple text with no data.
      return <div></div>;
    }
  };

  return (
    <>
      <div className="" style={{ background: "#5E5E68" }}>
        <form
          className="center"
          style={{ padding: "10px 0px" }}
          onSubmit={searchMap}
        >
          <div className="input-field inputSerach ">
            <i
              className="material-icons  prefix "
              style={{ color: "#A3A3A9", left: "0px", top: "10px" }}
            >
              search
            </i>
            <input
              id="search"
              type="text"
              placeholder="Puedes buscar por: cliente, operador, unidad, remolque o destino"
              className="inputSerachBorderBottom"
              value={serachText}
              onChange={onChangeSerachText}
            />
          </div>
        </form>
      </div>
      <div className="contentMap">
        {
          //cards
          <div className="sectionCardsMap">
            {loading === true ? (
              <div className="centerGif center">
                <CircleGif />
              </div>
            ) : (
              renderCards()
            )}
            {
              //pagination
              <div className="row center">
                <ul className="pagination">
                  <li
                    className={`${page === 1 ? "disabled" : ""}`}
                    key="back"
                    id="backli"
                  >
                    <a href="#!" id="back" onClick={handlerBackArrowPage}>
                      <i className="material-icons">chevron_left</i>
                    </a>
                  </li>
                  {renderPagination()}
                  <li
                    className={`${page === pageCount ? "disabled" : ""}`}
                    key="next"
                    id="nextli"
                  >
                    <a href="#!" id="next" onClick={handlerNextArrowPage}>
                      <i className="material-icons">chevron_right</i>
                    </a>
                  </li>
                </ul>
              </div>
            }
          </div>
        }

        {loading === true ? (
          <div className="centerGif center">
            <CircleGif />
          </div>
        ) : (
          renderMap()
        )}

        {/*<LoadScript id='script-loader' googleMapsApiKey='AIzaSyDnwZ0_kTdjOCodwYWcPuNOU_cfnujxSX4' >{renderMap()}</LoadScript>*/}
      </div>
    </>
  );
};


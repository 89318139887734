

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Navbar } from '../components/ui/Navbar'
import { HomePage } from '../pages/HomePage'

import { ChatPage } from '../pages/ChatPage'
import { EvidencePage } from '../pages/EvidencePage'
import { IncidenciasPage } from '../pages/IncidenciasPage'
import { EvidenceInfoPage } from '../pages/EvidenceInfoPage'
import { IncidenciaInfoPage } from '../pages/IncidenciaInfoPage'
import { MapaPage } from '../pages/MapaPage'
import { RegisterForm } from '../components/Users/RegisterForm'
import { UsersHomeForm } from '../components/Users/UsersHomeForm'
import { EditUserForm } from '../components/Users/EditUserForm'
import { ChangePassPage } from '../pages/ChangePass'
import { PerfilPage } from '../pages/PerfilPage'
import { Notification } from '../components/Notification'
import { UsersHomePage } from '../pages/UsersHomePage'
import { GruposPage } from '../pages/GruposPage'
import { EditUserPage } from '../pages/EditUserPage'
import { SolicitudesPage } from '../pages/SolicitudesPage'
import { StatusPage } from '../pages/StatusPage'
import { CompanyPage } from '../pages/CompanyPage'
import { CreateUsersSuperAdminPage } from '../pages/CreateUsersSuperAdminPage'
import { Refunds } from '../pages/Refaunds'
import { RefaundInfoPage } from '../pages/RefaundInfoPage'
import { BitacoraPage } from '../pages/BitacoraPage'
import { VideosPages } from '../pages/VideosPages'
import { ConceptoGastos } from '../pages/ConceptoGastos'
import { ConceptoDevoluciones } from '../pages/ConceptoDevoluciones'
import { RegisterGasto } from '../components/ConceptoGastos/RegisterGasto'
import { EditGasto } from '../components/ConceptoGastos/EditGasto'
import { RegisterDevolution } from '../components/ConceptoDevoluciones/RegisterDevolution'
import { EditDelovution } from '../components/ConceptoDevoluciones/EditDelovution'

export const DashbordRoot = (showToast) => {
    return (
        <>

            {
                /**
                 * showToast={showToast}
                    showToast={showToast}
                 */
            }
            <Navbar />
            <main>
                <Routes>


                    <Route
                        path="/"
                        element={<HomePage />}
                    />
                    <Route path="chat" element={<ChatPage />} />
                    <Route path="mapa" element={<MapaPage />} />
                    <Route path="evidencias" element={<EvidencePage />} />
                    <Route path="evidencias/:evidenceid" element={<EvidenceInfoPage />} />
                    <Route path="incidencias" element={<IncidenciasPage />} />
                    <Route path="incidencias/:incidenciaid" element={<IncidenciaInfoPage />} />
                    <Route path="usuarios/register" element={<RegisterForm />} />
                    <Route path="usuarios/userEdit/:usuario" element={<EditUserPage />} />
                    <Route path="usuarios/grupos" element={<GruposPage />} />
                    <Route path="usersHomeForm" element={<UsersHomeForm />} />
                    <Route path="userEdit" element={<EditUserForm />} />
                    <Route path="changePassword" element={<ChangePassPage />} />
                    <Route path="perfil" element={<PerfilPage />} />
                    <Route path="usuarios" element={<UsersHomePage />} />
                    <Route path="alertasPDF" element={<SolicitudesPage />} />
                    <Route path='status' element={<StatusPage />} />
                    <Route path='createCompany' element={<CompanyPage />} />
                    <Route path='creatUsers' element={<CreateUsersSuperAdminPage />} />
                    <Route path='devoluciones' element={<Refunds />} />
                    <Route path='devoluciones/:devolucionid' element={<RefaundInfoPage />} />
                    <Route path='bitacora' element={<BitacoraPage />} />
                    <Route path='videos' element={<VideosPages />} />
                    <Route path='conceptosGastos' element={<ConceptoGastos />} />
                    <Route path='conceptosDevolucion' element={<ConceptoDevoluciones />} />
                    <Route path='conceptosGastos/registrar' element={<RegisterGasto />} />
                    <Route path='conceptosDevolucion/registrar' element={<RegisterDevolution />} />
                    <Route path="conceptosGastos/editar/:concepto" element={<EditGasto />} />
                    <Route path="conceptosDevolucion/editar/:concepto" element={<EditDelovution />} />





                </Routes>
            </main>
            <Notification />
            <footer></footer>

        </>
    )
}



import React from 'react'
import { BigMap } from '../components/Map/BigMap'
import '../css/map.css'
import '../css/cardinfo.css'

export const MapaPage = () => {
    return (
       <BigMap />
    )
}

import Swal from "sweetalert2";
import firebase from "../config/global-variables";

//GET EVIDENCES
const getEvidences = async (serachText, idCompany, uid, type, initDate, endDate) => {
  try {


    //getEvidences
    const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getHeadEvidences`;
    const optionsRequest = {
      timeout: 10000,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        field: 0,
        value: serachText,
        companyID: idCompany,
        typeUser: type,
        managerID: uid,
        initDate: initDate,
        endDate: endDate
      }),
      //mode:'no-cors'
    };

    const response = await fetch(url, optionsRequest);
    const data = await response.json();
    const parseData = JSON.parse(data);
    const { Data } = parseData;
    //console.log('DataEvidences =>',Data);
    return Data;
  } catch (error) {
    console.log(error);
    console.log("DataEvidences =>", []);
    swalMessagepop();
    return [];
  }
};

//GET IMGS
const getImgEvidence = async (id, idCompany) => {
  try {
    const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getEvidences`;
    const optionsRequest = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ serviceDetailID: id, companyID: idCompany }),
      //mode:'no-cors'
    };
    const response = await fetch(url, optionsRequest);
    const data = await response.json();
    const parseData = JSON.parse(data);
    const { Data } = parseData;

    console.log("ImageEvidences =>", Data);
    return Data;
  } catch (error) {
    console.log(error);
    //console.log('ImageEvidences =>',[]);
    swalMessagepop();
    return [];
  }
};

const swalMessagepop = (message = "") => {
  Swal.fire({
    title: "Advertencia",
    text:
      message === ""
        ? "No se pudo obtener la información de Evidencias, inténtelo más tardes."
        : message,
    icon: "warning",
    confirmButtonText: "Ok",
  });
};

//send Email
/**
 *
 *  listURLIMg => lista de las url de imagenes
 *  objData => object de informacion {
 * senderEmail,companySender,whoRecibe,subject,msg
 * }
 */
const sendEmail = async (listURLIMg, objData, identify) => {
  try {
    Swal.fire({
      title: "Enviando Correo.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: "<b>Por favor espere...</b>",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/sendEmail`;
    const optionsRequest = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        listImg: listURLIMg,
        senderEmail: "smtp.gmail.com", //objData.senderEmail,
        reciber: objData.whoRecibe,
        subject: objData.subject,
        message: objData.msg,
        idCompany: objData.idCompany,
        identify: identify,
      }),
      // mode:'no-cors'
    };
    const response = await fetch(url, optionsRequest);
    console.log(response);
    if (response.status === 200 && response.ok === true) {
      Swal.close();
      Swal.fire({
        title: "Entrega",
        text: "La entrega de tu correo fue exitosa",
        icon: "success",
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: "La entrega de tu correo no pudo ser enviada, intente más tarde. Problema, referido con el servidor o configuración",
        icon: "error",
      });
    }
    // const data = await response.json();
    // console.log('sendEmail => ',data);
    /* Swal.fire({
           title:'Entrega',
           text:'La entrega de tu correo fue exitosa'+ response,
            icon:'success',
       });*/
  } catch (error) {
    Swal.close();
    //console.log(error);
    //console.log('ImageEvidences =>',[]);
    swalMessagepop(
      "Error al enviar correo, se perdio la conexión al servidor o mas bien fallo la entrega."
    );
  }
};

//delete User from users and from auth and all info
const deleteRowAndphotos = async (idCompany, serviceDetailID, id) => {
  try {
    const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/deleteImages`; //falta name function

    const optionsRequest = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        idCompany: idCompany,
        serviceDetailID: serviceDetailID,
        idEviInci: parseInt(id),
      }),
      // mode:'no-cors'
    };
    let eviInc = "";
    let lasIMg = "";
    let eliminadas = "";
    serviceDetailID !== "" ? (eviInc = "Evidencia") : (eviInc = "Incidencia");
    if (serviceDetailID !== "") {
      lasIMg = "Las imágenes";
      eliminadas = "eliminadas";
    } else {
      lasIMg = "La imágen";
      eliminadas = "eliminada";
    }
    const response = await fetch(url, optionsRequest);
    console.log(response);
    //const data = await response.json();
    //return data; // 1 si se elimino un 2 si no se pudo pero si conecto
    if (response.status === 200) {
      Swal.close();
      Swal.fire({
        title: "Eliminado",
        text:
          lasIMg + " de la " + eviInc + " fue " + eliminadas + " exitosamente.",
        icon: "success",
      }).then(function () {
        window.location.reload();
      });
    }
    /*if(data.status === 200 && data.message === 2){
        Swal.close();
        Swal.fire({
            title:'Alerta',
            text:'El usuario no se pudo eliminar, intentelo mas tarde.',
             icon:'warning',
        })
    }*/
  } catch (error) {
    Swal.fire({
      title: "Error",
      text:
        "Ocurrio un error al conectarse al servidor. No se pudo acceder a la información" +
        error,
      icon: "error",
    });
    return 0;
  }
};

export { getEvidences, getImgEvidence, sendEmail, deleteRowAndphotos };

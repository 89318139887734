
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';


import { AuthContext } from '../../auth/authContext';

import Swal from 'sweetalert2';

import M from 'materialize-css';
import { updatePicAndStatus } from '../../helpers/profileFunc';



export const ProfileView = () => {

     //Global info user dispatch
     const {currentUser} = useContext(AuthContext);

    //const [email, setEmail] = useState("");
   
   const [imgState, setImgState] = useState("");
   const [statecurrentPhoto, setCurrenPhoto] = useState(null);
    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);


    useEffect(()=>{
        let isMounted = true;
        const sele = document.querySelectorAll('.select');
        M.FormSelect.init(sele);
          //reset the paginations of evi and inci
     localStorage.setItem('savePage',JSON.stringify({
        pagination : 1
    }));
    localStorage.setItem('savePageInc',JSON.stringify({
        pagination : 1
    }));
        return () => {
            isMounted = false;
            };
        //M.FormSelect.getInstance(sele);
        
    },[])

    //changePassword
    const handlerSaveData = async()=>{
        Swal.fire({
            title:'Enviando Información.',
            allowOutsideClick:false,
            allowEscapeKey: false,
            html:'<b>Por favor espere...</b>',
            didOpen: () => {
                Swal.showLoading()
            },
        })
        //const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(imgState !== ""){
             await updatePicAndStatus(currentUser,statecurrentPhoto,imgState);
        }else{

            Swal.close();
            Swal.fire({
                title:'Upss!',
                text:`No puedes actualizar datos que no han sido cambiados`,
                icon:'warning'
            })
        }
     
    }

    const fileClick = (e)=>{
        e.preventDefault();
        const el = document.getElementById('imgInput');
      
        if(el){
            el.click();
        }
    }


    const uploadImage = async(e) =>{
        e.preventDefault();
       // console.log('Files',e.target.files[0]);
  
       
        //const transformDate = new Date(date);
     
        if(e.target.files && e.target.files[0]){
            const currentPhoto = e.target.files[0]; //current photo
            const prefixFileType = e.target.files[0].type.toString();// type jpg or png
            const reader = new FileReader();
            const fileByteArray = [];
           reader.readAsDataURL(currentPhoto);
         
             //reader.readAsArrayBuffer(currentPhoto);
            reader.onloadend = (e)=>{
                setImgState("");
                setCurrenPhoto(null);
                //console.log('renderResult',e.target.result);
                if(e.target.readyState === FileReader.DONE){

                    //console.log('Target => ', e.target.result);
                    const img = document.getElementById('profileImg');

                    img.src = e.target.result;
                    
                    setImgState(e.target.result);
                    setCurrenPhoto(currentPhoto);
                    console.log('valueSelect =>',document.getElementById('selectStatus').value);
                  
                      
               }
               
            }
            
        }

     }


     

    return (
        <div className='body-home row'>
        <div className=' contentFormProfile'>
            
            <div className='col s12 m12 l12 center'>

               { /*<div className='logoAppDiv'>
                    
                </div>*/ }
                <div className='row ' style={{paddingTop:'35%'}}>
                <h4>Mi perfil</h4>
                </div>


                
              
                        <form className='col s12 m12 l12'>
                            <div className='' >
                              
                            <img src={currentUser.image !== "" ? currentUser.image : `/assets/marvel-thor.png`} alt={currentUser.name}  className='profilePicture ' style={{pointerEvents:'none'}} id='profileImg'/>
                            
                            </div>
                            <Link to='' onClick={fileClick}>Editar Foto</Link>
                            <input accept='image/*' type='file' id='imgInput' style={{width:'0px'}} onChange={uploadImage}/>
                            <br />
                            <br />
                            <label>Estatus:</label>
                            <div className="input-field formInputSelect">
                            
                            <select   defaultValue='' name='status' className="browser-default noBorderSelect" id='selectStatus'>
                            <option  value={currentUser.status} disabled selected>{currentUser.status}</option>
                           {/* <option key='1' value='1'>SuperAdmin</option>
                            <option key='2' value='2'>Admin</option>
                            <option key='3' value='3'>Jefe Flotilla</option>
                            <option key='4' value='4'>Monitorista</option>
            <option key='5' value='5'>Operador</option> */}
                           
                            </select>
                            
                        </div>

                  
                           
                            <div className='accessDiv'>
                                
                                <button className='btn waves-effect waves-light btnFromLogin' type='button' name='login' id='btnSave' onClick={handlerSaveData}>Guardar</button>
                              
                            </div>
                        </form>
                      
                        

                       
               
            </div>
            
         
            
        </div>
        </div>
     
    )
}

/**
 *  <div className='registerDiv'>
                        <span className='spanLRH' >¿Aún no tienes una cuenta?</span> <a className='links'  href='#'>Crear cuenta</a>
                        </div>
 */





import React from 'react'
import { FormCompanies } from '../components/Companies/FormCompanies';

import '../css/Register.css';
export const CompanyPage = () => {
  return (
    <FormCompanies/>
  )
}

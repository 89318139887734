import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import M from "materialize-css";
import Swal from "sweetalert2";
import { DialogValidation } from "../DialogValidation";
import { saveRegisterStatusByCompany } from "../../helpers/statusFunc";
import { createNewCompany } from "../../helpers/companiesFunc";

export const FormCompanies = () => {
  const { currentUser } = useContext(AuthContext);
  // const [selectTypeStatus,selectTypeStatusState] = useState(0);
  //const [logingCompanies,setLoadingCompanies] = useState(true);
  // const [listCompanies,setListCompanies] = useState([]);
  const initialValues = {
    accesDoc: "DEFAULT",
    idCompany: "",
    keyAccess: "",
    name: "",
    routing: "",
    shortName: "",
    storageUrl: "",
    tokenAccess: "",
    tokenAccessERP: "",
    phone: "",
    address: "",
    status: 0,
    userApp: 0,
    userWeb: 0,
    locationRadius: 0,
    validateArrival: "DEFAULT",
  };
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    let isMounted = true;
    const select = document.querySelectorAll("select");
    M.FormSelect.init(select);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectTypeStatus = (e) => {
    console.log(e.target.value);
    // selectTypeStatusState(parseInt( e.target.value));
  };

  const validate = (values) => {
    // e.preventDefault();
    //  console.log('Click enviar');
    let msgError = "";
    let newErrr = "";
    let flag;
    console.log(formValues);
    const regExpUrlIps =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const regEXpPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    // const typeStatus = document.getElementById("typeStatus");
    if (values.accesDoc === "" || values.accesDoc === "DEFAULT") {
      msgError += "El acceso al doc es requerida.\n";
      flag = false;
    }
    if (values.validateArrival === "" || values.validateArrival === "DEFAULT") {
      msgError += "La validación de ubicación es requerida.\n";
      flag = false;
    }
    if (values.idCompany === "") {
      msgError += "El  idCompany es requerido.\n";
      flag = false;
    }
    if (values.keyAccess === "") {
      msgError += "La llave de acceso  requerido.\n";
      flag = false;
    }
    if (values.name === "") {
      msgError += "El nombre de la empresa es requerio.\n";
      flag = false;
    }
    if (values.routing === "") {
      msgError += "El routing es requerido.\n";
      flag = false;
    }
    if (values.shortName === "") {
      msgError += "El nombre mini de la empresa es requerido.\n";
      flag = false;
    }
    if (values.phone === "") {
      msgError += "El teléfono de la empresa es requerido.\n";
      flag = false;
    }
    if (values.phone !== "") {
      if (!values.phone.match(regEXpPhone)) {
        newErrr += "El número de teléno no es valido.\n";
        flag = false;
      }
    }
    if (values.address === "") {
      msgError += "La dirección de la empresa es requerida.\n";
      flag = false;
    }

    if (values.userApp === 0) {
      msgError += "Las licencias de la app son requeridas.";
      flag = false;
    }
    if (values.userWeb === 0) {
      msgError += "Las licencias de la web son requeridas.";
      flag = false;
    }
    if (values.storageUrl === "") {
      msgError += "La url de firebase es requerida.";
      flag = false;
    }
    if (values.storageUrl !== "") {
      if (!values.storageUrl.match(regExpUrlIps)) {
        newErrr += "La url del storageUrl no es valida.\n";
        flag = false;
      }
    }
    if (values.routing !== "") {
      if (
        !values.routing.match(
          regExpUrlIps
          // /^((https?:\/\/)|(www.))(?:([a-zA-Z]+)|(\d+\.\d+.\d+.\d+)):\d{4}$/
        )
      ) {
        newErrr +=
          "La ip del el routing no es valida debe ser formato url o ip.\n";
        flag = false;
      }
    }
    if (values.tokenAccess === "") {
      msgError += "El token de acceso es requerido.";
      flag = false;
    }
    if (values.tokenAccessERP === "") {
      msgError += "El token de acceso al ERP es requerido.";
      flag = false;
    }

    if (msgError !== "" || newErrr !== "") {
      msgError = "Todos los campos son requeridos.";

      Swal.fire({
        title: "Aviso",
        text: newErrr !== "" ? newErrr : msgError,
        icon: "warning",
      });

      flag = false;

    } else {
      flag = true;
    }

    return flag;
  };

  /* const handlerChangeSelector = (e) =>{
        e.preventDefault();
        console.log(e.target.value); // get the value = id firebase user
        selectCompanyState(e.target.value);
    }*/

  const handlerSaveRegister = async (e) => {
    e.preventDefault();
    const flag = validate(formValues);

    if (flag === true) {
      const data = {
        accesDoc: parseInt(formValues.accesDoc),
        idCompany: formValues.idCompany.toUpperCase().trim(),
        keyAccess: formValues.keyAccess.trim(),
        name: formValues.name.trim(),
        routing: formValues.routing.trim(),
        shortname: formValues.shortName.trim(),
        status: parseInt(formValues.status),
        userApp: parseInt(formValues.userApp),
        userWeb: parseInt(formValues.userWeb),
        storageUrl: formValues.storageUrl,
        tokenAccess: formValues.tokenAccess,
        tokenAccessERP: formValues.tokenAccessERP,
        phone: formValues.phone,
        address: formValues.address,
        validateArrival: parseInt(formValues.validateArrival),
        locationRadius: parseFloat(formValues.locationRadius).toFixed(2),
      };

      const result = await createNewCompany(data);
      console.log(result);
      if (result === 1) {
        Swal.fire({
          title: "Exitoso",
          text: "Empresa registrada.",
          confirmButtonText: "Ok",
          icon: "success",
        }).then(function () {
          setFormValues(initialValues);
          window.location.reload();
        });
      } else {
        //
        Swal.fire({
          title: "Aviso",
          text: "No se pudo registrar la empresa.",
          confirmButtonText: "Ok",
          icon: "warning",
        }).then(function () {
          setFormValues(initialValues);
          window.location.reload();
        });

        // setFormValues(initialValues);
        // document.getElementById("typeStatus").value = '0';
      }

    }
    //saveRegisterStatusByCompany
  };

  return (
    <>
      {currentUser.typeUser === 1 ? (
        <>
          {" "}
          <div className="containerUser" style={{ position: "absolute" }}>
            <div
              className="contentFormCompany"
              style={
                {
                  /*paddingTop:'300px'*/
                }
              }
            >
              <div className="col s12 m12 l12 center">
                <div className=" center">
                  <h4>Registro de empresas</h4>
                </div>
                <br></br>
                <form className="col s12 m12 l12 ">
                  <div className="row ">
                    <div className="input-field white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <i className="tiny material-icons prefix iconPositionInput "></i>
                      <select
                        name="accesDoc"
                        onChange={handleChange}
                        required
                        id="mySelect"
                        //defaultValue={'DEFAULT'}
                        value={formValues.accesDoc}
                      >
                        <option key="0" value="DEFAULT" disabled>
                          AccessDoc
                        </option>
                        <option key="1" value="1">
                          True
                        </option>
                        <option key="2" value="2">
                          False
                        </option>
                      </select>
                    </div>
                    <div className="col s12 m1 l1"></div>

                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                      <input
                        name="idCompany"
                        placeholder="idCompany"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.idCompany}
                        maxLength={15}
                        onChange={handleChange}
                      />
                      <label htmlFor="idCompany" className="active">
                        idCompany
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <input
                        name="keyAccess"
                        placeholder="keyAccess"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.keyAccess}
                        onChange={handleChange}
                        maxLength={10}
                      />
                      <label htmlFor="keyAccess" className="active">
                        keyAccess
                      </label>
                    </div>
                    <div className="col s12 m1 l1"></div>
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                      <input
                        name="name"
                        placeholder="name"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.name}
                        onChange={handleChange}
                        maxLength={25}
                      />
                      <label htmlFor="name" className="active">
                        name
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <input
                        name="phone"
                        placeholder="442 000 0000"
                        type="tel"
                        required
                        id="statusCaption"
                        value={formValues.phone}
                        onChange={handleChange}

                        maxLength={25}
                      />
                      <label htmlFor="phone" className="active">
                        Phone
                      </label>
                    </div>
                    <div className="col s12 m1 l1"></div>
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                      <input
                        name="address"
                        placeholder="address"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.address}
                        onChange={handleChange}
                        maxLength={150}
                      />
                      <label htmlFor="address" className="active">
                        Address
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <input
                        name="routing"
                        placeholder="routing"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.routing}
                        onChange={handleChange}
                        maxLength={60}
                      />
                      <label htmlFor="routing" className="active">
                        routing
                      </label>
                    </div>
                    <div className="col s12 m1 l1"></div>
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                      <input
                        name="storageUrl"
                        placeholder="storageUrl"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.storageUrl}
                        onChange={handleChange}
                        maxLength={60}
                      />
                      <label htmlFor="storageUrl" className="active">
                        storageUrl
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <input
                        name="tokenAccessERP"
                        placeholder="tokenAccessERP"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.tokenAccessERP}
                        onChange={handleChange}
                        maxLength={35}
                      />
                      <label htmlFor="tokenAccessERP" className="active">
                        tokenAccessERP
                      </label>
                    </div>
                    <div className="col s12 m1 l1"></div>
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m6 l6">
                      <input
                        name="tokenAccess"
                        placeholder="tokenAccess"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.tokenAccess}
                        onChange={handleChange}
                        maxLength={35}
                      />
                      <label htmlFor="tokenAccess" className="active">
                        tokenAccess
                      </label>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="input-field white lighten-5 inputRegisterUser  col s12 m5 l5">
                      <i className="tiny material-icons prefix iconPositionInput "></i>
                      <select
                        name="validateArrival"
                        onChange={handleChange}
                        required
                        id="mySelect"
                        //defaultValue={'DEFAULT'}
                        value={formValues.validateArrival}
                      >
                        <option key="0" value="DEFAULT" disabled>
                          validateArrival
                        </option>
                        <option key="1" value="1">
                          True
                        </option>
                        <option key="2" value="2">
                          False
                        </option>
                      </select>
                    </div>
                    <div className="col s12 m4 l4"></div>
                    <div className="input-field  white lighten-5 inputRegisterUser col s12 m3 l3">
                      <input
                        name="locationRadius"
                        type="number"
                        className=""
                        required
                        id="type"
                        value={formValues.locationRadius}
                        onChange={handleChange}
                        step=".01"
                        min={0}
                        max={10000000}
                      />
                      <label htmlFor="status" className="active">
                        Radius Location
                      </label>
                    </div>

                  </div>

                  <div className="row">
                    <div className="input-field  white lighten-5 inputRegisterUser  col s12 m3 l3">
                      <input
                        name="shortName"
                        placeholder="shortName"
                        type="text"
                        required
                        id="statusCaption"
                        value={formValues.shortName}
                        onChange={handleChange}
                        maxLength={10}
                      />
                      <label htmlFor="shortName" className="active">
                        shortName
                      </label>
                    </div>
                    <div className="col s12 m1 l1"></div>

                    <div className="input-field  white lighten-5 inputRegisterUser col s12 m2 l2">
                      <input
                        name="status"
                        type="number"
                        className=""
                        required
                        id="type"
                        value={formValues.status}
                        onChange={handleChange}
                        min={0}
                        max={1}
                      />
                      <label htmlFor="status" className="active">
                        status
                      </label>
                    </div>

                    <div className="col s12 m1 l1"></div>

                    <div className="input-field  white lighten-5 inputRegisterUser col s12 m2 l2">
                      <input
                        name="userApp"
                        type="number"
                        className=""
                        required
                        id="type"
                        value={formValues.userApp}
                        onChange={handleChange}
                        min={0}
                      />
                      <label htmlFor="userApp" className="active">
                        userApp
                      </label>
                    </div>

                    <div className="col s12 m1 l1"></div>

                    <div className="input-field  white lighten-5 inputRegisterUser col s12 m2 l2">
                      <input
                        name="userWeb"
                        type="number"
                        className=""
                        required
                        id="type"
                        value={formValues.userWeb}
                        onChange={handleChange}
                        min={0}
                      />
                      <label htmlFor="userWeb" className="active">
                        userWeb
                      </label>
                    </div>
                  </div>

                  <div className="registerDiv">
                    <button
                      className="btn waves-effect waves-light btnFromRegisterCreate"
                      onClick={handlerSaveRegister}
                      type="button"
                      name="Crear"
                    >
                      Crear
                    </button>
                    <Link
                      to="/"
                      className="btn btnFromLogin"
                      id="btnFromLoginRU"
                      style={{ background: "#5E5E68" }}
                    >
                      Salir
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="containerUser">
              <h3 className="center">No tiene acceso a este apartado.</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
};


import React, { useContext } from 'react'
import { AuthContext } from '../../auth/authContext';
import  HomeCards   from './HomeCards'

export const Home = () => {
    
    const {currentUser} = useContext(AuthContext);
    return (
        <>
        
        <div className='body-home'>
       
                {currentUser.typeUser === 2 || currentUser.typeUser === 3  ? <div style={{paddingTop:'5%'}}>
                        <HomeCards />
                    </div> : <></>}

        </div>
        </>
    )
}
